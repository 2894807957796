import getBasePath from '../functions/api-resource';

const basePath = getBasePath('apuracao_acao', 'apuracao-parcial');

const apuracaoActions = {
  gravar: { method: 'POST', url: `${basePath}` },
  buscarRecebimentosParciais: { method: 'GET', url: `${basePath}/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);
