<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" v-if="!somenteLeitura" class="pt-0">
        <regiao-form
          ref="regiaoForm"
          @FORMULARIO_CADASTRO_REGIAO_SALVAR="onNovoRegiao"
          @FORMULARIO_CADASTRO_REGIAO_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="somenteLeitura ? 12 : 9" class="pt-0">
        <regiao-tabela
          ref="regiaoTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.regiao', 2)"
          @TABELA_EXPORTAR_DADOS="requestExportacao"
          @PESQUISA_REGIAO_EDITAR="onEditarRegiao"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '@/common/functions/roles-computed-generator';
import CRUDGenerico from '@/shared-components/generico/CRUDGenerico';
import exportacao from '@/common/functions/exportacao';
import RegiaoForm from './RegiaoForm';
import RegiaoTabela from './RegiaoTabela';

export default {
  name: 'RegiaoCadastro',
  extends: CRUDGenerico,
  data() {
    return {
      regiaoResources: this.$api.regiao(this.$resource),
    };
  },
  components: {
    RegiaoForm,
    RegiaoTabela,
  },
  computed: {
    ...generateComputed('Regiao', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onCancelar() {
      this.$refs.regiaoTabela.filtrar();
    },
    onEditarRegiao(regiao) {
      this.$refs.regiaoForm.editar(regiao);
    },
    onNovoRegiao() {
      this.$refs.regiaoTabela.filtrar();
    },
    requestAdicionar(objeto, cb = null) {
      this.requestAdicionarGenerico(this.regiaoResources.gravar, objeto, cb);
    },
    requestAtivarInativar(index) {
      const row = this.tableRows[index],
        { id } = row;
      return this.requestAtivarInativarGenerico(row, id, this.regiaoResources);
    },
    requestExportacao(cb) {
      const params = {
        filtro: this.$refs.regiaoTabela.getSearchQuery() || '',
      };
      exportacao.exportar(cb, 'regiao_simples', this, params);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
