<template>
  <v-card>
    <v-card-title class="headline">{{ $t('title.tradelinks') }}</v-card-title>
    <v-card-text>{{ $t('message.selecionar_tradelinks') }}</v-card-text>
    <v-card-text>
          <tenant-list @TenantList_selecionarTenant="selecionarTenant"/>
    </v-card-text>
  </v-card>
</template>

<script>
import TenantList from '@/shared-components/tenant/TenantList';

export default {
  name: 'TenantCard',
  components: { TenantList },
  methods: {
    selecionarTenant(tenantID) {
      this.$emit('TenantCard_selecionarTenant', tenantID);
    },
  },
};
</script>
