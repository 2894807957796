const Notas = () => import('@/spa/notas/Notas');
const NotasListNivel1 = () => import('@/spa/notas/NotasNivel1');
const NotasListNivel2 = () => import('@/spa/notas/NotasNivel2');

export default {
  path: 'notas',
  component: Notas,
  children: [
    {
      path: '',
      name: 'notas',
      component: NotasListNivel1,
    },
    {
      path: 'nivel2',
      name: 'nivel2Notas',
      component: NotasListNivel2,
    },
  ],
};
