<script>
import exportacao from '@/common/resources/exportacoes';
import { prepareResponseData } from '@/common/functions/http';
import {
  removeItem,
  copyObject,
} from '@/common/functions/helpers';
import {
  sortTruthy,
  sortTableColumn,
} from '@/common/functions/sort';

export default {
  methods: {
    aplicarAcaoArray(arrayObjetos, acao) {
      if (arrayObjetos.length) {
        arrayObjetos.map(acao);
      }
    },
    ativarInativar(arrayObjetos, cb) {
      this.aplicarAcaoArray(arrayObjetos, (row) => {
        this.requestAtivarInativar(row);
        return row;
      });
      if (cb) {
        cb();
      }
    },
    atualizar(row, order = true) {
      const item = this.tableRows.find((el) => el.id === row.id);
      if (item) {
        removeItem(this.tableRows, item);
        this.tableRows.unshift(row);
        if (order) {
          this.organizarAtivos();
        }
      }
    },
    autocompleteModelAccess(campo) {
      return campo.replace('id_', '').replace('cod_', '');
    },
    cleanTableNoResult(cb) {
      this.tableRows = [];
      if (cb) {
        cb();
      }
    },
    closeDialog() {
      this.$refs.dialog1.close();
    },
    closeDialogSave(item, cb) {
      this.closeDialog();
      this.requestAdicionar(item, cb);
    },
    criarHeaderMenu(key, object) {
      return ({
        name: object[key],
        tooltip: object[key],
        sortType: key,
        classes: [],
      });
    },
    exportarFiltros(filtros, cb, nomeExportador, limit = 500, timeout = 120000) {
      const resource = exportacao(this.$http, timeout);
      resource.download(nomeExportador,
        {
          limit,
          parametrosConsulta: filtros,
        })
        .then(() => {
          if (cb) {
            cb();
          }
          this.$toast(this.$t('message.exportacao_download'));
        })
        .catch(() => {
          if (cb) {
            cb();
          }
          this.$toast('Erro ao exportar. Tente novamente.');
        });
    },
    exportar(cb, nomeExportador, limit = 500, timeout = 120000) {
      this.exportarFiltros({}, cb, nomeExportador, limit, timeout);
    },
    openDialog(ref) {
      this.$refs[ref].open();
    },
    organizar(ordenacao, source = null) {
      sortTableColumn(source || this.tableRows, ordenacao);
    },
    organizarAtivos() {
      sortTruthy(this.tableRows, 'indAtivo');
    },
    prepareFetchExtensao(idNivelExtensao) {
      return (param = null) => {
        const params = { ...param, idNivelExtensao };
        return (this.extensaoResources || this.resourcesExtensao).listarAtivos(params);
      };
    },
    prepareFetchExtensaoManyToMany(idNivelExtensao, extensoes, campo) {
      return (param = null) => {
        const params = { ...param, idNivelExtensao };
        if (extensoes) {
          params.idsNotIn = extensoes.map((el) => el[campo]);
        }
        return (this.extensaoResources || this.resourcesExtensao).listarAtivos(params);
      };
    },
    prepareResponseData,
    remover(arrayObjetos) {
      this.aplicarAcaoArray(arrayObjetos, (row) => {
        this.requestRemover(row);
        return row;
      });
    },
    requestAdicionarGenerico(resource, objeto, cb = null) {
      resource(objeto)
        .then((docs) => {
          objeto.id = docs.data.id;
          this.tableRows.unshift(copyObject(objeto));
          if (cb) {
            cb();
          }
          this.$toast(this.$t('message.adicionado_confira_tabela'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestAtivarInativarGenerico(objeto, id, resources, cb, order) {
      const resource = this.validaObjeto(objeto)
        ? resources.inativar
        : resources.ativar;
      return resource({ id }, { id })
        .then(() => this.tratarRetornoSucessoAtivarInativar(objeto, cb, order))
        .catch((err) => {
          this.$error(this, err);
        });
    },
    tratarRetornoSucessoAtivarInativar(objeto, cb, order) {
      this.$toast(`${this.validaObjeto(objeto)
        ? 'Inativado'
        : 'Ativado'} com sucesso.`);

      objeto.indAtivo = !objeto.indAtivo;
      objeto.ind_ativo = !objeto.ind_ativo;

      this.atualizar(objeto, order);
      if (cb) {
        cb();
      }
    },
    tratarRetornoSucessoAtivarInativarMassa(arrayObjetos, cb) {
      this.$toast(this.$t('message.alteracao_massa_sucesso'));

      this.aplicarAcaoArray(arrayObjetos, (row) => {
        row.indAtivo = !row.indAtivo;
        row.ind_ativo = !row.ind_ativo;
        this.atualizar(row);
      });

      if (cb) {
        cb();
      }
    },
    requestAtualizarGenerico(resource, objeto, order) {
      resource(objeto)
        .then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.atualizar(objeto, order);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestPopularGenerico(resource, page = null, cb = null, source = null, order = true) {
      const pagination = page || { size: 20, page: 1 };

      resource(pagination)
        .then((response) => {
          const objetos = response.data.resposta;

          if (!objetos.length) {
            if (pagination.page < 2) {
              this.cleanTableNoResult(cb);
            } else if (cb) {
              cb(true);
            }
            return;
          }

          if (objetos && objetos.hasOwnProperty('length') && objetos.length) {
            if (pagination.page < 2) {
              if (source) {
                source = [];
                source.push(...objetos);
              } else {
                this.tableRows = [];
                this.tableRows.push(...objetos);
              }
            } else {
              (source || this.tableRows).push(...objetos);
            }
          }
          if (cb) {
            cb();
          }
          if (!source && order) {
            this.organizarAtivos();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    requestRemoverGenerico(objeto, id, resources, cb) {
      return resource({ id }, { id })
        .then(() => {
          this.$toast(`${this.validaObjeto(objeto) ? 'Inativado' : 'Ativado'} com sucesso.`);
          removeItem(this.tableRows, row);
          if (cb) {
            cb();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    validaObjeto(obj) {
      return !!(obj.indAtivo);
    },
  },
};
</script>

<style src="./CRUDGenerico.css" lang="css"></style>
