<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row v-if="carregouMetadados">
      <v-col cols="12" sm="3" v-if="!somenteLeitura" class="pt-0">
        <divisao-form
          ref="divisaoForm"
          @FORMULARIO_CADASTRO_DIVISAO_SALVAR="onNovaDivisao"
          @FORMULARIO_CADASTRO_DIVISAO_CANCELAR="onCancelar" />
      </v-col>
      <v-col cols="12" :md="!somenteLeitura ? 9 : 12" class="pt-0">
        <divisao-tabela
          ref="divisaoTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.divisao', 2)"
          @PESQUISA_DIVISAO_EDITAR="onEditarDivisao"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import CRUDGenerico from '@/shared-components/generico/CRUDGenerico';
import DivisaoForm from '@/spa/divisao/DivisaoForm';
import DivisaoTabela from '@/spa/divisao/DivisaoTabela';

export default {
  name: 'CadastroDivisao',
  extends: CRUDGenerico,
  data() {
    return {
      divisaoResources: this.$api.divisao(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  components: {
    DivisaoForm,
    DivisaoTabela,
  },
  computed: {
    ...generateComputed('Divisao', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getDivisaoMetadado',
    ]),
    somenteLeitura() {
      return !this.canEdit && this.canGet;
    },
    carregouMetadados() {
      const metadados = this.getDivisaoMetadado;
      return metadados != null;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setDivisaoMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
    popularMetadados() {
      if (this.getDivisaoMetadado) {
        return;
      }
      this.setDivisaoMetadado({
        resource: this.resourceMetadado.listarDivisao,
      });
    },
    onCancelar() {
      this.$refs.divisaoTabela.filtrar();
    },
    onEditarDivisao(divisao) {
      this.$refs.divisaoForm.editar(divisao);
      this.$refs.divisaoForm.buscarDivisoesPorEstruturaDeUsuario();
      this.$refs.divisaoForm
        .buscarEstruturasDeUsuario(divisao.estruturaUsuario.nome);
    },
    onNovaDivisao() {
      this.$refs.divisaoTabela.filtrar();
    },
    requestPopular(page = null) {
      if (page && page.filter) {
        this.request(this.divisaoResources.listarFiltrado, page);
        return;
      }
      this.request(this.divisaoResources.listar, page);
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    } else {
      this.popularMetadados();
    }
  },
};
</script>
