import { render, staticRenderFns } from "./DivisaoTabela.vue?vue&type=template&id=129892ab&lang=html"
import script from "./DivisaoTabela.vue?vue&type=script&lang=js"
export * from "./DivisaoTabela.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports