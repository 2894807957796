import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('usuario', 'perfil');

const perfilActions = {
  selecao: { method: 'GET', url: `${basePath}/selecao` },
  buscaIdPerfilUsuarioAprovador: { method: 'GET', url: `${basePath}/aprovador/usuario` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
};

export default (resource) => resource(`${basePath}`, {}, perfilActions);
