import TermoAceite from '@/spa/termo-aceite/TermoAceite';
import TermoAceiteLista from '@/spa/termo-aceite/TermoAceiteLista';
import TermoAceiteForm from '@/spa/termo-aceite/TermoAceiteForm';
import TermoAceiteRealizado from '@/spa/termo-aceite/TermoAceiteRealizado';

export default {
  path: 'termo-aceite',
  component: TermoAceite,
  children: [
    {
      path: '',
      name: 'termo-aceite',
      component: TermoAceiteLista,
    },
    {
      path: '/novo',
      name: 'novoTermoAceite',
      component: TermoAceiteForm,
    },
    {
      path: ':id/editar',
      name: 'editarTermoAceite',
      component: TermoAceiteForm,
    },
    {
      path: ':id/ver',
      name: 'verTermoAceite',
      component: TermoAceiteForm,
      props: {
        somenteLeitura: true,
      },
    },
    {
      path: '/realizado',
      name: 'termoAceiteRealizado',
      component: TermoAceiteRealizado,
    },
  ],
};
