<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%;" class="mb-0">{{ $t('label.cadastrar_editar_campo_customizado') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="nome"
              name="nome"
              v-model="campoCustomizado.nomCampo"
              :label="`${$t('label.nome_campo')} *`"
              :counter="30"
              maxlength="30"
              :rules="[rules.required]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="nome_interno"
              name="nome_interno"
              v-model="campoCustomizado.labelCampo"
              :label="`${$t('label.nome_interno')} *`"
              :counter="30"
              maxlength="30"
              :rules="[rules.required, rules.onlyLetterAndUnderscore]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="descricao"
              name="descricao"
              v-model="campoCustomizado.descricao"
              :label="`${$t('label.descricao_campo')} *`"
              :counter="100"
              maxlength="100"
              :rules="[rules.required]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              class="Form-text__invalid"
              id="tipo_campo"
              name="tipo_campo"
              item-text="nome"
              item-value="valor"
              clearable
              v-model="campoCustomizado.tipoCampo"
              @change="limparCamposRelacionadosAoTipo"
              :rules="[rules.required]"
              :items="tipos"
              :label="`${$tc('label.tipo_campo', 1)} *`">
            </v-select>
          </v-col>
          <v-col cols="12" v-if="isData && false">
            <v-select
              class="Form-text__invalid"
              id="formato_data"
              name="formato_data"
              clearable
              v-model="campoCustomizado.desAtributos.formatoData"
              :rules="[rules.required]"
              :items="formatosData"
              :label="`${$tc('label.formato_data', 1)} *`">
            </v-select>
          </v-col>
          <v-col cols="12" :sm="isNumerico ? 6 : undefined" v-if="isNumerico">
            <v-text-field
              class="Form-text__invalid"
              id="min"
              name="min"
              v-model="campoCustomizado.min"
              mask="##########"
              :label="`${$t('label.tamanho_minimo_descritivo_numerico')}`"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12" :sm="isTexto ? 12 : (isNumerico ? 6 : undefined)" v-if="podeExibirTamanhoCaracteres()">
            <v-text-field
              class="Form-text__invalid"
              id="max"
              name="max"
              :mask="`${isTexto ? '###' : '##########'}`"
              :return-masked-value="true"
              v-model="campoCustomizado.max"
              :label="`${isTexto ? $t('label.tamanho_maximo_descritivo_texto') : $t('label.tamanho_maximo_descritivo_numerico')}`"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="isLista">
            <v-combobox
              class="combo_opcoes"
              v-model="tags"
              :label="`${$tc('label.opcoes_lista', 1)} *`"
              multiple
              persistent-hint
              :rules="[rules.requiredTags]"
              :readonly="somenteLeitura"
              small-chips>
              <template
                v-slot:selection="data">
                <v-chip close @click:close="removeChipSelecao(data.item)">{{ data.item }}</v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12">
            <v-select
              class="Form-text__invalid"
              id="entidade"
              name="entidade"
              item-text="nome"
              item-value="valor"
              clearable
              v-model="campoCustomizado.entidade"
              :rules="[rules.required]"
              :disabled="!!idCampoCustomizado || somenteLeitura || desabilitarSelecaoEntidade"
              :items="entidades"
              :label="`${$tc('label.entidade', 1)} *`">
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" lg="6">
            <v-switch :label="$t('label.ativo')" color="primary" v-model="campoCustomizado.indAtivo"></v-switch>
          </v-col>
          <v-col cols="12" sm="12" lg="6">
            <v-switch :label="$t('label.obrigatorio')" color="primary" v-model="campoCustomizado.vlrObrigatorio"></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $tc('label.campo_customizado', 1)})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'CamposCustomizadosForm',
  components: {
    Confirm,
  },
  props: {
    idCampoCustomizado: {
      type: Number,
      default: null,
    },
    somenteLeitura: true,
    desabilitarSelecaoEntidade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campoCustomizado: {
        id: null,
        nomCampo: null,
        labelCampo: null,
        tipoCampo: null,
        entidade: null,
        indAtivo: true,
        vlrObrigatorio: false,
        descricao: null,
        max: null,
        min: null,
        desAtributos: {},
      },
      entidades: [
        { nome: this.$tc('label.acao', 1), valor: 'ACAO' },
        { nome: this.$tc('label.contrato', 1), valor: 'CONTRATO' },
        { nome: this.$tc('label.cliente', 1), valor: 'CLIENTE' },
        { nome: this.$tc('label.fornecedor', 1), valor: 'FORNECEDOR' },
        { nome: this.$tc('label.orcamento', 1), valor: 'ORCAMENTO' },
        { nome: this.$tc('label.pagamento_manual', 1), valor: 'PAGAMENTO_MANUAL' },
        { nome: this.$tc('label.produto', 1), valor: 'PRODUTO' },
      ],
      formatosData: [
        'DD-MM-YYYY',
        'MM-DD-YYYY',
        'YYYY-MM-DD',
        'YYYY-DD-MM',
      ],
      minInteiro: 0,
      maxInteiro: Number.MAX_SAFE_INTEGER,
      tag: '',
      tags: [],
      tipos: [
        { nome: this.$tc('label.data', 1), valor: 'DATA' },
        { nome: this.$tc('label.hora', 1), valor: 'HORA' },
        { nome: this.$tc('label.lista', 1), valor: 'LISTA' },
        { nome: this.$tc('label.multipla_escolha', 1), valor: 'MULTIPLA_ESCOLHA' },
        { nome: this.$tc('label.numerico', 1), valor: 'NUMERICO' },
        { nome: this.$tc('label.percentual', 1), valor: 'PERCENTUAL' },
        { nome: this.$tc('label.sim_nao', 1), valor: 'BOOLEAN' },
        { nome: this.$tc('label.texto', 1), valor: 'TEXTO' },
        { nome: this.$tc('label.textarea', 1), valor: 'TEXTAREA' },
      ],
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTags: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
        onlyLetterAndUnderscore: (value) => /^[a-z_]+$/.test(value) || this.$t('message.so_letra_minuscula_e_underline'),
      },
      resources: this.$api.camposCustomizados(this.$resource),
    };
  },
  computed: {
    isData() {
      return this.campoCustomizado.tipoCampo === 'DATA';
    },
    isLista() {
      return this.campoCustomizado.tipoCampo === 'LISTA' || this.campoCustomizado.tipoCampo === 'MULTIPLA_ESCOLHA';
    },
    isNumerico() {
      return this.campoCustomizado.tipoCampo === 'NUMERICO' || this.campoCustomizado.tipoCampo === 'PERCENTUAL';
    },
    isTexto() {
      return this.campoCustomizado.tipoCampo === 'TEXTO';
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      if (this.isLista && this.tags.length === 0) return;
      if (this.isNumerico && !this.minimoMaximoValidos()) {
        this.$toast(this.$t('message.minimo_maior_maximo'));
        return;
      }
      this.$refs.confirmDialog.open();
    },
    buscarEntidades() {
      return this.entidades;
    },
    cancelar() {
      this.limparFormulario();
      this.$emit('cancelar');
    },
    carregar() {
      const id = this.idCampoCustomizado;
      this.resources.obter({ id }).then((response) => {
        this.carteira = response.data;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    habilitarApenasUmaEntidade(nomeEntidade) {
      this.entidades.forEach((entidade) => {
        if (entidade.valor === nomeEntidade) {
          this.campoCustomizado.entidade = entidade.valor;
        }
      });
    },
    iniciarEdicao(campo) {
      this.tags = [];
      this.campoCustomizado = { ...campo };
      this.campoCustomizado.desAtributos = campo.desAtributos
        && campo.desAtributos.value
        ? JSON.parse(campo.desAtributos.value)
        : {};

      if (this.campoCustomizado.desAtributos && this.campoCustomizado.desAtributos.lista) {
        this.campoCustomizado.desAtributos.lista.forEach((item) => {
          this.tags.push(item);
        });
      }
    },
    limparCamposRelacionadosAoTipo() {
      this.campoCustomizado.min = null;
      this.campoCustomizado.max = null;
      this.campoCustomizado.desAtributos = {};
      if (!this.isLista) this.tags = [];
    },
    limparFormulario() {
      this.campoCustomizado.indAtivo = false;
      this.campoCustomizado.vlrObrigatorio = true;
      this.$refs.form.reset();
      this.tags = [];
      this.$emit('limparIdCampoCustomizado');
      setTimeout(() => {
        this.campoCustomizado.indAtivo = true;
        this.campoCustomizado.vlrObrigatorio = false;
      });
    },
    minimoMaximoValidos() {
      if (this.campoCustomizado.min && this.campoCustomizado.max) {
        const minimo = parseInt(this.campoCustomizado.min, 10);
        const maximo = parseInt(this.campoCustomizado.max, 10);
        if (maximo <= minimo) return false;
      }
      return true;
    },
    podeExibirTamanhoCaracteres() {
      return this.isNumerico || this.isTexto;
    },
    prepararCampoCustomizado() {
      if (this.isLista) {
        this.campoCustomizado.desAtributos.lista = [];
        this.tags.forEach((tag) => {
          this.campoCustomizado.desAtributos.lista.push(tag);
        });
      }

      if (this.isData) {
        this.campoCustomizado.desAtributos.formatoData = 'DD-MM-YYYY';
      }

      if (this.campoCustomizado.min) {
        this.campoCustomizado.desAtributos.min = this.campoCustomizado.min;
      }

      if (this.campoCustomizado.max) {
        this.campoCustomizado.desAtributos.max = this.campoCustomizado.max;
      }

      if (this.isNumerico && !this.campoCustomizado.min) {
        this.campoCustomizado.desAtributos.min = this.minInteiro;
      }

      if (this.isNumerico && !this.campoCustomizado.max) {
        this.campoCustomizado.desAtributos.max = this.maxInteiro;
      }

      this.campoCustomizado.desAtributos.descricao = this.campoCustomizado.descricao;
      this.campoCustomizado.id = this.idCampoCustomizado;
      return this.campoCustomizado;
    },
    salvar() {
      if (this.idCampoCustomizado) {
        this.resources.atualizar({ id: this.idCampoCustomizado }, this.prepararCampoCustomizado())
          .then(() => {
            this.$toast(this.$t('message.atualizado_confira_tabela'));
            this.limparFormulario();
            this.$emit('atualizarGrid');
          }, (err) => {
            this.$error(this, err);
          });
      } else {
        this.resources.gravar(this.prepararCampoCustomizado())
          .then((response) => {
            this.$toast(this.$t('message.adicionado_confira_tabela'));
            this.limparFormulario();
            this.$emit('atualizarGrid', response.data);
          }, (err) => {
            this.$error(this, err);
          });
      }
    },
    removeChipSelecao(item) {
      const index = this.tags.indexOf(item);
      if (index >= 0) {
        this.tags.splice(index, 1);
      }
    },
  },
  mounted() {
    if (this.idCampoCustomizado) {
      this.carregar();
    }
  },
};
</script>
<style lang="scss">
  .combo_opcoes .v-input__append-inner {
    display: none;
  }
</style>
