import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'termo-aceite');

const termoAceiteActions = {
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  gravar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  ativacao: { method: 'PUT', url: `${basePath}/ativacao/{codigo}` },
  buscarTermosLogin: { method: 'GET', url: `${basePath}/login` },
  aceitarTermos: { method: 'POST', url: `${basePath}/aceito` },
  listarRealizados: { method: 'GET', url: `${basePath}/realizados?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
  buscarTermoPorObjetivo: { method: 'GET', url: `${basePath}/objetivo/{objetivo}` },
  salvarAceiteCadastroUsuario: { method: 'POST', url: `${basePath}/aceite-cadastro-usuario` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
};

export default (resource) => resource(`${basePath}`, {}, termoAceiteActions);
