<template lang="html">
  <v-card>
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.workflow', 1) }}</h3>
    </v-card-title>

    <v-row justify="start">
      <v-col cols="12" sm="6" md="3" class="ml-3">
        <v-text-field
          v-model="searchQuery"
          @input="filtrar"
          append-icon="search"
          :label="$t('label.pesquisar')"
          single-line
          clearable
          hide-details>
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="3" align-self="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon
              id="workflow-botao-ativar-inativar"
              v-show="canEdit && selected.length > 0"
              class="pt-4 mr-5"
              v-on="on"
              @click="ativarDesativarRegistros">
              <v-icon>block</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.ativar_inativa') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="fluxos"
      v-model="selected"
      show-select
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.acoes="{ item }" v-if="canEdit">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-0" v-on="on" @click.stop="editarItem(item)">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('label.editar_produto') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.tipoFluxo="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.tipoFluxo}}
        </span>
      </template>
      <template v-slot:item.descricao="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.descricao}}
        </span>
      </template>
      <template v-slot:item.ind_ativo="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao')}}
        </span>
      </template>
      <template v-slot:item.usuarioCriacao="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.usuarioCriacao}}
        </span>
      </template>
      <template v-slot:item.dtaCriacao="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.dtaCriacao}}
        </span>
      </template>
      <template v-slot:item.usuarioAlteracao="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.usuarioAlteracao}}
        </span>
      </template>
      <template v-slot:item.dtaAlteracao="{ item }">
        <span :class="{ 'row--disabled': !item.indAtivo }">
          {{item.dtaAlteracao}}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script type="text/javascript">
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  data() {
    return {
      resource: this.$api.fluxoAprovacao(this.$resource),
      esperar: false,
      headers: [
        {
          text: '', value: 'acoes', width: '2%', sortable: false,
        },
        { text: this.$tc('label.nome', 1), value: 'tipoFluxo', sortable: false },
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: true },
        { text: this.$tc('label.ativo', 1), value: 'ind_ativo', sortable: true },
        { text: this.$tc('label.criador', 1), value: 'usuarioCriacao', sortable: false },
        { text: this.$tc('label.data_criacao', 1), value: 'dtaCriacao', sortable: false },
        { text: this.$tc('label.atualizador', 1), value: 'usuarioAlteracao', sortable: false },
        { text: this.$tc('label.data_atualizacao', 1), value: 'dtaAlteracao', sortable: false },
      ],
      fluxos: [],
      pagination: {},
      totalPage: 0,
      searchQuery: null,
      selected: [],
      timeout: 0,
    };
  },
  computed: {
    ...generateComputed('fluxo', ['canEdit']),
  },
  watch: {
    pagination: {
      handler() {
        this.buscar();
      },
      deep: true,
    },
  },
  methods: {
    buscar() {
      const params = {
        descricao: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0] ? this.pagination.sortBy[0] : 'tipo_fluxo, descricao',
      };

      this.resource.listar(params).then((response) => {
        this.fluxos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    ativarDesativarRegistros() {
      const promises = this.selected.map((fluxo) => {
        const { indAtivo, id } = fluxo;
        if (indAtivo) {
          return this.resource.inativar({ id }, { id });
        }
        return this.resource.ativar({ id }, { id });
      });

      Promise.all(promises)
        .then(() => {
          this.selected = [];
          this.buscar();
          this.$toast(this.$t('message.alteracao_massa_sucesso'));
        })
        .catch(() => {
          this.selected = [];
          this.buscar();
          this.$toast(this.$t('errors.ativacao.massa'));
        });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    editarItem(row) {
      this.$emit('WORKFLOWTABLE_EDIT', row);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
  },
};
</script>
