<template>
  <div>
    <div style="text-align: center" class="title justify-center">
      <p class="mb-0">{{ titulo }}</p>
    </div>
    <v-container fluid grid-list-md class="DashboardPendencias_Planejamento">
      <v-data-iterator
        :items="dados"
        :server-items-length="totalPage"
        :options.sync="pagination"
        :hide-default-footer="escondePaginacao"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50],
        }">
        <template v-slot:default="props">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              v-for="(item) in props.items"
              :key="item.id">
              <v-hover>
                <v-card
                  v-if="item.isContrato"
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 5 : 1}`"
                  fill-height class="Card_Pendencia fill-height">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                        <v-row justify="space-between">
                          <v-col cols="9" class="py-0">
                            <span v-if="dashFornecedor || dashCliente" class="pa-0">
                              {{ `${$tc('label.contrato', 1)} #${item.id} :: ${item.solicitante}` }}
                            </span>
                            <span v-else class="pa-0">
                              {{ `${$tc('label.contrato', 1)} #${item.id} :: ${item.divisao}` }}
                            </span>
                          </v-col>
                        <v-col cols="3" class="d-flex flex-row-reverse">
                          <v-row justify="end">
                            <v-col cols="3" class="d-flex flex-row-reverse pr-4 py-0">
                              <v-tooltip left v-if="mostrarGerarCartaParceria(item)">
                                <template v-slot:activator="{ on }">
                                  <v-btn text icon
                                    v-on="on"
                                    @click.native="realizarDownloadCartaParceria(item)">
                                    <v-icon>receipt</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="!isTenantEpoca">{{ $tc('label.gerar_carta_parceria', 1) }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="1" class="d-flex flex-row-reverse pr-0 py-0">
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn text icon
                                    v-on="on"
                                    @click.native="abrirDetalhamento(item)">
                                    <v-icon>info</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $tc('label.detalhe', 2) }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                        </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" class="py-0">
                          <span v-if="item.grupoFornecedor">{{ item.grupoFornecedor }}</span>
                          <span v-else>{{ item.fornecedor }}</span>
                          <span v-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" md=12 class="pa-0">
                          <v-spacer></v-spacer>
                          <planejamento-contrato-fluxo
                            :key="item.id"
                            :id-contrato="item.id"
                            :is-carta-parceria="item.isCartaParceria"
                            :data-inicio-contrato="item.dtaInicio"
                            :data-fim-contrato="item.dtaFim"
                            :status="item.status"
                            @recarregar="buscarDados()"
                            :acao-possui-fluxo="false"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
                <v-card
                  v-else
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 5 : 1}`"
                  fill-height class="Card_Pendencia fill-height">
                  <v-card-title class="Card_Title">
                    <v-container fluid>
                      <v-row justify="space-between">
                        <v-col cols="9" class="py-0">
                          <span v-if="dashFornecedor || dashCliente" class="pa-0">
                            {{ `${$t('label.acao_avulsa')} #${item.id} :: ${item.solicitante}` }}
                          </span>
                          <span v-else-if="item.isAcaoContrato" class="pa-0">
                            {{ `${$tc('label.acao_contrato', 1)} #${item.id} :: ${item.divisao}` }}
                          </span>
                          <span v-else class="pa-0">
                            {{ `${$t('label.acao_avulsa')} #${item.id} :: ${item.divisao}` }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="d-flex flex-row-reverse">
                          <v-row justify="end">
                            <v-col cols="3" class="d-flex flex-row-reverse pr-4 py-0">
                              <v-tooltip left v-if="item.numNotaDebito">
                                <template v-slot:activator="{ on }">
                                  <v-btn text icon
                                        v-on="on"
                                        @click.native="exportarNota(item)">
                                    <v-icon>receipt</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $tc('label.exportar', 1) }}</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="1" class="d-flex flex-row-reverse pr-0 py-0">
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-btn text icon
                                    v-on="on"
                                    @click.native="abrirDetalhamento(item)">
                                    <v-icon>info</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $tc('label.detalhe', 2) }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row justify="start" v-if="item.numNotaDebito">
                        <v-col cols="12" class="py-0">
                          <span v-if="exibirBotaoExibicaoMultiplasNds(item)">{{ `${$tc('label.num_nota_debito', 1)}: ` }}</span>
                          <v-btn v-if="exibirBotaoExibicaoMultiplasNds(item)" icon class="mx-0" @click.stop="abrirModalExibicaoMultiplasNds(item)">
                            <v-icon>visibility</v-icon>
                          </v-btn>
                          <span v-else>{{ `${$tc('label.num_nota_debito', 1)}: ${item.numNotaDebito}` }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text class="Card_Content">
                    <v-container fluid>
                      <v-row justify="center" align="center">
                        <v-col cols="12" v-if="!item.metaIntervalo" class="py-0">
                          <span>
                            {{ `${$tc('label.verba', 1)}: ${verbaPendencia(item)}`}}
                          </span>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span v-if="item.grupoFornecedor">{{ item.grupoFornecedor }}</span>
                          <span v-else>{{ item.fornecedor }}</span>
                          <span v-if="item.extensoes">{{ `- ${item.extensoes}`}}</span>
                        </v-col>
                        <v-col cols="12" md=12 class="pa-0">
                          <v-spacer></v-spacer>
                          <planejamento-acao-fluxo
                            :key="item.id"
                            :id-acao="item.id"
                            :status="item.status"
                            @recarregar="buscarDados()"
                            :acao-possui-fluxo="false"
                            :is-modalidade-valor-fixo="isModalidadeValorFixo(item)"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>

    <modal-exibicao-multiplas-nds
      ref="ModalExibicaoMultiplasNds">
    </modal-exibicao-multiplas-nds>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import PlanejamentoAcaoFluxo from '@/spa/planejamento-acao/PlanejamentoAcaoFluxo';
import ModalExibicaoMultiplasNds from '@/shared-components/ModalExibicaoMultiplasNdsCamelCase';
import PlanejamentoContratoFluxo from '@/spa/planejamento-contrato/PlanejamentoContratoFluxo';
import {
  getMoney,
  getPercent,
} from '@/common/functions/helpers';
import { pendenciasFluxoAcao } from '@/common/resources/workflow-acao';
import exportacao from '@/common/resources/downloads';
import exportacaoCartaParceria from '@/common/functions/downloads';
import basePath from '@/common/functions/api-resource';

export default {
  name: 'dash-inicial-pendencias-planejamento',
  components: {
    PlanejamentoAcaoFluxo,
    PlanejamentoContratoFluxo,
    ModalExibicaoMultiplasNds,
  },
  props: {
    filtros: Object,
    dashFornecedor: {
      type: Boolean,
      default: false,
    },
    dashCliente: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dados: [],
      pagination: {
        itemsPerPage: 5,
      },
      escondePaginacao: true,
      totalPage: 0,
      filtrosAplicados: this.filtros,

      realizouPrimeiraBusca: false,
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    titulo() {
      if (this.title) {
        return this.title;
      }
      return this.$tc('title.pendencia_planejamento', 2);
    },
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.dados = [];
        this.buscarDados();
        setTimeout(() => {
          this.realizouPrimeiraBusca = true;
        });
      },
    },
    pagination: {
      handler() {
        if (this.realizouPrimeiraBusca) {
          this.buscarDados();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMoney,
    getPercent,
    verbaPendencia(pendencia) {
      const { verba } = pendencia;
      if (verba === 'PERCENTUAL') {
        return this.$tc('label.percentual', 1);
      }
      if (verba === 'VALOR_POR_PECA') {
        return this.$tc('label.valor_por_peca', 1);
      }
      return this.$tc('label.monetario', 1);
    },
    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      pendenciasFluxoAcao(params, this.$resource)
        .then((res) => {
          const { data } = res;
          if (!data.resposta.length && this.pagination.page > 1) {
            this.pagination.page -= 1;
            this.buscarDados();
            return;
          }
          this.dados = data.resposta;
          this.totalPage = data.quantidadeRegistrosPagina;
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    isAfter(data, outra) {
      return moment(data, 'YYYY-MM-DD').isAfter(moment(outra, 'YYYY-MM-DD'));
    },
    abrirDetalhamento(item) {
      if (item.isContrato || item.isAcaoContrato) {
        this.$router.push({ name: 'detalharContrato', params: { idContrato: item.idContrato, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      } else {
        let routeName = 'detalharAcao';
        if (this.isTemplateVerbaVariavel(item)) {
          routeName = 'detalharAcaoVerbaVariavel';
        }
        this.$router.push({ name: routeName, params: { idAcao: item.id, from: 'dashboard-inicial' } });
      }
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    mostrarGerarCartaParceria(item) {
      return ['EM_CADASTRO', 'AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE'].includes(item.status)
        && item.isCartaParceria;
    },
    realizarDownloadCartaParceria(item) {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const idContrato = item.id;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    exportarNota(item) {
      const idAcao = item.id;
      const getBasePath = basePath('job', 'recebimento/relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(getBasePath, {
        param: '',
        url: `nota-debito/preview/${idAcao}`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    isModalidadeValorFixo(item) {
      return item.modalidade && item.modalidade === 'VALOR_FIXO';
    },
    exibirBotaoExibicaoMultiplasNds(item) {
      return item.qtdNds && item.qtdNds === 'MULTIPLAS' && item.listaMultiplasNds && item.listaMultiplasNds.length;
    },
    abrirModalExibicaoMultiplasNds(item) {
      this.$refs.ModalExibicaoMultiplasNds.open(item);
    },
  },
  mounted() {
    this.pagination.itemsPerPage = this.itemsPerPage;
  },
};
</script>
<style>
.DashboardPendencias_Planejamento {
  padding-bottom: 0px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title span {
  font-weight: 700;
  margin: 0px;
  font-size: 16px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content {
  padding: 7px 7px 0px 7px ;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content span {
  font-size: 14px;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title button span i,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Title span,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content span,
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Content button span i {
  color: rgba(0,0,0,.70) !important;
}
.DashboardPendencias_Planejamento .Card_Pendencia .Card_Actions {
  padding-top: 0px;
}
</style>
