<template>
  <v-container fluid v-if="mostrarAcompanhamento || podeAprovar">
    <v-row justify="end">

      <v-tooltip left v-if="mostrarAcompanhamento">
        <template v-slot:activator="{ on }">
          <v-btn text icon
                  v-on="on"
                  @click.native="abrirAcompanhamento">
            <v-icon >assignment_turned_in</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.acompanhamento') }}</span>
      </v-tooltip>

      <acao-fluxo
        ref="acaoAprovar"
        icone="thumb_up"
        :id-acao="idAcao"
        :texto-acao="$t('label.aprovar')"
        :status="status"
        :is-contrato="isContrato"
        :is-aprovacao="true"
        :is-modalidade-valor-fixo="isModalidadeValorFixo"
        :data-inicio-contrato="dataInicioContrato"
        :data-fim-contrato="dataFimContrato"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :is-contrato="isContrato"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :is-contrato="isContrato"
        :validar-justificativa="true"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="reprovar">
      </acao-fluxo>

    </v-row>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'ACAO'"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import Confirm from '@/shared-components/dialog/Confirm';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import PlanejamentoAcaoFluxoFuncoes from '@/spa/planejamento-acao/PlanejamentoAcaoFluxoFuncoes';

export default {
  name: 'PlanejamentoAcaoFluxo',
  mixins: [PlanejamentoAcaoFluxoFuncoes],
  props: {
    idAcao: Number,
    idContrato: Number,
    status: String,
    isContrato: {
      type: Boolean,
      default: false,
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
    dataInicioContrato: String,
    dataFimContrato: String,
  },
  components: {
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),

      solicitanteFluxo: false,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      statusPassos: {},
    };
  },
  computed: {
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeAprovar() {
      return this.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.acaoPossuiFluxo && this.status !== 'CANCELADO';
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.idAcao && this.status) {
        this.verificarUsuarioAprovador(this.idAcao, this.status);
      }
    },
    verificarUsuarioAprovador(idAcao, status) {
      if (status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
          });
      }
    },
    reprovar(justificativa) {
      const { idAcao } = this;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const { idAcao } = this;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        })
        .finally(this.carregaInformacoesFluxo());
    },
    enviarAnalise(justificativa) {
      const { idAcao } = this;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento() {
      const { idAcao } = this;
      this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
        .then((res) => {
          if (res.data.passos
              && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
  },
};
</script>
