import acaoConfiguracaoApuracaoTemplate from '@/common/resources/apuracao-template';
import acaoConfiguracaoCampos from '@/common/resources/acao-configuracao-campos';
import agendamento from '@/common/resources/agendamento';
import alertaManutencao from '@/common/resources/alerta-manutencao';
import apuracaoAcaoEdicao from '@/common/resources/apuracao-acao-edicao';
import apuracaoAcaoListagem from '@/common/resources/apuracao-acao-listagem';
import apuracaoAcaoVerbaVariavelListagem from '@/common/resources/apuracao-acao-verba-variavel-listagem';
import apuracaoContrato from '@/common/resources/apuracao-contrato';
import apuracaoContratoParcial from '@/common/resources/apurcao-contrato-parcial';
import arquivoConfiguracao from '@/common/resources/arquivo-configuracao';
import cacheManager from '@/common/resources/cache-manager';
import calendario from '@/common/resources/calendario';
import camposCustomizados from '@/common/resources/campos-customizados';
import centroCusto from '@/common/resources/centro-custo';
import cep from '@/common/resources/cep';
import configuracaoSistema from '@/common/resources/configuracao-sistema';
import dashboardFornecedorConfiguracao from '@/common/resources/dashboard-fornecedor-configuracao';
import dashboardInicialConfiguracao from '@/common/resources/dashboard-inicial-configuracao';
import divisao from '@/common/resources/divisao';
import endereco from '@/common/resources/endereco';
import estruturaUsuario from '@/common/resources/estrutura-usuario';
import extensao from '@/common/resources/extensao';
import filial from '@/common/resources/filial';
import fornecedor from '@/common/resources/fornecedor';
import fornecedorIntegracao from '@/common/resources/fornecedor-integracao';
import importacoes from '@/common/resources/importacoes';
import liquidacaoConfiguracao from '@/common/resources/liquidacao-configuracao';
import recebimento from '@/common/resources/recebimento';
import metadado from '@/common/resources/metadado';
import nivelProduto from '@/common/resources/nivel-produto';
import notas from '@/common/resources/notas';
import notasDebitoConsulta from '@/common/resources/notas-debito-consulta';
import notasDebitoConsultaConfiguracao from '@/common/resources/notas-debito-consulta-configuracao';
import notasDebitoContabilizacao from '@/common/resources/notas-debito-contabilizacao';
import notasDebitoContabilizacaoConfiguracao from '@/common/resources/notas-debito-contabilizacao-configuracao';
import parametrizacaoAcao from '@/common/resources/parametrizacao-acao';
import parametrizacaoContrato from '@/common/resources/parametrizacao-contrato';
import perfil from '@/common/resources/perfil';
import planejamentoAcaoCadastro from '@/common/resources/planejamento-acao-cadastro';
import planejamentoAcaoConfiguracao from '@/common/resources/planejamento-acao-configuracao';
import planejamentoAcaoListagem from '@/common/resources/planejamento-acao-listagem';
import planejamentoContratoCadastro from '@/common/resources/planejamento-contrato-cadastro';
import planejamentoContratoConfiguracao from '@/common/resources/planejamento-contrato-configuracao';
import planejamentoContratoListagem from '@/common/resources/planejamento-contrato-listagem';
import produto from '@/common/resources/produto';
import regiao from '@/common/resources/regiao';
import ssoGrupo from '@/common/resources/grupo';
import ssoRole from '@/common/resources/role';
import termoAceite from '@/common/resources/termo-aceite';
import tipoAcao from '@/common/resources/tipo-acao';
import tipoBeneficio from '@/common/resources/tipo-beneficio';
import tipoContrato from '@/common/resources/tipo-contrato';
import tipoInvestimento from '@/common/resources/tipo-investimento';
import unidadeMedida from '@/common/resources/unidade-medida';
import usuario from '@/common/resources/usuario';
import workflowAcao from '@/common/resources/workflow-acao';
import workflowApuracaoAcao from '@/common/resources/workflow-apuracao-acao';
import fluxoAprovacao from '@/common/resources/workflow-cadastro';
import workflowContrato from '@/common/resources/workflow-contrato';
import workspaceFiltros from '@/common/resources/workspace-filtros';
import magaluRelatorios from './magalu-relatorios';
import notasDebitoIntegracao from './notas-debito-integracao';
import notasReprocessamento from './notas-reprocessamento';
import notasInconsistencia from './notas-inconsistencia';
import notasSellinDevolucao from './notas-sellin-devolucao';
import solicitacaoExportacaoAssincrona from './solicitacao-exportacao-assincrona';
import solicitacaoExportacaoAssincronaRealizado from './solicitacao-exportacao-assincrona-realizado';
import workflowAcaoMagalu from './workflow-acao-redirecionamento';
import workflowApuracaoAcaoMagalu from './workflow-apuracao-acao-redirecionamento';
import tipoUso from './tipo-uso';

function plugin(Vue) {
  Vue.prototype.$api = {
    acaoConfiguracaoApuracaoTemplate,
    acaoConfiguracaoCampos,
    agendamento,
    alertaManutencao,
    apuracaoAcaoEdicao,
    apuracaoAcaoListagem,
    apuracaoAcaoVerbaVariavelListagem,
    apuracaoContrato,
    apuracaoContratoParcial,
    arquivoConfiguracao,
    cacheManager,
    calendario,
    camposCustomizados,
    centroCusto,
    cep,
    configuracaoSistema,
    dashboardFornecedorConfiguracao,
    dashboardInicialConfiguracao,
    divisao,
    endereco,
    estruturaUsuario,
    extensao,
    filial,
    fluxoAprovacao,
    fornecedor,
    fornecedorIntegracao,
    importacoes,
    liquidacaoConfiguracao,
    magaluRelatorios,
    metadado,
    nivelProduto,
    notas,
    notasDebitoConsulta,
    notasDebitoConsultaConfiguracao,
    notasDebitoContabilizacao,
    notasDebitoContabilizacaoConfiguracao,
    notasDebitoIntegracao,
    notasInconsistencia,
    notasReprocessamento,
    notasSellinDevolucao,
    parametrizacaoAcao,
    parametrizacaoContrato,
    perfil,
    planejamentoAcaoCadastro,
    planejamentoAcaoConfiguracao,
    planejamentoAcaoListagem,
    planejamentoContratoCadastro,
    planejamentoContratoConfiguracao,
    planejamentoContratoListagem,
    produto,
    recebimento,
    regiao,
    solicitacaoExportacaoAssincrona,
    solicitacaoExportacaoAssincronaRealizado,
    ssoGrupo,
    ssoRole,
    termoAceite,
    tipoAcao,
    tipoBeneficio,
    tipoContrato,
    tipoInvestimento,
    tipoUso,
    unidadeMedida,
    usuario,
    workflowAcao,
    workflowAcaoMagalu,
    workflowApuracaoAcao,
    workflowApuracaoAcaoMagalu,
    workflowContrato,
    workspaceFiltros,
  };
}

if (typeof window !== 'undefined' && window.Vue) { // eslint-disable-line no-undef
  window.Vue.use(plugin); // eslint-disable-line no-undef
}

// module.exports = plugin;
export default plugin;
