<template>
  <div class="ApuracaoAcaoDetalhesResumoExecucao">
    <template>
      <v-card-title v-if="exibeTitulo">
        <span >{{ $tc('label.resumo_execucao_acao', 1)}}</span>
      </v-card-title>
      <v-data-table
        :key="panelKey"
        :headers="headers"
        :items="resumo"
        v-if="resumoExec !== null"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        class="elevation-1"
      >
        <template v-slot:[`item.vlrApuracaoPrevia`]="{ item }">
          {{ getMoney(item.vlrApuracaoTotal) }}
          <h4 v-if="item.possuiValorMaximoNd" class="orange--text">
            {{ '%execução: ' + getPercent(item.vlrApuracaoTotalExecucao * 100, 0) }}
          </h4>
        </template>
        <template v-slot:[`item.vlrApuracaoFinalOriginal`]="{ item }">
          <div v-if="isApuracaoPrevia(item.status)">
            <div v-if="item.vlrApuracaoFinalOriginal > 0">
              {{ getMoney(item.vlrApuracaoFinalOriginal) }}
              <h4 v-if="item.possuiValorMaximoNd" class="orange--text">
                {{ '%execução: ' + getPercent(item.vlrApuracaoFinalExecucao * 100, 0) }}
              </h4>
            </div>
            <div v-else>
              {{ getMoney(item.vlrApuracaoPrevia) }}
              <h4 v-if="item.possuiValorMaximoNd" class="orange--text">
                {{ '%execução: ' + getPercent(item.vlrApuracaoPreviaExecucao * 100, 0) }}
              </h4>
            </div>
          </div>
          <div v-else>
            {{ getMoney(item.vlrApuracaoFinalOriginal) }}
            <h4 v-if="item.possuiValorMaximoNd" class="orange--text">
              {{ '%execução: ' + getPercent(item.vlrApuracaoFinalExecucao * 100, 0) }}
            </h4>
          </div>
        </template>
        <template v-slot:[`item.vlrTetoAcao`]="{ item }">
          {{ item.possuiValorMaximoNd ? getMoney(item.vlrTetoAcao) : ' - ' }}
          <h5 v-if="item.isCalculoProdutoNdMaximo" class="orange--text">
            {{ $tc('label.agrupamento_por_produto', 2)}}
          </h5>
          <h5 v-else-if="item.isCalculoCampanhaNdMaximo" class="orange--text">
            {{ $tc('label.sobre_teto_da_campanha', 2)}}
          </h5>
        </template>
        <template v-slot:[`item.valorRecebimentoPendente`]="{ item }">
          {{ getMoney(item.valorRecebimentoPendente) }}
        </template>
        <template v-slot:[`item.vlrVerbaAcimaDoTeto`]="{ item }">
          {{ item.possuiValorMaximoNd ? getMoney(item.vlrVerbaAcimaDoTeto) : ' - ' }}
        </template>
        <template v-slot:[`item.notaDebito`]="{ item }">
          {{ getMoney(item.notaDebito) }}
        </template>
      </v-data-table>
    </template>
  </div>
</template>
<script>
import { getMoney, getPercent } from '@/common/functions/helpers';
import Status from '@/shared-components/Status';

export default {
  name: 'ApuracaoAcaoDetalhesResumoExecucao',
  components: {
    getMoney,
    getPercent,
    Status,
  },
  props: {
    resumoExec: {
      type: Object,
      default: null,
    },
    exibeTitulo: true,
  },
  watch: {
    resumoExec: {
      handler() {
        this.resumo = [];
        this.resumo.push(this.resumoExec);
        this.reRenderizarTable();
      },
      deep: true,
    },
  },
  data() {
    return {
      panelKey: 0,
      resumo: [],
      headers: [
        { text: this.$tc('label.id', 1), value: 'id' },
        {
          text: this.$tc('label.fornecedor', 1), value: 'focoAcao', class: 'nowrap',
        },
        {
          text: this.$tc('label.inicio_vigencia', 1), value: 'dtInicioVigencia', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.fim_vigencia', 1), value: 'dtFimVigencia', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_total', 1), value: 'vlrApuracaoPrevia', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.apuracao_final', 1), value: 'vlrApuracaoFinalOriginal', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.teto_acao', 1), value: 'vlrTetoAcao', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.recebimento_pendente', 1), value: 'valorRecebimentoPendente', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.verba_acima_teto', 1), value: 'vlrVerbaAcimaDoTeto', class: 'nowrap', cellClass: 'nowrap',
        },
        {
          text: this.$tc('label.nota_debito', 1), value: 'notaDebito', class: 'nowrap', cellClass: 'nowrap',
        },
      ],
    };
  },
  methods: {
    getMoney,
    getPercent,
    reRenderizarTable() {
      this.panelKey += 1;
    },
    isApuracaoPrevia(status) {
      return status === 'APURACAO_PREVIA';
    },
  },
  mounted() {
    this.resumo = [];
    this.resumo.push(this.resumoExec);
    this.reRenderizarTable();
  },
};
</script>
