import getBasePath from '../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'configuracao');

const configuracaoActions = {
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/campos/filtro` },
  buscarCamposGrid: { methods: 'GET', url: `${basePath}/campos/grid/{idTipoAcao}` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/vigente/{idTipoAcao}` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/snapshot/{idAcao}` },
};

export default (resource) => resource(`${basePath}`, {}, configuracaoActions);
