import Workflow from '@/spa/workflow/Workflow';
import WorkflowList from '@/spa/workflow/WorkflowList';
import WorkflowForm from '@/spa/workflow/WorkflowForm';

export default {
  path: 'workflow',
  component: Workflow,
  children: [
    {
      path: '',
      name: 'workflow',
      component: WorkflowList,
    },
    {
      path: 'novo',
      name: 'novoWorkflow',
      component: WorkflowForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
      },
    },
    {
      path: ':id',
      name: 'verWorkflow',
      component: WorkflowForm,
      props: {
        default: true,
        somenteLeitura: true,
        copia: false,
      },
    },
    {
      path: ':id/editar',
      name: 'editarWorkflow',
      component: WorkflowForm,
      props: {
        default: true,
        somenteLeitura: false,
        copia: false,
      },
    },
  ],
};
