<template lang="html">
  <div v-if="canAccessPage">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title>
          <h3 class="headline mb-0">{{ $tc('label.alerta_manutencao', 1) }}</h3>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="6" sm="2" md="2">
              <v-autocomplete
                v-model="tipoDeAlertaSelecionado"
                :items="options"
                :label="$tc('label.tipo_de_alerta', 1)"
                item-text="label"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            </v-row>
          </v-container >
        <v-container grid-list-xl fluid>
          <v-row>
            <template v-if="isComunicacao">
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                id="grupo-solicitante"
                class="custom-autocomplete"
                required
                :rules="[rules.requiredMultiple]"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="`${$tc('label.perfil', 1)} *`"
                v-model="perfisSelecionados"
                :items="perfis"
                item-text="nomPerfil"
                item-value="id"
                return-object
                append-icon
                cache-items
                multiple
                chips
                deletable-chips>
              </v-autocomplete>
              </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                class="CalendarioForm-data__text"
                id="alerta_manutencao_link"
                name="alerta_manutencao_link"
                v-model="alertaManutencao.link"
                label="Link"
                :rules="[rules.required]">
              </v-text-field>
            </v-col>
            </template>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                class="CalendarioForm-data__text"
                id="alerta_manutencao_mensagem"
                name="alerta_manutencao_mensagem"
                v-model="alertaManutencao.mensagem"
                :label="`${$tc('label.mensagem', 1)} *`"
                :counter="100"
                :rules="[rules.required]">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                class="filtro-acesso-usuario__select"
                v-model="alertaManutencao.dataInicio"
                v-mask="'##/##/#### ##:##'"
                hint='DD/MM/AAAA HH:mm'
                :persistent-hint="true"
                :return-masked-value="true"
                :rules="[rules.required, rules.validDate]"
                :label="`${$t('label.data_inicio')} *`">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                class="filtro-acesso-usuario__select"
                v-model="alertaManutencao.dataFim"
                v-mask="'##/##/#### ##:##'"
                hint='DD/MM/AAAA HH:mm'
                :persistent-hint="true"
                :return-masked-value="true"
                :rules="[rules.required, rules.validDate]"
                :label="`${$t('label.data_fim')} *`">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="todosCamposPreenchidos">
            <v-col cols="12" sm="12">
              {{ $tc('label.texto_alerta', 1) }}: {{ previsaoMensagem }}
              <a v-if="isComunicacao && alertaManutencao.link" :href="alertaManutencao.link" target="_blank" rel="noopener noreferrer">
                {{ $t('label.saiba_mais') }}
              </a>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
          <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </v-card>
      <confirm
        ref="confirmDialog"
        :message="$t('message.deseja_salvar', {text: $tc('label.alerta_manutencao', 1)})"
        :persistent="true"
        @agree="salvar">
      </confirm>
    </v-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'AlertaManutencaoForm',
  components: {
    Confirm,
  },
  data() {
    return {
      alertaManutencaoResources: this.$api.alertaManutencao(this.$resource),
      perfilResources: this.$api.perfil(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 16 && this.moment(value.substring(0, 9), 'DD/MM/YYYY').isValid())) || this.$t('message.data_invalida'),
        requiredMultiple: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
      alertaManutencao: {
        mensagem: null,
        dataInicio: null,
        dataFim: null,
      },
      tipoDeAlertaSelecionado: null,
      options: [
        { id: 1, label: this.$t('message.manutencao') },
        { id: 2, label: this.$t('message.comunicacao') },
      ],
      perfis: [],
      idsPerfil: [],
      perfisSelecionados: [],
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ALERTA_MANUTENCAO').length;
    },
    todosCamposPreenchidos() {
      return this.alertaManutencao.mensagem
        && this.alertaManutencao.dataInicio
        && this.alertaManutencao.dataInicio.length === 16
        && this.alertaManutencao.dataFim
        && this.alertaManutencao.dataFim.length === 16;
    },
    previsaoMensagem() {
      if (this.todosCamposPreenchidos) {
        const params = {
          mensagem: this.alertaManutencao.mensagem,
          dataInicio: this.alertaManutencao.dataInicio.substring(0, 10),
          dataFim: this.alertaManutencao.dataFim.substring(0, 10),
          horaInicio: this.alertaManutencao.dataInicio.substring(11),
          horaFim: this.alertaManutencao.dataFim.substring(11),
        };
        if (this.isComunicacao) {
          if (params.dataInicio === params.dataFim) {
            return this.$t('message.alerta_manutencao_inicio_fim_mesmo_dia_comunicacao', params);
          }

          return this.$t('message.alerta_manutencao_inicio_fim_dias_diferentes_comunicacao', params);
        }
        if (params.dataInicio === params.dataFim) {
          return this.$t('message.alerta_manutencao_inicio_fim_mesmo_dia', params);
        }

        return this.$t('message.alerta_manutencao_inicio_fim_dias_diferentes', params);
      }
      return '';
    },
    isComunicacao() {
      return this.tipoDeAlertaSelecionado === 2;
    },
    isManutencao() {
      return this.tipoDeAlertaSelecionado === 1;
    },
  },
  methods: {
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      this.$refs.confirmDialog.close();

      const params = {
        ...this.alertaManutencao,
        data_inicio: this.alertaManutencao.dataInicio,
        data_fim: this.alertaManutencao.dataFim,
        tipo_alerta: this.isComunicacao ? 'COMUNICACAO' : 'MANUTENCAO',
      };
      if (this.isComunicacao) {
        const idsPerfil = this.perfisSelecionados.map((perfil) => perfil.id).join(',');
        params.ids_perfil = idsPerfil;
        params.link = this.alertaManutencao.link;
      }
      if (this.alertaManutencao.id) {
        this.alertaManutencaoResources.atualizar(params)
          .then(() => {
            this.$router.push({ name: 'alerta-manutencao' });
            this.$toast(this.$t('message.atualizado_confira_tabela'));
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      } else {
        this.alertaManutencaoResources.gravar(params)
          .then(() => {
            this.$router.push({ name: 'alerta-manutencao' });
            this.$toast(this.$t('message.adicionado_confira_tabela'));
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    cancelar() {
      this.$router.push({ name: 'alerta-manutencao' });
    },
    carregarAlertaManutencao(id) {
      this.alertaManutencaoResources.buscar({ id })
        .then((res) => {
          this.alertaManutencao = { ...this.alertaManutencao, ...res.body };

          if (this.alertaManutencao.tipoAlerta === 'COMUNICACAO') {
            const perfisIds = this.alertaManutencao.idsPerfil.split(',');
            this.perfisSelecionados = this.perfis.filter((perfil) => perfisIds.includes(String(perfil.id)));
          }

          this.tipoDeAlertaSelecionado = this.alertaManutencao.tipoAlerta === 'COMUNICACAO' ? 2 : 1;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarPerfil() {
      const promise = this.perfilResources.listarAtivos({})
        .then((r) => {
          this.perfis = r.data.resposta;
        })
        .catch((err) => {
          this.$error(this, err);
        });
      return promise;
    },
  },
  mounted() {
    this.buscarPerfil().then(() => {
      const { id } = this.$route.params;
      if (id) {
        this.carregarAlertaManutencao(id);
      }
    });
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
