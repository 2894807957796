const PlanejamentoContrato = () => import('@/spa/planejamento-contrato/PlanejamentoContrato');
const PlanejamentoContratoList = () => import('@/spa/planejamento-contrato/list/PlanejamentoContratoList');
const PlanejamentoContratoForm = () => import('@/spa/planejamento-contrato/form/PlanejamentoContratoForm');
const PlanejamentoEdicaoContratoForm = () => import('@/spa/planejamento-contrato/form/PlanejamentoEdicaoContratoForm');
const AlterarPrazoVencimento = () => import('@/spa/planejamento-contrato/alterar-prazo-vencimento/AlterarPrazoVencimento');

export default {
  path: 'planejamento-contrato',
  component: PlanejamentoContrato,
  children: [
    {
      path: '',
      name: 'planejamento-contrato',
      component: PlanejamentoContratoList,
    },
    {
      path: ':idTipoContrato/novo',
      name: 'novoContrato',
      component: PlanejamentoContratoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
        edicao: false,
        novo: true,
      },
    },
    {
      path: ':idContrato/editar',
      name: 'editarContrato',
      component: PlanejamentoEdicaoContratoForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idContrato/editar_',
      name: 'editarContrato_',
      component: PlanejamentoContratoForm,
      props: {
        somenteLeitura: false,
        edicao: true,
        novo: false,
      },
    },
    {
      path: ':idContrato/detalhar',
      name: 'detalharContrato',
      component: PlanejamentoContratoForm,
      props: {
        default: false,
        somenteLeitura: true,
        copia: false,
        edicao: false,
        novo: false,
      },
    },
    {
      path: 'alterar-prazo-vencimento',
      name: 'alterarPrazoVencimento',
      component: AlterarPrazoVencimento,
    },
  ],
};
