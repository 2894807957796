import getBasePath from '../functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'contrato/configuracao');

const configuracaoActions = {
  buscarCamposFiltro: { methods: 'GET', url: `${basePath}/campos/filtro` },
  buscarCamposGrid: { methods: 'GET', url: `${basePath}/campos/grid/{idTipoContrato}` },
  buscarConfigVigente: { methods: 'GET', url: `${basePath}/vigente/{idTipoContrato}` },
  buscarConfigSnapshot: { methods: 'GET', url: `${basePath}/snapshot/{idContrato}` },
};

export default (resource) => resource(`${basePath}`, {}, configuracaoActions);
