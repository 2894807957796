<template>
  <v-container grid-list-xl fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          :id="`workflow-form-passo-descricao-${hashId}`"
          :rules="[rules.required]"
          :label="`${$t('label.descricao')} *`"
          v-model="passo.descricao"
          maxlength="100"
          counter
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <input-number
          :id="`workflow-form-passo-sla-${hashId}`"
          :label="$t('label.sla')"
          required
          v-model="passo.sla"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          :id="`workflow-form-passo-tipo-${hashId}`"
          :rules="[rules.requiredSelect]"
          :label="`${$t('label.selecione_aprovadores')} *`"
          :items="tiposAprovador"
          item-text="texto"
          item-value="valor"
          v-model="tipoAprovador"
          @input="tipoAprovadorSelecionado"
        ></v-select>
      </v-col>

      <v-col cols="12" md="4" v-if="aprovadorUsuario">
        <v-autocomplete
          :id="`workflow-form-passo-usuario-${hashId}`"
          class="custom-autocomplete"
          required
          :rules="[rules.requiredMultiple]"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          :label="`${$tc('label.workflow_buscar_usuarios', 1)} *`"
          v-model="passo.usuariosAprovadores.usuarios"
          :items="usuariosAprovadores"
          item-text="nome"
          cache-items
          return-object
          :search-input.sync="usuarioAprovadorInput"
          @click:append="triggerUsuarioAprovador"
          @click.native="buscarUsuarioAprovador"
          multiple
          chips
          deletable-chips>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="4" v-if="aprovadorGrupo">
        <v-autocomplete
          :id="`workflow-form-passo-grupo-${hashId}`"
          class="custom-autocomplete"
          required
          :rules="[rules.requiredMultiple]"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          :label="`${$tc('label.workflow_buscar_grupos', 1)} *`"
          v-model="passo.usuariosAprovadores.grupos"
          :items="gruposAprovadores"
          item-text="nome"
          cache-items
          return-object
          :search-input.sync="grupoAprovadorInput"
          @click:append="triggerGrupoAprovador"
          @click.native="buscarGrupoAprovador"
          multiple
          chips
          deletable-chips>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" v-if="aprovadorGrupo">
        <v-autocomplete
          :id="`workflow-form-passo-subgrupo-${hashId}`"
          class="custom-autocomplete"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          :label="`${$tc('label.workflow_buscar_subgrupos', 1)}`"
          v-model="passo.usuariosAprovadores.subgrupos"
          :items="subGruposAprovadores"
          item-text="nome"
          cache-items
          return-object
          :search-input.sync="subGrupoAprovadorInput"
          @click:append="triggerSubGrupoAprovador"
          @click.native="buscarSubGrupoAprovador"
          multiple
          chips
          deletable-chips>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          :id="`workflow-form-passo-condicional-${hashId}`"
          :label="isPerfilExternoSelecionado ? `${$tc('label.condicao', 1)} *` : `${$tc('label.condicao', 1)}`"
          :items="condicionais"
          :disabled="!condicionais.length"
          :rules="isPerfilExternoSelecionado ? [rules.requiredSelect] : []"
          item-text="nome"
          item-value="id"
          v-model="passo.idCondicional"
          @input="condicionalSelecionada"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" v-if="isCondicionalFornecedorContratoSelecionada">
        <v-select
          :id="`workflow-form-passo-tipo-fornecedor-${hashId}`"
          :label="`${$tc('label.tipo_fornecedor', 1)} *`"
          :rules="[rules.requiredSelect]"
          :items="tiposFornecedor"
          item-text="texto"
          item-value="valor"
          v-model="passo.tipoFornecedor"
          clearable
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
  <v-col cols="12" sm="3" md="4" v-if="habilitaSwitch">
    <v-switch v-if="habilitaSwitch"
      v-model="passo.indSubstituicaoContrato"
      :label="`${$t('message.apresentar_contratos_renovacao')}`"
      color="primary"
    ></v-switch>
  </v-col>
  <v-col cols="12" sm="3" md="4" v-if="habilitaSwitchEmail">
    <v-switch v-if="habilitaSwitchEmail"
      v-model="passo.indEnviaEmailAnalise"
      :label="`${$t('message.enviar_email_analise')}`"
      color="primary"
    ></v-switch>
  </v-col>
  <v-col cols="12" sm="3" md="4" v-if="passo.indEnviaEmailAnalise">
        <v-autocomplete
          :id="`workflow-form-passo-perfil-email-${hashId}`"
          class="custom-autocomplete"
          required
          :rules="[rules.requiredMultiple]"
          :no-data-text="$tc('message.nenhum_registro', 1)"
          :label="`${$tc('label.destinatario_email', 1)} *`"
          v-model="passo.perfilDestinatarios"
          :items="listaPerfil"
          item-text="nome"
          cache-items
          return-object
          :search-input.sync="perfilEmailInput"
          @click:append="triggerPerfilEmail"
          @click.native="buscarPerfil"
          multiple
          chips
          deletable-chips>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import InputNumber from '@/shared-components/inputs/InputNumber';

export default {
  components: {
    InputNumber,
  },
  props: {
    passo: Object,
    condicionais: Array,
    habilitaSwitch: Boolean,
    habilitaSwitchEmail: Boolean,
  },
  computed: {
    isCondicionalFornecedorContratoSelecionada() {
      if (!this.passo || !this.passo.idCondicional) {
        return false;
      }
      const condicionalSelecionada = this.condicionais.filter((c) => c.id === this.passo.idCondicional);
      if (!condicionalSelecionada || !condicionalSelecionada[0]) {
        return false;
      }
      return ['CONTRATO_PASSO_FORNECEDOR'].includes(
        condicionalSelecionada[0].chave,
      );
    },
    isCondicionalFornecedorAcaoSelecionada() {
      if (!this.passo || !this.passo.idCondicional) {
        return false;
      }
      const condicionalSelecionada = this.condicionais.filter((c) => c.id === this.passo.idCondicional);
      if (!condicionalSelecionada || !condicionalSelecionada[0]) {
        return false;
      }
      return ['ACAO_PASSO_FORNECEDOR'].includes(
        condicionalSelecionada[0].chave,
      );
    },
    isCondicionalFornecedorApuracaoSelecionada() {
      if (!this.passo || !this.passo.idCondicional) {
        return false;
      }
      const condicionalSelecionada = this.condicionais.filter((c) => c.id === this.passo.idCondicional);
      if (!condicionalSelecionada || !condicionalSelecionada[0]) {
        return false;
      }
      return ['APURACAO_PASSO_FORNECEDOR'].includes(
        condicionalSelecionada[0].chave,
      );
    },
    isPerfilExternoSelecionado() {
      return this.passo.usuariosAprovadores.grupos.some((grupo) => grupo.indPerfilUsuarioExterno === true);
    },
  },
  data() {
    return {
      usuarioResources: this.$api.usuario(this.$resource),
      perfilResources: this.$api.perfil(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),

      hashId: null,

      tiposAprovador: [
        {
          valor: 'usuario',
          texto: this.$t('label.workflow_solicitante_usuario'),
        },
        {
          valor: 'grupo',
          texto: this.$t('label.workflow_solicitante_grupo'),
        },
      ],
      tiposFornecedor: [
        {
          valor: 'APROVADOR_AUTORIZADO',
          texto: this.$t('label.aprovador_autorizado'),
        },
        {
          valor: 'REPRESENTANTE_LEGAL',
          texto: this.$t('label.tipos_fornecedor.representante_legal'),
        },
      ],
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredSelect: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredMultiple: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
      usuariosAprovadores: [],
      gruposAprovadores: [],
      subGruposAprovadores: [],
      listaPerfil: [],
      aprovadorUsuario: false,
      aprovadorGrupo: false,

      usuarioAprovadorInput: null,
      grupoAprovadorInput: null,
      subGrupoAprovadorInput: null,
      perfilEmailInput: null,

      tipoAprovador: null,
    };
  },
  watch: {
    tipoAprovador() {
      this.aprovadorUsuario = false;
      this.aprovadorGrupo = false;
      setTimeout(() => {
        this.aprovadorUsuario = this.tipoAprovador === 'usuario';
        this.aprovadorGrupo = this.tipoAprovador === 'grupo';
        this.usuariosAprovadores = [...this.passo.usuariosAprovadores.usuarios];
        this.gruposAprovadores = [...this.passo.usuariosAprovadores.grupos];
        this.subGruposAprovadores = [...this.passo.usuariosAprovadores.subgrupos];
      }, 1E2);
    },
    usuarioAprovadorInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarUsuarioAprovador();
      }, 500);
    },
    grupoAprovadorInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarGrupoAprovador();
      }, 500);
    },
    subGrupoAprovadorInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarSubGrupoAprovador();
      }, 500);
    },
    perfilEmailInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarPerfil();
      }, 500);
    },
  },
  methods: {
    triggerUsuarioAprovador() {
      this.triggerClick(`workflow-form-passo-usuario-${this.hashId}`);
    },
    buscarUsuarioAprovador() {
      const nome = this.usuarioAprovadorInput;
      this.usuarioResources.listarAtivosResumido({ nome })
        .then((r) => {
          this.usuariosAprovadores = r.data.resposta;
          if (this.usuariosAprovadores) {
            this.usuariosAprovadores.forEach((u) => {
              u.nome = u.nomeSobrenome;
            });
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerGrupoAprovador() {
      this.triggerClick(`workflow-form-passo-grupo-${this.hashId}`);
    },
    buscarGrupoAprovador() {
      const nome = this.grupoAprovadorInput;
      this.perfilResources.listarAtivos({ nome })
        .then((r) => {
          this.gruposAprovadores = r.data.resposta
            .map((grupo) => ({ id: grupo.id, nome: grupo.nomPerfil }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerSubGrupoAprovador() {
      this.triggerClick(`workflow-form-passo-subgrupo-${this.hashId}`);
    },
    buscarSubGrupoAprovador() {
      const nome = this.subGrupoAprovadorInput;
      this.estruturaUsuarioResources.listarAtivos({ nome })
        .then((r) => {
          this.subGruposAprovadores = r.data.resposta
            .map((subGrupo) => ({ id: subGrupo.id, nome: subGrupo.nomEstruturaUsuario }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerPerfilEmail() {
      this.triggerClick(`workflow-form-passo-perfil-email-${this.hashId}`);
    },
    buscarPerfil() {
      const nome = this.perfilEmailInput;
      this.perfilResources.listarAtivos({ nome })
        .then((r) => {
          this.listaPerfil = r.data.resposta
            .map((perfil) => ({ id: perfil.id, nome: perfil.nomPerfil }));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    triggerClick(id) {
      setTimeout(() => document.getElementById(id).click());
    },
    tipoAprovadorSelecionado(tipoAprovador) {
      this.passo.tipoAprovador = tipoAprovador;
    },
    condicionalSelecionada(condicional) {
      if (this.isCondicionalFornecedorAcaoSelecionada || this.isCondicionalFornecedorContratoSelecionada
        || this.isCondicionalFornecedorApuracaoSelecionada) {
        this.passo.tipoFornecedor = 'APROVADOR_AUTORIZADO';
      } else if (!condicional || !this.isCondicionalFornecedorContratoSelecionada) {
        this.passo.tipoFornecedor = null;
      }
    },
  },
  beforeMount() {
    this.hashId = Math.floor(Math.random() * 1000) + 1;
  },
  mounted() {
    setTimeout(() => {
      this.usuariosAprovadores = [...this.passo.usuariosAprovadores.usuarios || []];
      this.gruposAprovadores = [...this.passo.usuariosAprovadores.grupos || []];
      this.subGruposAprovadores = [...this.passo.usuariosAprovadores.subgrupos || []];
      this.listaPerfil = [...this.passo.perfilDestinatarios || []];
      if (this.passo.tipoAprovador) {
        this.tipoAprovador = this.passo.tipoAprovador;
      }
    });
  },
};
</script>
