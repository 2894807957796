export default {
  data() {
    return {
      notaDebitoResource: this.$api.notasDebitoConsulta(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),

      camposFormularioMagalu: {
        padrao: [
          {
            labelCampo: 'numero',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_apuracao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'status_nd',
            nomCampoId: 'status_entidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('status_entidade.aguardando_aprovacao'),
                    valor: 'AGUARDANDO_APROVACAO',
                  },
                  {
                    texto: this.$tc('status_entidade.aguardando_apuracao', 1),
                    valor: 'AGUARDANDO_APURACAO',
                  },
                  {
                    texto: this.$tc('status_entidade.apuracao_previa', 1),
                    valor: 'APURACAO_PREVIA',
                  },
                  {
                    texto: this.$tc('status_entidade.em_cadastro', 1),
                    valor: 'EM_CADASTRO',
                  },
                  {
                    texto: this.$tc('status_entidade.aprovado', 1),
                    valor: 'APROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.reprovado', 1),
                    valor: 'REPROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.cancelado', 1),
                    valor: 'CANCELADO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_analise', 1),
                    valor: 'EM_ANALISE',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'modalidade',
            nomCampoId: 'modalidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('label.faturamento_fornecedor'),
                    valor: 'FATURAMENTO_FORNECEDOR',
                  },
                  {
                    texto: this.$t('label.sellin'),
                    valor: 'SELLIN',
                  },
                  {
                    texto: this.$tc('label.sellout', 1),
                    valor: 'SELLOUT',
                  },
                  {
                    texto: this.$tc('label.valor_fixo', 1),
                    valor: 'VALOR_FIXO',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'descricao_passo',
            nomCampoId: 'descricao_passo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.workflowAcaoMagaluResource.buscarPassosDescricao({ autocomplete }),
              itemValue: 'descricao',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status_integracao',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                  { texto: `${this.$tc('status_integracao.INTEGRADO', 1)}`, valor: 'INTEGRADO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_DATA_CONTABILIZACAO', 1)}`, valor: 'PENDENTE_DATA_CONTABILIZACAO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_INTEGRACAO', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_inicio_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = { autocomplete };
                return this.divisaoResource.buscarDivisoesPorVisao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'origem',
            nomCampoId: 'origem',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.contrato', 1)}`, valor: 'CONTRATO' },
                { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'ACAO' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'data_inicio_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'unidadenegocio',
            nomCampoId: 'id_unidadenegocio',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarUnidadesNegocioEntidadesControlador({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'data_inicio_criacao_nd',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_criacao_nd',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'codigo_campanha',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_inicio_criacao_entidade',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_criacao_entidade',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'codigo_ads_trade',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'valor_ads',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            nomCampoId: 'cod_fornecedor',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'ocultar_apuracoes_canceladas',
            tipoCampo: 'BOOLEAN',
            valorDefault: true,
          },
          {
            labelCampo: 'ocultar_nds_zeradas',
            tipoCampo: 'BOOLEAN',
            valorDefault: true,
          },
        ],
      },
      camposFormularioKabum: {
        padrao: [
          {
            labelCampo: 'numero',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'fornecedor',
            nomCampoId: 'id_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'data_inicio_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'divisao',
            nomCampoId: 'id_divisao',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = { autocomplete };
                return this.divisaoResource.buscarDivisoesPorVisao(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'data_inicio_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'descricao_passo',
            nomCampoId: 'descricao_passo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.workflowAcaoMagaluResource.buscarPassosDescricao({ autocomplete }),
              itemValue: 'descricao',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'data_inicio_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            nomCampoId: 'cod_fornecedor',
            tipoCampo: 'TEXTO',
          },
        ],
      },
      camposFormularioEpoca: {
        padrao: [
          {
            labelCampo: 'numero_nd',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'numero_nf',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_anexo_comercial',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_acao',
            tipoCampo: 'NUMERICO',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'grupo_fornecedor',
            nomCampoId: 'id_grupo_fornecedor',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.planejamentoAcaoResource.buscarGruposFornecedores({ autocomplete }),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'status_nd_nf',
            nomCampoId: 'status_entidade',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  {
                    texto: this.$t('status_entidade.aguardando_aprovacao'),
                    valor: 'AGUARDANDO_APROVACAO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_cadastro', 1),
                    valor: 'EM_CADASTRO',
                  },
                  {
                    texto: this.$tc('status_entidade.aprovado', 1),
                    valor: 'APROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.reprovado', 1),
                    valor: 'REPROVADO',
                  },
                  {
                    texto: this.$tc('status_entidade.cancelado', 1),
                    valor: 'CANCELADO',
                  },
                  {
                    texto: this.$tc('status_entidade.em_analise', 1),
                    valor: 'EM_ANALISE',
                  },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'descricao_passo',
            nomCampoId: 'descricao_passo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.workflowAcaoMagaluResource.buscarPassosDescricao({ autocomplete }),
              itemValue: 'descricao',
              itemText: 'descricao',
            },
          },
          {
            labelCampo: 'status_integracao',
            nomCampoId: 'status',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: () => new Promise((resolve) => resolve({
                data: [
                  { texto: `${this.$tc('status_integracao.ERRO', 1)}`, valor: 'ERRO' },
                  { texto: `${this.$tc('status_integracao.INTEGRADO', 1)}`, valor: 'INTEGRADO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_DATA_CONTABILIZACAO', 1)}`, valor: 'PENDENTE_DATA_CONTABILIZACAO' },
                  { texto: `${this.$tc('status_integracao.PENDENTE_INTEGRACAO', 1)}`, valor: 'PENDENTE_INTEGRACAO' },
                ],
              })),
              itemValue: 'valor',
              itemText: 'texto',
            },
          },
          {
            labelCampo: 'data_inicio_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vencimento',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_inicio_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_contabilizacao',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'tipo_acao',
            nomCampoId: 'id_acao_tipo',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            vlrObrigatorio: true,
            async: {
              fetchFunction: (autocomplete) => this.tipoAcaoResource.buscarTodosTiposAcao({ autocomplete }),
              itemValue: 'id',
              itemText: 'nome',
            },
          },
          {
            labelCampo: 'origem',
            nomCampoId: 'origem',
            tipoCampo: 'LISTA',
            vlrObrigatorio: true,
            desAtributos: {
              lista: [
                { texto: `${this.$tc('label.contrato', 1)}`, valor: 'CONTRATO' },
                { texto: `${this.$tc('label.acao_tatica', 1)}`, valor: 'ACAO' },
              ],
              textoItem: 'texto',
              valorItem: 'valor',
            },
          },
          {
            labelCampo: 'data_inicio_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'data_fim_vigencia',
            tipoCampo: 'DATA',
            vlrObrigatorio: true,
          },
          {
            labelCampo: 'cod_fornecedor_acao',
            nomCampoId: 'cod_fornecedor',
            tipoCampo: 'TEXTO',
          },
          {
            labelCampo: 'ocultar_apuracoes_canceladas',
            tipoCampo: 'BOOLEAN',
            valorDefault: true,
          },
          {
            labelCampo: 'ocultar_nds_zeradas',
            tipoCampo: 'BOOLEAN',
            valorDefault: true,
          },
        ],
      },
    };
  },
};
