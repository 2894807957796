import Produto from '@/spa/produto/Produto';

const ProdutoList = () => import('@/spa/produto/ProdutoList');
const ProdutoForm = () => import('@/spa/produto/ProdutoForm');

export default {
  path: 'produto',
  component: Produto,
  children: [
    {
      path: '',
      name: 'produto',
      component: ProdutoList,
    },
    {
      path: 'novo',
      name: 'novoProduto',
      component: ProdutoForm,
      props: {
        default: false,
        somenteLeitura: false,
        copia: false,
      },
    },
    {
      path: ':id',
      name: 'verProduto',
      component: ProdutoForm,
      props: {
        default: true,
        somenteLeitura: true,
        copia: false,
      },
    },
    {
      path: ':id/editar',
      name: 'editarProduto',
      component: ProdutoForm,
      props: {
        default: true,
        somenteLeitura: false,
        copia: false,
      },
    },
  ],
};
