<template>
  <div
    :class="`${item.label || ''}__Wrapper`">
    <v-row>
      <v-col cols="12" sm="3">
        <metadados-extensao-form
          :id="idUnidade"
          :ref="`${item.label || ''}Form`"
          :idNivelExtensao="item.idNivelExtensao"
          :tipoCadastro="$tc(`label.${item.label}`, 1)"
          @atualizarGrid="filtrar"
          :somenteLeitura="somenteLeitura"
          @limparId="limparId"
          :input-layout="inputLayout"/>
      </v-col>
      <v-col cols="12" sm="9">
        <div class="__ContentTable">
          <v-card
            class="mb-1 mt-2">
            <v-card-title
              v-if="item && item.label">
              <h3 class="headline mb-0">{{ $tc(`label.${item.label}`) }}</h3>
            </v-card-title>
            <v-row justify="start">
              <v-col cols="12" sm="6" md="3" class="ml-3">
                <v-text-field
                  v-model="search"
                  @input="filtrar"
                  append-icon="search"
                  :label="$t('label.pesquisar')"
                  single-line
                  clearable
                  hide-details>
                </v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="unidades"
              :options.sync="pagination"
              :server-items-length="totalPage"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50],
              }">
              <template v-slot:item.acoes="{ item }" v-if="!somenteLeitura">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" v-on="on" @click.stop="editarCampo(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ `${$t('label.editar')} ${$t(`label.${labelEntidade}`)}` }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.id_externo="{ item }">
                {{ item.idExterno }}
              </template>
              <template v-slot:item.nom_extensao="{ item }">
                {{ item.nomExtensao }}
              </template>
              <template v-slot:item.ind_ativo="{ item }">
                {{ item.indAtivo ? $t('label.sim') : $t('label.nao') }}
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import MetadadosExtensaoForm from '@/shared-components/metadados-extensao/MetadadosExtensaoForm';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'TabelaGenerica',
  data() {
    return {
      idUnidade: null,
      inputLayout: {
        ml1: false,
        mt1: false,
        mt2: true,
      },
      search: '',
      resources: this.$api.extensao(this.$resource),
      esperar: false,
      pagination: {},
      totalPage: 0,
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_externo' },
        { text: this.$tc('label.descricao', 1), value: 'nom_extensao' },
      ],
      unidades: [],
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    idNivelExtensao: Number,
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  components: {
    MetadadosExtensaoForm,
    Confirm,
  },
  computed: {
    labelEntidade() {
      return this.item && this.item.label
        ? this.item.label
        : '';
    },
  },
  methods: {
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    editItem(item) {
      item = this.setAtributos(item);
      this.$refs[`${this.item.label || ''}Form`].iniciarEdicao(item);
    },
    buscar() {
      if (this.search) {
        this.pagination.page = 1;
      }

      const params = {
        nome: this.search,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
        idNivelExtensao: this.idNivelExtensao,
      };

      this.resources.listar(params).then((response) => {
        this.unidades = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    editarCampo(item) {
      this.idUnidade = item.id;
      this.$refs[`${this.item.label || ''}Form`].iniciarEdicao(item);
    },
    limparId() {
      this.idUnidade = null;
    },
  },
  mounted() {
    this.filtrar();
    if (!this.somenteLeitura) {
      this.headers.unshift({
        text: '', value: 'acoes', sortable: false, width: '1%', align: 'center',
      });
    }
  },
};
</script>
<style>
  .__ContentTable {
    width: 100% !important;
  }
</style>
