import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'metadados/entidade');

const marcaActions = {
  listarCliente: { method: 'GET', url: `${basePath}/cliente` },
  listarProduto: { method: 'GET', url: `${basePath}/produto` },
  listarUsuario: { method: 'GET', url: `${basePath}/usuario` },
  listarDivisao: { method: 'GET', url: `${basePath}/divisao` },
  listarFornecedor: { method: 'GET', url: `${basePath}/fornecedor` },
  definicaoCentroCusto: { method: 'GET', url: `${basePath}/ceco` },
  definicaoAcao: { method: 'GET', url: `${basePath}/acao` },
  definicaoContrato: { method: 'GET', url: `${basePath}/contrato` },
  definicaoFilial: { method: 'GET', url: `${basePath}/filial` },
};

export const marcaResource = (resource) => resource(`${basePath}`, {}, marcaActions);

export default marcaResource;
