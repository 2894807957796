<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-row>
      <v-col cols="12" sm="3" v-if="!somenteLeitura">
        <unidade-medida-form
          ref="unidadeMedidaForm"
          @FORMULARIO_CADASTRO_UNIDADE_SALVAR="onUnidadeSalva"
        />
      </v-col>
      <v-col cols="12" :sm="somenteLeitura ? 12 : 9">
        <unidade-medida-lista
          ref="unidadeTabela"
          class="CRUDGenerico__ContentTable"
          :title="$tc('label.unidade_medida', 2)"
          @PESQUISA_UNIDADE_EDITAR="onEditarUnidade"/>
      </v-col>
    </v-row>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '@/common/functions/roles-computed-generator';
import UnidadeMedidaForm from '@/spa/unidade-medida/UnidadeMedidaForm';
import UnidadeMedidaLista from '@/spa/unidade-medida/UnidadeMedidaLista';

export default {
  name: 'UnidadeMedidaCadastro',
  data() {
    return {
    };
  },
  components: {
    UnidadeMedidaForm,
    UnidadeMedidaLista,
  },
  computed: {
    ...generateComputed('unid medida', [
      'canAccessCRUD',
      'canAccessPage',
    ]),
    somenteLeitura() {
      return !this.canAccessCRUD;
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    onEditarUnidade(unidade) {
      this.$refs.unidadeMedidaForm.editar(unidade);
    },
    onUnidadeSalva() {
      this.$refs.unidadeTabela.filtrar();
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
<style scoped>
.CRUDGenerico__ContentTable  {
  width: 100%;
}
</style>
