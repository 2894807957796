<template>
  <v-card style="margin-bottom:1em" class="RelatorioLogAcoesFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.log_acao_e_contrato', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          v-if="camposFormulario !== null"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :ignora-obrigatorios="filtroObrigatorioSelecionado"
          :objeto="filtrosLogAcoes"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/common/functions/loading';
import { mapGetters } from 'vuex';
import { countRelatorioLogAcoes } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoLogAcoesFiltrosCampos from './RelatorioExportacaoLogAcoesFiltrosCampos';

export default {
  name: 'RelatorioLogAcoes',
  mixins: [
    RelatorioExportacaoLogAcoesFiltrosCampos,
  ],
  watch: {
    filtrosLogAcoes: {
      handler() {
        this.filtroObrigatorioSelecionado = false;
        this.filtroDataSelecionado = false;
        this.filtrosValores = 0;
        Object.entries(this.filtrosLogAcoes).forEach(([, value]) => {
          if ((`${value}`) !== 'null' && (`${value}`) !== '') {
            this.filtrosValores += 1;
          }
        });
        if (this.filtrosLogAcoes.data_inicio && this.filtrosLogAcoes.data_fim) {
          this.filtroDataSelecionado = true;
          if (this.filtrosValores >= 3) {
            this.filtroObrigatorioSelecionado = true;
            return false;
          }
        } else {
          Object.values(this.filtrosLogAcoes).every((value) => {
            if (value instanceof Array) {
              if (value && value.length) {
                this.filtroObrigatorioSelecionado = true;
                return false;
              }
            } else if (value) {
              this.filtroObrigatorioSelecionado = true;
              return false;
            }
            return true;
          });
        }
        this.$refs.form.resetValidation();
        return true;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'cod_acao',
        'cod_contrato',
        'cod_fornecedor_acao',
        'nota_debito',
        'grupo_fornecedor',
        'nome_usuario',
        'data_inicio',
        'data_fim',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosLogAcoes: {},
      filtroObrigatorioSelecionado: false,
      filtroDataSelecionado: false,
      filtrosValores: 0,
      limiteRegistrosExportacao: 50000,
      camposFormulario: null,
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido() {
      return this.filtroObrigatorioSelecionado;
    },
    filtroDataValido() {
      return this.filtroDataSelecionado;
    },
    resetaFiltros() {
      this.filtrosLogAcoes = {};
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();
      if (!this.filtroValido()) {
        if (!this.filtroDataValido()) {
          this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        } else {
          this.$toast(this.$t('message.selecione_filtros_obrigatorios'));
        }
        return;
      }
      if (!this.filtroPeriodoValido(filtrosAplicados)) {
        return;
      }
      if (!this.$refs.form.validate()) {
        return;
      }
      if (filtrosAplicados.nota_debito !== undefined
        && filtrosAplicados.nota_debito !== null
        && !filtrosAplicados.nota_debito.length) {
        delete filtrosAplicados.nota_debito;
      }
      if (filtrosAplicados.cod_fornecedor_acao === '') {
        filtrosAplicados.cod_fornecedor_acao = null;
      }

      const params = {
        ...filtrosAplicados,
      };

      let quantidadeRegistros = 0;

      countRelatorioLogAcoes(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            let nomeRelatorio = '';
            if (this.isAmbienteKabum) {
              nomeRelatorio = 'log_acoes_kabum';
            } else {
              nomeRelatorio = 'log_acoes_magalu';
            }
            this.$emit('RelatorioExportacaoLogAcoes__AplicaFiltros',
              params,
              nomeRelatorio,
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    filtroPeriodoValido(filtrosAplicados) {
      const dtInicio = this.moment(filtrosAplicados.data_inicio, 'YYYYMMDD');
      const dtFinal = this.moment(filtrosAplicados.data_fim, 'YYYY-MM-DD');
      if ((dtInicio.toString() === 'Invalid date' || dtFinal.toString() === 'Invalid date')) {
        this.$toast(this.$t('message.data_final_e_inicial'));
        return false;
      }
      if (filtrosAplicados.data_inicio && filtrosAplicados.data_fim) {
        if (dtFinal.isBefore(dtInicio)) {
          this.$toast(this.$t('message.data_final_antes_inicial'));
          return false;
        }
      }
      return true;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-log-acoes';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-log-acoes';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosLogAcoes;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  mounted() {
    if (this.isAmbienteKabum) {
      this.camposFormulario = this.camposFormularioKabum;
    } else {
      this.camposFormulario = this.camposFormularioMagalu;
    }
  },
};
</script>
<style>
  .RelatorioLogAcoesFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioLogAcoesFiltros__Opcoes div.v-input__slot,
  .RelatorioLogAcoesFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioLogAcoesFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioLogAcoesFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
