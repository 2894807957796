import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'configuracao-campos-customizados');

const camposCustomizadosActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&{&entidades*}` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  listarPorEntidadeAcao: { method: 'GET', url: `${basePath}/entidade?entidade=ACAO` },
  listarPorEntidadeContrato: { method: 'GET', url: `${basePath}/entidade?entidade=CONTRATO` },
  listarPorEntidadeAcaoTodosProcessos: { method: 'GET', url: `${basePath}/entidade/todos-processos?entidade=ACAO` },
  listarCamposADS: { method: 'GET', url: `${basePath}/campos-ads` },
};

export default (resource) => resource(`${basePath}`, {}, camposCustomizadosActions);
