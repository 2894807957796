import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'parametrizacao-contrato');

const parametrizacaoContratoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  buscar: { method: 'GET', url: `${basePath}` },
  obterPasso1: { method: 'GET', url: `${basePath}/{id}/passo1` },
  obterPasso2: { method: 'GET', url: `${basePath}/{id}/passo2` },
  obterPasso3: { method: 'GET', url: `${basePath}/{id}/passo3` },
  obterPasso4: { method: 'GET', url: `${basePath}/{id}/passo4` },
  obterPasso5: { method: 'GET', url: `${basePath}/{id}/passo5` },
  obterPasso6: { method: 'GET', url: `${basePath}/{id}/passo6` },
  buscarVinculosCarteira: { method: 'GET', url: `${basePath}/passo2/carteira` },
};

export default (resource) => resource(`${basePath}`, {}, parametrizacaoContratoActions);
