import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'listagem');

const apuracaoActions = {
  buscarApuracao: { methods: 'GET', url: `${basePath}/carregamento/{idApuracao}` },
  verificarDadosExportacao: { methods: 'GET', url: `${basePath}/{idApuracao}/verificar-dados-exportacao` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/acesso/{idApuracao}` },
  buscarMetas: { method: 'GET', url: `${basePath}/metas/{idAcao}` },
  buscarMetasProdutos: { method: 'GET', url: `${basePath}/metas-produtos` },
  buscarMetasApuracao: { method: 'GET', url: `${basePath}/metas-apuracao` },
  buscarCodigoCampanhas: { method: 'GET', url: `${basePath}/codigo_campanha` },
  buscarTodasCategorias: { method: 'GET', url: `${basePath}/categoria` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const listarApuracoesTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores', parametros).doGet();
