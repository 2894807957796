<template lang="html">
  <v-navigation-drawer
    v-model="abrirMenu"
    app
    temporary
    style="overflow: hidden"
    width="300"
    class="SideBar__Menu">

    <div class="imagem_menu">
      <span class="helper"></span>
      <img src="../../assets/img/magalu-header.png" class="logo" alt="" v-if="isTenantMagalu" >
      <img src="../../assets/img/kabum-logo.png" class="logo" alt="" v-if="isTenantKabum" >
      <img src="../../assets/img/epoca-logo.png" class="logo" alt="" v-if="isTenantEpoca" >
    </div>
    <div class="strip-magalu" v-if="isTenantMagalu">
    </div>

    <v-list style="height: 76vh; overflow-y: auto;" class="listaMenu">
      <template v-for="(item, idx) in menuList">
        <v-list-group
          v-if="item.sub"
          v-model="item.model"
          :key="idx"
          :prepend-icon="item.icon"
          no-action
        >
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title class="black-text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(child, i) in item.sub">
          <v-list-group
            v-if="child.sub && child.submenu"
            v-model="child.model"
            :key="i"
            no-action
            sub-group
          >
            <v-icon slot="prependIcon" class="mr-9 pl-1">{{ child.icon }}</v-icon>
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title class="black-text">
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-icon slot="appendIcon">expand_more</v-icon>
            <v-list-item
              v-for="(subChild, j) in child.sub"
              :key="j"
              @click="goToPage(subChild.route)"
            >
              <v-list-item-action v-if="subChild.icon" class="pl-10">
                <v-icon>{{ subChild.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="black-text">
                  {{ subChild.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="i"
            @click="goToPage(child.route)"
          >
            <v-list-item-action v-if="child.icon" class="pl-2">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="black-text">
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        </v-list-group>
        <v-list-item v-else :key="idx" @click="goToPage(item.route)">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="black-text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <v-footer class="pa-2 footer" app inset>
      <!-- Versão: 1.0.0 -->
      <span>Tradelinks</span>
    </v-footer>

  </v-navigation-drawer>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { showLoading, hideLoading } from '@/common/functions/loading';

export default {
  props: {
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
    abrir: false,
    logo: String,
  },
  data() {
    return {
      abrirMenu: false,
      rotaAtual: null,
    };
  },
  watch: {
    abrir() {
      this.abrirMenu = !this.abrirMenu;
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantMagalu() {
      return this.getTenantId === 'magalu';
    },
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
    isTenantKabum() {
      return this.getTenantId === 'kabum';
    },
  },
  methods: {
    lockBodyScroll() {
      document.body.style.overflowY = 'hidden';
    },
    onItemClicked(item) {
      if (!item.sub) {
        this.goToPage(item.route);
      }
    },
    getAbsolutePath() {
      return window.location.hash ? window.location.hash.replace('#/', '') : '';
    },
    shouldReloadRoute(route) {
      const { current } = this.$router.history;
      return current.matched.filter((m) => m.name === route).length > 0;
    },
    goToPage(route) {
      this.abrirMenu = !this.abrirMenu;

      if (this.rotaAtual === route) {
        return;
      }
      this.rotaAtual = route;

      setTimeout(() => {
        if (!this.shouldReloadRoute(route)) {
          showLoading();
          this.$router
            .push({ name: route, params: { menu: true } }, null, () => hideLoading());
        } else {
          this.$router.go();
        }
      }, 500);
    },
    toggleLockBodyScroll() {
      document.body.style.overflowY = document.body.style.overflowY === 'hidden'
        ? 'auto'
        : 'hidden';
    },
    unlockBodyScroll() {
      document.body.style.overflowY = 'auto';
    },
    toggleExpand(...args) {
      $logger.log('Args', args);
    },
    resetSidebar() {
      this.rotaAtual = null;
    },
  },
  mounted() {
  },
};
</script>

<style src="./Sidebar.scss" lang="scss"></style>
<style lang="scss">
  .imagem_menu {
    padding: 0 !important;
    height: 7em;
    text-align: center;
    vertical-align: middle;
    background-color: var(--v-primary-base);
  }
  .black-text {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .footer {
    color: var(--v-primary-base) !important;
  }
  .logo {
    width: 60%;
    padding-top: 2.5em;
  }
  .strip-magalu {
    height: 5px;
    background: -moz-linear-gradient(left,#fcd000 0,#ff8a00 17%,#ff253a 34%,#ff37a8 51%,#a400e1 67%,#0086ff 83%,#00d604 100%);
    background: -webkit-linear-gradient(left,#fcd000 0,#ff8a00 17%,#ff253a 34%,#ff37a8 51%,#a400e1 67%,#0086ff 83%,#00d604 100%);
    background: linear-gradient(to right,#fcd000 0,#ff8a00 17%,#ff253a 34%,#ff37a8 51%,#a400e1 67%,#0086ff 83%,#00d604 100%);
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
  }
  .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .listaMenu .v-list__group__header__prepend-icon {
    justify-content: center;
  }
  .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>div>.v-list-item {
    padding-left: 3em;
  }
  .SideBar__Menu .v-list-group .v-list-group__header .v-list-item {
    padding-left: 0px;
  }
</style>
