export default (context) => [{
  text: context.$tc('label.administracao', 1),
  icon: 'track_changes',
  sub: [
    {
      text: context.$tc('label.estrutura_usuario', 1), icon: 'recent_actors', route: 'estrutura-usuario', role: 'ESTRUTURA', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.divisao', 1), icon: 'line_weight', route: 'divisao', role: 'DIVISAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.perfil', 1), icon: 'group', route: 'sso-grupo', role: 'PERFIL', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.gestao_usuario', 1),
      icon: 'manage_accounts',
      submenu: true,
      sub: [
        {
          text: context.$tc('label.funcionario', 1),
          icon: 'person',
          route: 'usuario-funcionario',
          role: ['USR_CADASTRAR_GESTAO_DE_FUNCIONARIO', 'USR_CONSULTAR_GESTAO_DE_FUNCIONARIO'],
          tenants: ['MAGALU', 'EPOCA', 'KABUM'],
        },
        {
          text: context.$tc('label.fornecedor', 1),
          icon: 'account_box',
          route: 'usuario-fornecedor',
          role: ['USR_CADASTRAR_GESTAO_DE_FORNECEDOR_MASTER', 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_REPRESENTANTE_LEGAL', 'USR_CONSULTAR_GESTAO_DE_FORNECEDOR'],
          tenants: ['MAGALU', 'EPOCA', 'KABUM'],
        },
      ],
      route: '',
      role: ['USR_CADASTRAR_GESTAO_DE_FUNCIONARIO', 'USR_CONSULTAR_GESTAO_DE_FUNCIONARIO', 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_MASTER', 'USR_CADASTRAR_GESTAO_DE_FORNECEDOR_REPRESENTANTE_LEGAL', 'USR_CONSULTAR_GESTAO_DE_FORNECEDOR'],
      tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$t('label.regiao'), icon: 'location_on', route: 'regiao', role: 'REGIAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.filial', 1), icon: 'store', route: 'filial', role: ['FILIAL_PAGINA', 'FILIAL_CRUD'], tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$t('label.workflow'), icon: 'group_work', route: 'workflow', role: 'FLUXO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.calendario', 1), icon: 'calendar_today', route: 'calendario', role: ['CAL_PAGINA', 'CAL_CRUD'], tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.termo_aceite', 1), icon: 'description', route: 'termo-aceite', role: 'TERMO_ACEITE', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.tipo_uso', 1), icon: 'chrome_reader_mode', route: 'tipo-uso', role: 'TIPO_USO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.alerta', 2), icon: 'warning', route: 'alerta-manutencao', role: 'ALERTA_MANUTENCAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
  ],
},
{
  text: context.$tc('label.cadastro', 2),
  icon: 'input',
  sub: [
    {
      text: context.$tc('label.unidade_medida', 1), icon: 'aspect_ratio', route: 'unidade-medida', role: 'UNID_MEDIDA', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.produto', 1), icon: 'shopping_basket', route: 'produto', role: 'PRD', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$t('label.grupo_fornecedor'), icon: 'home_work', route: 'nivel-fornecedor', role: 'NIVEL_FORNECEDOR', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('title.fornecedor', 1), icon: 'fa-industry', route: 'fornecedor', role: ['FORN_PAGINA', 'FORN_CRUD'], tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.nota', 2), icon: 'fa-file-invoice-dollar', route: 'notas', role: 'NOTA', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
  ],
},
{
  text: context.$tc('label.acao_tatica', 1),
  icon: 'content_paste',
  sub: [
    {
      text: context.$tc('label.planejamento', 1), icon: 'storage', route: 'planejamento-acao', role: ['ACAO_PAGINA', 'ACAO_CRUD'], tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.apuracao', 1), icon: 'playlist_add_check', route: 'apuracao-acao', role: 'APU_ACAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.recebimento', 1), icon: 'payment', route: 'recebimento', role: 'REC_ACAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
  ],
},
{
  text: context.$tc('label.contrato', 1),
  icon: 'fa-file-contract',
  sub: [
    {
      text: context.$tc('label.planejamento', 1), icon: 'storage', route: 'planejamento-contrato', role: 'CONTRATO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.apuracao_contrato', 1), icon: 'playlist_add_check', route: 'apuracao-contrato', role: 'APU_CONTR', tenants: ['MAGALU', 'KABUM'],
    },
    {
      text: context.$tc('label.apuracao_contrato_samsung', 1), icon: 'playlist_add_check', route: 'apuracao-contrato-samsung', role: 'APU_CONTR', tenants: ['MAGALU', 'KABUM'],
    },
    {
      text: context.$tc('label.apuracao_contrato', 1), icon: 'playlist_add_check', route: 'apuracao-contrato-epoca', role: 'APU_CONTR', tenants: ['EPOCA'],
    },
  ],
},
{
  text: context.$tc('label.nota_debito', 1),
  icon: 'receipt',
  sub: [
    {
      text: context.$tc('label.contabilizacao', 1), icon: 'fa-calendar-check', route: 'notas-debito-contabilizacao', role: 'LIQ_DTA_CONT', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.consulta', 1), icon: 'assignment', route: 'notas-debito-consulta', role: 'LIQ_ND_PAGINA', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
  ],
},
{
  text: context.$tc('title.relatorio', 2),
  icon: 'list_alt',
  route: 'reports',
  role: ['REL_PRODUTO_EXPORTACAO', 'REL_PRODUTO_REORG', 'REL_COMPARATIVO_NOTAS', 'REL_LOG_ACOES', 'REL_LOG_FORNECEDOR', 'REL_LOG_PRODUTO',
    'REL_LOG_USUARIO', 'REL_NOTAS_SELLIN', 'REL_DETALHAMENTO_ND', 'REL_USUARIO', 'REL_DETALHAMENTO_CONTRATO', 'REL_DADOS_CAMPANHA',
    'REL_NDS_NAO_PROCESSADAS', 'REL_DETALHAMENTO_RETROATIVO', 'REL_HISTORICO_JOBS', 'REL_STATUS_ACAO', 'REL_ND_MASSIVA_EPOCA',
    'REL_ACOMPANHAMENTO_CAMPANHAS_ADS'],
  tenants: ['MAGALU', 'EPOCA', 'KABUM'],
},
{
  text: context.$tc('label.parametrizacao', 1),
  icon: 'settings',
  sub: [
    {
      text: context.$tc('label.campo_customizado', 2), icon: 'text_fields', route: 'campos-customizados', role: 'CONFIG_CAMPOS_CUSTOMIZADOS', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$t('label.nivel_produto'), icon: 'line_style', route: 'nivel-produto', role: 'NIVEL_PRD', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.acao', 1), icon: 'content_paste', route: 'parametrizacao-acao', role: 'PARAM_ACAO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.contrato', 1), icon: 'fa-file-contract', route: 'parametrizacao-contrato', role: 'PARAM_CONTRATO', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
    {
      text: context.$tc('label.configuracao', 2), icon: 'build', route: 'configuracao-sistema', role: 'CONFIG_SISTEMA', tenants: ['MAGALU', 'EPOCA', 'KABUM'],
    },
  ],
},
{
  text: context.$tc('label.suporte', 1),
  icon: 'build',
  route: 'administracao',
  role: 'ADMINISTRACAO',
  tenants: ['MAGALU', 'EPOCA', 'KABUM'],
}];
