<template>
  <v-card style="margin-bottom:1em" class="RelatorioBaseContratosFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.detalhamento_contrato', 1) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          v-if="camposFormulario !== null"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-obrigatorios="umFiltroObrigatorioSelecionado"
          :objeto="filtrosBaseContratos"
          ref="container">
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-menu
              v-model="menu"
              ref="menu"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-combobox
                  v-model="anosSelecionados"
                  :rules="[rules.required]"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  :label="$t('label.ano')"
                  prepend-icon="event"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="anosSelecionados"
                :active-picker.sync="activePicker"
                reactive
                ref="pickerYear"
                locale="pt-br"
                color="primary"
                :min="minYearFilter"
                :max="maxYearFilter"
                type="year"
                multiple
                @click:year="alteraFiltroAno($event)">
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>

import { mapGetters } from 'vuex';
import { skipLoading } from '@/common/functions/loading';
import { countRelatorioBaseContratos } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import RelatorioBaseContratosFiltrosCampos from './RelatorioBaseContratosFiltrosCampos';

export default {
  name: 'RelatorioBaseContratos',
  mixins: [
    RelatorioBaseContratosFiltrosCampos,
  ],
  watch: {
    menu(val) {
      if (val && this.activePicker !== 'YEAR') {
        setTimeout(this.activePicker = 'YEAR');
      }
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'grupo_fornecedor',
        'status',
        'descricao_passo',
        'tipo_acao',
        'categoria',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosBaseContratos: {},
      umFiltroObrigatorioSelecionado: true,
      limiteRegistrosExportacao: 50000,
      anosSelecionados: [this.moment().format('YYYY')],
      activePicker: 'YEAR',
      minYearFilter: this.moment().subtract(10, 'y').format('YYYY'),
      maxYearFilter: this.moment().add(5, 'y').format('YYYY'),
      rules: {
        required: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
      camposFormulario: null,
      menu: false,
    };
  },
  methods: {
    alteraMaxMinYearDatePicker() {
      const maxSelecionado = Math.max.apply(null, this.anosSelecionados);
      const minSelecionado = Math.min.apply(null, this.anosSelecionados);
      this.maxYearFilter = (maxSelecionado + 5).toString();
      this.minYearFilter = (minSelecionado - 10).toString();
    },
    alteraFiltroAno(val) {
      const anoSelecionado = val.toString();
      if (!this.anosSelecionados.includes(anoSelecionado)) {
        this.anosSelecionados.push(anoSelecionado);
        this.alteraMaxMinYearDatePicker();
      }
      setTimeout(this.activePicker = 'YEAR');
      setTimeout(this.$refs.pickerYear.activePicker = 'YEAR');
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido() {
      // valida se algum filtro foi selecionado
      return this.umFiltroObrigatorioSelecionado;
    },
    resetaFiltros() {
      this.filtrosBaseContratos = {};
    },
    aplicarFiltros() {
      const filtrosAplicados = this.$refs.container.getValoresCamposPadrao();
      filtrosAplicados.ano = this.anosSelecionados;
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroValido()) {
        this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        return;
      }
      if (filtrosAplicados.origem !== null && typeof filtrosAplicados.origem !== 'undefined') {
        if (filtrosAplicados.origem === 'CONTRATO') {
          filtrosAplicados.contrato = true;
        } else {
          filtrosAplicados.acao = true;
        }
      }
      const params = {
        ...filtrosAplicados,
      };

      let quantidadeRegistros = 0;

      countRelatorioBaseContratos(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            let nomeRelatorio = '';
            if (this.isAmbienteKabum) {
              nomeRelatorio = 'base_contratos_magalu';
            } else if (this.isAmbienteEpoca) {
              nomeRelatorio = 'base_contratos_epoca';
            } else {
              nomeRelatorio = 'base_contratos_magalu';
            }
            this.$emit('RelatorioExportacaoBaseContratos__AplicaFiltros',
              params,
              nomeRelatorio,
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-base-contratos';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-base-contratos';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosBaseContratos;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  mounted() {
    if (this.isAmbienteKabum) {
      this.camposFormulario = this.camposFormularioMagalu;
    } else if (this.isAmbienteEpoca) {
      this.camposFormulario = this.camposFormularioEpoca;
    } else {
      this.camposFormulario = this.camposFormularioMagalu;
    }
  },
};
</script>
<style>
  .RelatorioBaseContratos__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioBaseContratos__Opcoes div.v-input__slot,
  .RelatorioBaseContratos__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioBaseContratos__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioBaseContratos__Opcoes label {
    font-size: 14px;
  }
</style>
