import Vue from 'vue';
import Vuex from 'vuex';
import anoFiscal from './modules/ano-fiscal';
import importacao from './modules/importacao';
import controlador from './modules/controlador';
import metadados from './modules/metadados';
import roles from './modules/roles';
import usuario from './modules/usuario';
import toast from './modules/toast';
import parametros from './modules/parametros';
import implantacao from './modules/implantacao';
import filtros from './modules/filtros';
import acaoSaldoContaCorrente from './modules/acao-saldo-conta-corrente';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    anoFiscal,
    importacao,
    controlador,
    metadados,
    roles,
    usuario,
    parametros,
    implantacao,
    filtros,
    acaoSaldoContaCorrente,
    toast,
  },
  strict: true,
});
