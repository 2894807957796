<template>
  <div class="" ref="entrypoint">
    <form :action="url" name="dropzone" class="dropzone" :id="id" :numMaxArquivos="numMaxArquivos"></form>
  </div>
</template>

<script>
import Dropzone from 'dropzone';
import { auth } from '@/config/auth';

Dropzone.autoDiscover = false;

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    numMaxArquivos: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    acceptedFileTypes: {
      type: String,
    },
    thumbnailHeight: {
      type: Number,
      default: 200,
    },
    thumbnailWidth: {
      type: Number,
      default: 200,
    },
    showRemoveLink: {
      type: Boolean,
      default: true,
    },
    maxFileSizeInMB: {
      type: Number,
      default: 500,
    },
    maxNumberOfFiles: {
      type: Number,
      default: 1,
    },
    autoProcessQueue: {
      type: Boolean,
      default: true,
    },
    dropzoneOptions: {
      type: Object,
      default() { return null; },
    },
    title: {
      type: String,
      default() { return 'Arraste o seu arquivo aqui'; },
    },
  },
  data() {
    return {
      dropzone: null,
    };
  },
  computed: {
    addIcon() {
      return '<i class="material-icons">add</i>';
    },
    doneIcon() {
      return '<i class="material-icons">done</i>';
    },
    errorIcon() {
      return '<i class="material-icons">error</i>';
    },
  },
  watch: {
    url() {
      this.recreateDropzone();
    },
    id() {
      this.recreateDropzone();
    },
    numMaxArquivos() {
      this.removeAllFiles();
    },
  },
  methods: {
    removeAllFiles() {
      this.dropzone.removeAllFiles(true);
    },
    processQueue() {
      this.dropzone.processQueue();
    },
    recreateDropzone() {
      this.dropzone.destroy();
      this.dropzone = null;
      document.querySelector(`#${this.id}`).innerHTML = '';
      this.initDropzone();
    },
    createDropzoneElement() {
      let element = document.getElementById(this.id);
      if (!element) {
        element = this.$refs.entrypoint.querySelector('form');
      }
      return element;
    },
    createDropzone() {
      const element = this.createDropzoneElement();
      this.dropzone = (!this.dropzoneOptions)
        ? new Dropzone(element, {
          addRemoveLinks: this.showRemoveLink,
          acceptedFiles: this.acceptedFileTypes,
          autoProcessQueue: this.autoProcessQueue,
          clickable: this.clickable,
          dictCancelUpload: 'Cancelar',
          dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o envio?',
          dictDefaultMessage: `${this.title}<br>${this.addIcon}`,
          dictFallbackMessage: 'O seu browser não suporta esta feature. Use o Google Chrome.',
          // dictFallbackText: '',
          dictFileTooBig: `O arquivo é muito grande! O máximo suportado é ${this.maxFileSizeInMB}MB`,
          dictInvalidFileType: `Este arquivo não é suportado. Os tipos aceitos são: ${this.acceptedFileTypes}`,
          dictMaxFilesExceeded: `O número máximo de arquivos para envio é: ${this.maxNumberOfFiles}.`,
          dictResponseError: 'Erro no nosso servidor. Reenvie o arquivo.',
          dictRemoveFile: 'Remover arquivo',
          maxFiles: this.maxNumberOfFiles,
          maxFilesize: this.maxFileSizeInMB,
          accept: (file, done) => {
            if (auth.isTokenExpired()) {
              auth.refreshToken().then(() => done());
            } else {
              done();
            }
          },
          previewTemplate: `
              <div class="dz-preview dz-file-preview">
                <div class="dz-image" style="width: ${this.thumbnailWidth}px; height:${this.thumbnailHeight}px">
                  <img data-dz-thumbnail />
                </div>
                <div class="dz-details">
                  <div class="dz-size">
                    <span data-dz-size></span>
                  </div>
                  <div class="dz-filename">
                    <span data-dz-name></span>
                  </div>
                </div>
                <div class="dz-progress">
                  <span class="dz-upload" data-dz-uploadprogress></span>
                </div>
                <div class="dz-error-message">
                  <span data-dz-errormessage></span>
                </div>
                <div class="dz-success-mark">${this.doneIcon}</div>
                <div class="dz-error-mark">${this.errorIcon}</div>
              </div>`,
          thumbnailWidth: this.thumbnailWidth,
          thumbnailHeight: this.thumbnailHeight,
        })
        : new Dropzone(element, this.dropzoneOptions);
    },
    initDropzone() {
      this.createDropzone();
      this.registerDropzoneEvents();
    },
    removeFile(file) {
      this.dropzone.removeFile(file);
    },
    registerDropzoneEvents() {
      this.dropzone.on('addedfile', (file) => {
        this.$emit('UPLOADFILE_FILE_ADDED', file);
      });

      this.dropzone.on('maxfilesexceeded', (file) => {
        this.dropzone.removeFile(file);
      });

      this.dropzone.on('removedfile', (file) => {
        this.$emit('UPLOADFILE_FILE_REMOVED', file);
      });

      this.dropzone.on('success', (file, response) => {
        this.$emit('UPLOADFILE_SUCCESS', file, response);
        setTimeout(() => {
          this.dropzone.removeFile(file);
        }, 3E3);
      });

      this.dropzone.on('error', (file, error, xhr) => {
        this.$emit('UPLOADFILE_ERROR', file, error, xhr);
      });

      this.dropzone.on('sending', (file, xhr, formData) => {
        if (sessionStorage && sessionStorage.token) {
          const token = this.$auth.token || sessionStorage.token;
          xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          xhr.setRequestHeader('x-tenant-id', window.localStorage.getItem('xTenantId'));
        }
        const fn = encodeURI(file.name);
        formData.append('filename', fn);
        this.$emit('UPLOADFILE_SENDING', file, xhr, formData);
      });
    },
    startComponent() {
      this.initDropzone();
    },
  },
  mounted() {
    this.startComponent();
  },
};
</script>

<style src="./Dropzone.scss" lang="scss"></style>
