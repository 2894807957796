import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('recebimento', 'listagem');

const recebimentoActions = {
  buscarRecebimento: { methods: 'GET', url: `${basePath}/carregamento/{idRecebimento}` },
};

export default (resource) => resource(`${basePath}`, {}, recebimentoActions);

export const listarRecebimentosTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores', parametros).doGet();

export const listarRecebimentosPorFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-fornecedor', parametros).doGet();

export const listarRecebimentosNivel2 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2', parametros).doGet();

export const listarRecebimentosNivel2Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2/simplificado', parametros).doGet();

export const listarNotasDebito = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nota-debito', parametros).doGet();

export const listarRankingRecebimentosPorGrupoFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ranking-por-fornecedor', parametros).doGet();

export const listarRankingRecebimentosPorSetor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ranking-por-setor', parametros).doGet();

export const buscarGraficoDashInicial = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'grafico', parametros).doGet();
