import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'notas');
const jobBasePath = getBasePath('job', 'notas');

const notasActions = {
  listarNotasAgrupadasPorTipo: { methods: 'GET', url: `${basePath}/por-tipo` },
  listarNotasSellOutConsolidado: { methods: 'GET', url: `${basePath}/sell-out/consolidado?tamanhoPagina={size}&numeroPagina={page}` },
  listarNotasSellOutDetalhado: { methods: 'GET', url: `${basePath}/sell-out/detalhado?tamanhoPagina={size}&numeroPagina={page}` },
  verificarDadosExportacao: { methods: 'GET', url: `${jobBasePath}/verificar-dados-exportacao?{&meses*}` },
};

export default (resource) => resource(`${basePath}`, {}, notasActions);
