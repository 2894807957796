import getBasePath from '@/common/functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('api', 'produto');

const produtoActions = {
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  selecao: { method: 'GET', url: `${basePath}/selecao{?exceto*&contendo*}` },
  ativar: { method: 'PUT', url: `${basePath}/cadastro/ativacao/{id}/true` },
  atualizar: { method: 'PUT', url: `${basePath}/cadastro` },
  ativarMassa: { method: 'PUT', url: `${basePath}/cadastro/ativacao-massa` },
  gravar: { method: 'POST', url: `${basePath}/cadastro` },
};

export default (resource) => resource(`${basePath}`, {}, produtoActions);

export const buscarProdutosSelecao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'selecao', parametros).doGet();
