import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'alerta-manutencao');

const alertaManutencaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}&filtro={filter}` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  listarAlertasParaExibir: { method: 'GET', url: `${basePath}/alertas-para-exibir` },
  salvaUsuarioLogAlertaManutencao: { method: 'POST', url: `${basePath}/salvar-usuario-log-alerta` },
};

export default (resource) => resource(`${basePath}`, {}, alertaManutencaoActions);
