<template>
  <div>
    <v-dialog v-model="dialogForm" persistent max-width="1200">
      <calendario-form
          v-if="dialogForm"
          :id-evento="idEvento"
          :somente-leitura="!canAccessCRUD"
          @cancelar="fecharForm"
          @atualizarGrid="buscar"></calendario-form>
    </v-dialog>
    <calendario-tabela
      @edit="edit"
      ref="tabela"
      :somente-leitura="!canAccessCRUD">
    </calendario-tabela>
    <v-btn v-if="canAccessCRUD" @click="abrirForm" fixed fab bottom right color="primary" class="Calendario__btn--floating__heigth">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { generateComputed } from '@/common/functions/roles-computed-generator';
import CalendarioTabela from './CalendarioTabela';
import CalendarioForm from './CalendarioForm';
import CalendarioToolbar from './CalendarioToolbar';

export default {
  name: 'Calendario',
  data() {
    return {
      dialogForm: false,
      idEvento: null,
    };
  },
  components: {
    CalendarioForm,
    CalendarioTabela,
    CalendarioToolbar,
  },
  computed: {
    ...generateComputed('Cal', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
  },
  methods: {
    edit(id) {
      this.idEvento = id;
      this.abrirForm();
    },
    buscar() {
      this.fecharForm();
      this.$refs.tabela.filtrar();
    },
    abrirForm() {
      this.dialogForm = true;
    },
    fecharForm() {
      this.idEvento = null;
      this.dialogForm = false;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.$router.push({ name: 'inicio' });
      }, 1E3);
    }
  },
};
</script>

<style>
  .Calendario__btn--floating__heigth > div {
    height: auto !important;
  }
</style>
