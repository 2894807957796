import EstruturaUsuario from '@/spa/estrutura-usuario/EstruturaUsuario';

const EstruturaUsuarioForm = () => import('@/spa/estrutura-usuario/EstruturaUsuarioForm');
const EstruturaUsuarioList = () => import('@/spa/estrutura-usuario/EstruturaUsuarioList');

export default {
  path: 'estrutura-usuario',
  component: EstruturaUsuario,
  children: [
    {
      path: '',
      name: 'estrutura-usuario',
      component: EstruturaUsuarioList,
    },
    {
      path: ':id/editar',
      name: 'editarEstruturaUsuario',
      component: EstruturaUsuarioForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: 'novo',
      name: 'novoEstruturaUsuario',
      component: EstruturaUsuarioForm,
    },
    {
      path: ':id',
      name: 'verEstruturaUsuario',
      component: EstruturaUsuarioForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
