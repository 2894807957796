import getBasePath from '@/common/functions/api-resource';
import resourceBuilder from '@/common/functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/redirecionamento-passo-acao');

const workflowAcaoActions = {
  buscarPassosDescricao: { method: 'GET', url: `${basePath}/fluxo/passos/buscar/descricao` },
  buscarPassosDescricaoContrato: { method: 'GET', url: `${basePath}/fluxo/passos/buscar/descricao/contrato` },
  buscarPassosDescricaoApuracao: { method: 'GET', url: `${basePath}/fluxo/passos/buscar/descricao/apuracao` },
  salvarAcaoTipoUso: { method: 'PUT', url: `${basePath}/fluxo/salvar-acao-tipo-uso/{idAcao}` },
  salvarAcaoDataContabilizacao: { method: 'PUT', url: `${basePath}/fluxo/salvar-acao-data-contabilizacao/{idAcao}` },
  buscarFluxoAcao: { method: 'GET', url: `${basePath}/fluxo/buscar` },
  carregarTipoUso: { method: 'GET', url: `${basePath}/fluxo/carregar-tipo-uso/{idAcao}` },
  buscarTiposUso: { method: 'GET', url: `${basePath}/fluxo/buscar-tipos-uso` },
  verificarSeUsuarioAprovadorNDComplementoInformacoes: { method: 'GET', url: `${basePath}/fluxo/usuario-informa-data-contabilizacao` },
  verificarSeUsuarioAprovadorNFComplementoInformacoes: { method: 'GET', url: `${basePath}/fluxo/usuario-informa-data-contabilizacao-nf` },
  verificarSePossuiPassoFornecedor: { method: 'GET', url: `${basePath}/fluxo/possui-passo-fornecedor/{idAcao}` },
};

export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const iniciaFluxo = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'inicia-fluxo', parametros).doPost(parametros);
