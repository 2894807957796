<template>
  <div style="min-height: 350px;">
    <grafico-barras
      category-field="anoMes"
      :value-fields="valueFields"
      :dados="dados"
      v-if="dados"
      :height="412"
      :unit-y-axis="`${$t('label.unidade_dinheiro')} `"/>
  </div>
</template>

<script>
import GraficoBarras from '@/spa/dashboard/GraficoBarras';
import { buscarGraficoDashInicial } from '@/common/resources/recebimento';

export default {
  name: 'dash-inicial-grafico',
  components: {
    GraficoBarras,
  },
  props: {
    filtros: Object,
  },
  data() {
    return {
      recebimentoResource: this.$api.recebimento(this.$resource),

      valueFields: [
        {
          balloonText: `${this.$tc('label.contrato', 1)}: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$tc('label.contrato', 1),
          valueField: 'recebimentoContrato',
          type: 'column',
          valueAxis: 'v1',
        },
        {
          balloonText: `${this.$t('label.acao_avulsa')}: ${this.$t('label.unidade_dinheiro')} [[value]]`,
          title: this.$t('label.acao_avulsa'),
          valueField: 'recebimentoAcaoAvulsa',
          type: 'column',
          valueAxis: 'v2',
        },
      ],
      dados: null,
      filtrosAplicados: this.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosAplicados = this.filtros;
        this.dados = null;
        this.buscarDados();
      },
    },
  },
  methods: {
    buscarDados() {
      const params = {
        ...this.filtrosAplicados,
      };

      buscarGraficoDashInicial(params, this.$resource)
        .then((res) => {
          this.dados = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.filtros && this.filtros.data_inicio && this.filtros.data_fim) {
      this.buscarDados();
    }
  },
};
</script>
