<template lang="html">
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header mt-4">
        {{ $t('label.vigencia') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.vigencia')}`" />
      </v-card-title>
      <v-container fluid class="pl-0">
        <v-row class="mx-4">
          <v-col class="py-0" cols="12" sm="12">
            <v-switch
              dense
              id="config_acao_vigencia_automatica"
              :label="$tc('label.preenchimento_automatico', 1)"
              v-model="parametrizacao.indVigenciaAutomatica"
            ></v-switch>
          </v-col>
          <v-col class="py-0" cols="12" sm="12">
            <v-switch
              dense
              id="config_acao_vigencia_trimestral"
              :label="$tc('label.trimestral_ano_calendario', 1)"
              v-model="parametrizacao.indVigenciaTrimestral"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <v-card-title primary-title class="card-header mt-4">
        {{ $tc('title.visibilidade_configuracao_campos', 1) }}
      </v-card-title>
      <v-data-table align-center id="config_acao_tabela_campos"
        :headers="headersCamposFluxos"
        :items="camposConfiguracao"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('message.nenhum_registro')">
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="`${index}_${item.label}`">
              <td>
                <span>{{ item.nome }}</span>
                <tooltip-ajuda
                  v-if="item.label === 'divisao'"
                  color="accent"
                  :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_divisao')}`" />
                <tooltip-ajuda
                  v-else-if="item.label === 'regional'"
                  color="accent"
                  :mensagem="`${$t('message.ajuda.parametrizacao_acao.campo_regional')}`" />
              </td>
              <td v-for="fluxo in fluxos" :key="`${fluxo}_${index}`" style="height: '2em'">
                <div v-if="fluxo === 'PLANEJAMENTO' && recarregarPlanejamento">
                  <span v-if="item[fluxo]">{{$tc('label.sim', 1)}}</span>
                  <span v-else>{{$tc('label.nao', 1)}}</span>
                </div>
                <div v-else>
                  <span v-if="item.isCampoPadraoLiquidacao && fluxo !== 'LIQUIDACAO'">{{$tc('label.nao', 1)}}</span>
                  <v-switch
                    v-model="item[fluxo]"
                    @change="alteracaoFluxos(index)"
                    v-else-if="campoDinamicoPlanejamentoEditavel(fluxo, item) &&
                    campoProdutoPlanejamentoEditavel(fluxo, item) &&
                    campoPlanejamentoEditavel(fluxo, item) &&
                    campoCarteiraClienteEditavel(fluxo, item) &&
                    campoVinculoEditavel(fluxo, item)"/>
                  <span v-else>{{$tc('label.sim', 1)}}</span>
                </div>
              </td>
              <td>
                <v-switch
                  v-if="campoHabilitadoEdicaoAprovado(item.label)"
                  v-model="item.edicaoAprovado" />
                <span v-else>{{$tc('label.nao', 1)}}</span>
              </td>
              <td>
                <span v-if="!campoHabilitadoComplementoInformacao(item.label)">{{$tc('label.nao', 1)}}</span>
                <coluna-complemento-informacoes
                  :ref="`complementoInformacoes_${index}`"
                  v-else
                  :planejamento="item.PLANEJAMENTO"
                  :apuracao="item.APURACAO"
                  :liquidacao="item.LIQUIDACAO"
                  :etapasFluxo="item.etapasFluxo"
                  :index="index"
                  @ALTERACAO_ETAPAS_FLUXO="alteracaoEtapasFluxo"
                  :indHabilitaAprovacaoApuracao="item.indHabilitaAprovacaoApuracao"
                  />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <div>
        <v-card-title primary-title class="card-header mt-4">
          {{ $tc('title.fluxo_aprovacao', 1) }}
          <v-spacer />
          <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.fluxo_aprovacao')}`" />
        </v-card-title>
        <v-data-table align-center id="config_acao_tabela_fluxo_aprovacao"
          :headers="headersFluxoAprovacao"
          :items="parametrizacao.fluxoAprovacao"
          hide-default-footer
          disable-pagination
          :items-per-page="-1"
          :no-data-text="$t('message.nenhum_registro')">
          <template v-slot:item.fluxoAprovacao="{ item }">
            <span> {{ item.fluxoAprovacao}} </span>
          </template>
          <template v-slot:item.indHabilitaFluxo="{ item }">
            <v-switch
              @change="tipoDeFluxoAlterado(item)"
              v-model="item.indHabilitaFluxo" />
          </template>
          <template v-slot:item.idEstruturaUsuario="{ item }">
            <div v-if="isFluxoApuracao(item) || isFluxoPlanejamento(item)">
              <v-autocomplete
                v-if="item.indHabilitaFluxo"
                id="config_acao_fluxo_estrutura"
                class="config_acao_fluxo_estrutura"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :label="!item.indHabilitaFluxo ? `${$tc('label.estrutura_usuario', 1)} *` : `${$tc('label.estrutura_usuario', 1)}`"
                v-model="item.estruturasUsuario"
                :items="listaEstruturaUsuario"
                :disabled="true"
                item-text="nomEstruturaUsuario"
                item-value="id"
                return-object
                @click:append="triggerEstruturaUsuario"
                multiple
                chips
                deletable-chips>
              </v-autocomplete>
              <v-autocomplete
                  v-else
                  id="config_acao_fluxo_estrutura"
                  class="config_acao_fluxo_estrutura"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :label="!item.indHabilitaFluxo ? `${$tc('label.estrutura_usuario', 1)} *` : `${$tc('label.estrutura_usuario', 1)}`"
                  v-model="item.estruturasUsuario"
                  :items="listaEstruturaUsuario"
                  :disabled="false"
                  item-text="nomEstruturaUsuario"
                  item-value="id"
                  return-object
                  :rules="[rules.requiredLista]"
                  @click:append="triggerEstruturaUsuario"
                  multiple
                  chips
                  deletable-chips>
              </v-autocomplete>
            </div>
          </template>
          <template v-slot:item.indJustificativaAnalise="{ item }">
            <v-switch
              :disabled="!item.indHabilitaFluxo"
              v-model="item.indJustificativaAnalise" />
          </template>
          <template v-slot:item.indHabilitaEdicaoAprovacao="{ item }">
            <v-switch
              :disabled="!item.indHabilitaFluxo"
              v-model="item.indHabilitaEdicaoAprovacao" />
          </template>
        </v-data-table>
      </div>
      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo4_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo4_botao_voltar" @click="voltarPasso" class="mr-3">{{ $t('label.voltar_passo') }}</v-btn>
        <v-btn color="primary" id="config_acao_passo4_botao_proximo" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo4 from './Passo4';
import TooltipAjuda from '../TooltipAjuda';
import ColunaComplementoInformacoes from './ParametrizacaoAcaoPasso4ColunaComplementoInformacoes';

export default {
  name: 'ParametrizacaoAcaoPasso4',
  props: {
    id: Number,
  },
  components: {
    TooltipAjuda,
    ColunaComplementoInformacoes,
  },
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),

      passo: 4,
      headers: [
        {
          text: this.$tc('label.tipo_orcamento', 1), value: 'tipoVerba', sortable: false, width: '40%',
        },
        {
          text: this.$tc('label.vinculo', 1), value: 'vinculosCampos', sortable: false, width: '30%',
        },
      ],
      headersFluxoAprovacao: [
        {
          text: this.$tc('label.fluxo_aprovacao', 1), value: 'fluxoAprovacao', sortable: false, width: '22%',
        },
        {
          text: this.$tc('label.habilitar_acao_fluxo', 1), value: 'indHabilitaFluxo', sortable: false, width: '12%',
        },
        {
          text: this.$tc('label.responsavel_conclusao_etapa', 1), value: 'idEstruturaUsuario', sortable: false, width: '22%',
        },
        {
          text: this.$tc('label.justificativa_analise', 1), value: 'indJustificativaAnalise', sortable: false, width: '22%',
        },
        {
          text: this.$tc('label.habilitar_complemento_informacao', 1), value: 'indHabilitaEdicaoAprovacao', sortable: false, width: '22%',
        },
      ],
      headersCampos: [
        {
          text: this.$tc('label.campo', 1), value: 'nome', width: '20%', sortable: false,
        },
      ],
      headerEdicaoAprovado: {
        text: this.$tc('label.edicao_status_aprovado', 1),
        sortable: false,
        value: 'edicao_status_aprovado',
      },
      headerComplementoInformacoes: {
        text: this.$tc('label.complemento_informacao', 2),
        sortable: false,
        value: 'complemento_informacao',
      },
      parametrizacao: new Passo4(),
      rules: {
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
      },
      listaBanco: [],
      listaEstruturaUsuario: [],

      recarregarPlanejamento: false,
      fluxos: [],
      camposConfiguracao: [],
      headersCamposFluxos: [],
      labelsCamposDinamicos: [],
      fluxoAprovacao: [],
      fluxoAprovacaoBackup: [],
      camposVinculo: {},
      tokenEtapaParalela: '_E_',
    };
  },
  computed: {
    isFluxoApuracaoHabilitado() {
      return this.parametrizacao.fluxoAprovacao.filter((f) => f.tipoFluxo === 'APURACAO' && f.indHabilitaFluxo).length > 0;
    },
  },
  methods: {
    isFluxoApuracao(fluxo) {
      return fluxo.tipoFluxo === 'APURACAO';
    },
    isFluxoPlanejamento(fluxo) {
      return fluxo.tipoFluxo === 'PLANEJAMENTO';
    },
    alteracaoEtapasFluxo(index, etapasFluxo) {
      this.camposConfiguracao[index].etapasFluxo = etapasFluxo;
    },
    alteracaoFluxos(index) {
      const ref = `complementoInformacoes_${index}`;
      if (this.$refs[ref]) {
        const planejamento = this.camposConfiguracao[index].PLANEJAMENTO;
        const apuracao = this.camposConfiguracao[index].APURACAO;
        const liquidacao = this.camposConfiguracao[index].LIQUIDACAO;
        this.$refs[ref][0].setListaEtapaFluxo(planejamento, apuracao, liquidacao);
      }
    },
    tipoDeFluxoAlterado(item) {
      (this.parametrizacao.fluxoAprovacao || []).forEach((el) => {
        if (el.id === item.id) {
          if (item.indHabilitaFluxo) {
            el.estruturasUsuario = [];
          } else {
            el.indJustificativaAnalise = false;
            el.indHabilitaEdicaoAprovacao = false;
          }
        }
      });
    },
    habilitarVinculoPlanejamento(vinculo) {
      const vinculos = [];
      if (vinculo.vinculosCampos && vinculo.vinculosCampos.length) {
        vinculo.vinculosCampos.forEach((campo) => {
          vinculos.push(campo.label);
        });
      }
      if (!this.camposVinculo[vinculo.idTipoVerba]) {
        this.camposVinculo[vinculo.idTipoVerba] = [];
      }
      this.camposVinculo[vinculo.idTipoVerba] = vinculos;
      this.preencherVisibilidadeVinculos();
    },
    preencherVisibilidadeVinculos() {
      let campos = [];
      Object.keys(this.camposVinculo).forEach((idTipoVerba) => {
        campos = [...campos, ...this.camposVinculo[idTipoVerba]];
      });
      for (let i = 0; i < this.camposConfiguracao.length; i += 1) {
        const campo = this.camposConfiguracao[i];
        if (campos.indexOf(campo.label) >= 0) {
          campo.PLANEJAMENTO = true;
          campo.obrigatorioVinculo = true;
        } else {
          campo.obrigatorioVinculo = false;
        }
        this.recarregarPlanejamento = true;
        setTimeout(() => {
          this.recarregarPlanejamento = false;
        }, 2E2);
      }
    },
    setCamposConfiguracao(todosCampos, fluxos) {
      this.camposConfiguracao = [...todosCampos];
      this.fluxos = fluxos.split(',');

      const possuiEtapaParalela = this.fluxos
        .filter((f) => f.indexOf(this.tokenEtapaParalela) >= 0).length > 0;

      if (possuiEtapaParalela) {
        const etapaParalela = this.fluxos.splice(this.fluxos.length - 1, 1)[0];
        this.fluxos = [...this.fluxos, ...etapaParalela.split(this.tokenEtapaParalela)];
      }

      this.formataFluxosHabilitados();

      this.headersCamposFluxos = [...this.headersCampos];
      this.fluxos.forEach((f) => {
        this.headersCamposFluxos.push({
          text: this.$tc(`label.${f.toLowerCase()}`, 1),
          value: f,
          sortable: false,
          width: '15%',
        });
      });
      this.headersCamposFluxos.push(this.headerEdicaoAprovado);
      this.headersCamposFluxos.push(this.headerComplementoInformacoes);
    },
    setFluxosAprovacao() {
      if (this.fluxoAprovacao && this.fluxoAprovacao.length) {
        this.fluxoAprovacao = this.fluxoAprovacao
          .filter((el) => this.fluxos.includes(el.tipoFluxo));
        const aux = this.fluxoAprovacao.map((el) => el.tipoFluxo);
        const outrosFluxos = this.fluxos.filter((el) => !aux.includes(el));
        outrosFluxos.forEach((el) => {
          this.fluxoAprovacao.push({
            tipoFluxo: el,
            fluxoAprovacao: this.$tc(`label.${el.toLowerCase()}`),
            indHabilitaFluxo: false,
            indJustificativaAnalise: false,
            indHabilitaEdicaoAprovacao: false,
          });
        });
        this.fluxoAprovacao = this.fluxoAprovacao.map(this.montaObjetoAprovacao);
      } else {
        this.fluxos.forEach((el) => {
          this.fluxoAprovacao.push({
            tipoFluxo: el,
            fluxoAprovacao: this.$tc(`label.${el.toLowerCase()}`),
            indHabilitaFluxo: false,
            indJustificativaAnalise: false,
            indHabilitaEdicaoAprovacao: false,
          });
        });
      }
      this.parametrizacao.fluxoAprovacao = this.fluxoAprovacao;
    },
    montaObjetoAprovacao(fluxo) {
      const montaString = (str) => this.$tc(`label.${str.toLowerCase()}`);

      return {
        ...fluxo,
        fluxoAprovacao: montaString(fluxo.tipoFluxo),
      };
    },
    setCamposDinamicos(camposDinamicos) {
      this.labelsCamposDinamicos = camposDinamicos.map((campo) => campo.label);
    },
    campoHabilitadoEdicaoAprovado(label) {
      return label === 'descricao'
        || this.labelsCamposDinamicos.indexOf(label) >= 0;
    },
    campoHabilitadoComplementoInformacao(label) {
      return this.labelsCamposDinamicos.indexOf(label) >= 0;
    },
    campoPlanejamentoEditavel(fluxo, item) {
      if (fluxo === 'PLANEJAMENTO') {
        const naoEditaveis = [
          'descricao',
          'unidadenegocio',
          'periodo_planejamento',
          'status',
          'valor',
          'divisao',
          'cliente',
          'holding',
          'cliente_pagador',
          'fornecedor',
          'grupo_fornecedor',
          'fornecedor_pagador',
          'campo_cliente',
          'regional',
        ];
        if (!item.obrigatorio) {
          return true;
        }
        return naoEditaveis.indexOf(item.label) < 0;
      }
      return true;
    },
    campoProdutoPlanejamentoEditavel(fluxo, item) {
      return !(fluxo === 'PLANEJAMENTO' && item.indCampoProduto && (item.obrigatorio || item.excecao));
    },
    campoCarteiraClienteEditavel(fluxo, item) {
      return !(fluxo === 'PLANEJAMENTO' && item.indCampoCarteira);
    },
    campoVinculoEditavel(fluxo, item) {
      return !(fluxo === 'PLANEJAMENTO' && item.obrigatorioVinculo);
    },
    campoDinamicoPlanejamentoEditavel(fluxo, item) {
      return !(fluxo === 'PLANEJAMENTO' && item.idCampoDinamico && item.obrigatorio);
    },
    parseFluxosHabilitados() {
      this.camposConfiguracao.forEach((campo) => {
        campo.fluxos = [];
        this.fluxos.forEach((f) => {
          if (campo[f]) {
            campo.fluxos.push(f);
          }
        });
      });
    },
    formataFluxosHabilitados() {
      this.camposConfiguracao.forEach((campo) => {
        if (!campo.isCampoPadraoLiquidacao
          && (!this.campoPlanejamentoEditavel('PLANEJAMENTO', campo)
          || !this.campoProdutoPlanejamentoEditavel('PLANEJAMENTO', campo)
          || !this.campoCarteiraClienteEditavel('PLANEJAMENTO', campo)
          || !this.campoDinamicoPlanejamentoEditavel('PLANEJAMENTO', campo))) {
          campo.PLANEJAMENTO = true;
        } else if (this.campoPlanejamentoHabilitado(campo.label)) {
          campo.PLANEJAMENTO = true;
        } else {
          campo.PLANEJAMENTO = false;
        }

        if (campo.fluxos && campo.fluxos.length) {
          campo.fluxos.forEach((f) => {
            campo[f] = true;
          });
        }
        if (campo.etapasFluxo
          && campo.etapasFluxo.filter((c) => c).length > 0) {
          campo.complementoInformacao = true;
        } else {
          campo.complementoInformacao = false;
          campo.etapasFluxo = undefined;
        }
      });
    },
    triggerEstruturaUsuario() {
      this.triggerClick('config_acao_fluxo_estrutura');
    },
    buscarEstruturaUsuario(nome) {
      const nomeInput = this.formataInput(nome);
      const params = {
        nome: nomeInput,
        tamanhoPagina: 50000,
      };
      this.estruturaUsuarioResources.listarAtivos(params)
        .then((r) => {
          this.listaEstruturaUsuario = r.data
            .resposta
            .map((estrutura) => (
              {
                id: estrutura.id,
                nomEstruturaUsuario: estrutura.nomEstruturaUsuario,
              }
            ));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    formataInput(input) {
      return typeof input === 'string' ? input : null;
    },
    campoPlanejamentoHabilitado(label) {
      const item = this.camposConfiguracao.find((el) => el.label === label);
      return (item.fluxos || []).includes('PLANEJAMENTO');
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    proximoPasso() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.parseFluxosHabilitados();
      this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
      this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
    },
    voltarPasso() {
      this.fluxoAprovacao = this.fluxoAprovacaoBackup;
      this.$emit('PARAMETRIZACAO_ACAO_VOLTAR', this.passo - 1);
    },
    prepararPasso() {
      if (this.id) {
        this.obter(this.id);
      } else {
        this.buscarEstruturaUsuario();
      }
    },
    obter(id) {
      this.paramAcaoResources.obterPasso4({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo4(id, response.data.indVigenciaAutomatica,
          response.data.indVigenciaTrimestral);
        this.listaBanco = response.data.listaVinculo;
        this.fluxoAprovacao = response.data.fluxoAprovacao;
        this.fluxoAprovacaoBackup = response.data.fluxoAprovacao;
        this.buscarEstruturaUsuario();
        this.$forceUpdate();
      }, (err) => {
        this.$error(this, err);
      });
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
  .margem {
    margin-top: 12px;
    margin-bottom: 0px !important;
  }
</style>
