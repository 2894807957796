<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h3 class="headline mb-0">{{ $t('title.calendario') }}</h3>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              class="CalendarioForm-data__text"
              id="nome"
              name="nome"
              v-model="evento.nome"
              :label="`${$t('label.nome')} *`"
              :counter="50"
              evento
              :rules="[rules.required, rules.maxLength50]"
              :readonly="somenteLeitura">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              :items="tipos"
              class="CalendarioForm-data__text"
              v-model="evento.idTipo"
              item-text="descricao"
              item-value="id"
              :label="`${$tc('label.tipo', 1)} *`"
              :rules="[rules.requiredTipo]"
              @change="exibeObservacoes">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="data_inicio"
              :close-on-content-click="false"
              v-model="menuDataInicio"
              id="data_inicio"
              name="data_inicio"
              :nudge-right="40"
              lazy
              :readonly="somenteLeitura"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  class="CalendarioForm-data__text"
                  :return-masked-value="true"
                  v-mask="'##-##-####'"
                  :rules="[rules.required, rules.validDate, rules.dataInicioMenorQueDiaSeguinte]"
                  v-model="dateInicioFormatada"
                  :label="`${$t('label.data_inicio')} *`"
                  prepend-icon="event">
                </v-text-field>
              </template>

              <v-date-picker locale="pt-br" color="primary" :min="dataDiaSeguinte" v-model="dataInicio" @input="menuDataInicio = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="data_fim"
              :close-on-content-click="false"
              v-model="menuDataFim"
              id="data_fim"
              name="data_fim"
              :nudge-right="40"
              lazy
              :readonly="somenteLeitura"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  class="CalendarioForm-data__text"
                  :return-masked-value="true"
                  v-mask="'##-##-####'"
                  :rules="[rules.required, rules.validDate, rules.dataFimMaiorInicio]"
                  v-model="dateFimFormatada"
                  :label="`${$t('label.data_fim')} *`"
                  prepend-icon="event">
                </v-text-field>
              </template>

              <v-date-picker locale="pt-br" color="primary" :min="dataInicio" v-model="dataFim" @input="menuDataFim = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" sm="3" md="2">
            <v-menu
              ref="cor"
              v-model="menuCor"
              id="cor"
              name="cor"
              lazy
              :readonly="somenteLeitura"
              transition="scale-transition"
              max-width="290px"
              min-width="290px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  block
                  style="width: 100%"
                  :color="evento.cor"
                  dark>
                  {{$tc('label.cor', 1)}}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in cores"
                  :key="i"
                  :color="item"
                  @click="() => evento.cor = item">
                    <v-card dark :color="item" style="width: 100%">
                      <v-card-text></v-card-text>
                    </v-card>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="observacoes">
            <p v-if="observacoes.cabecalho" v-html="observacoes.cabecalho"/>
            <p v-if="observacoes.texto" v-html="observacoes.texto"/>
            <ul v-if="observacoes.lista">
              <li v-for="item in observacoes.lista" v-bind:key="item">
                {{ item }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $t('label.data')})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '@/common/functions/date-utils';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'CalendarioForm',
  components: {
    Confirm,
  },
  props: {
    idEvento: {
      type: Number,
      default: null,
    },
    somenteLeitura: true,
  },
  data() {
    return {
      resources: this.$api.calendario(this.$resource),
      tipos: [],
      menuDataInicio: false,
      menuCor: false,
      menuDataFim: false,
      observacoes: null,
      eventosListados: [],
      cores: [
        'red darken-1',
        'deep-purple darken-1',
        'blue darken-1',
        'light-blue darken-1',
        'cyan darken-1',
        'teal darken-1',
        'green darken-1',
        'light-green darken-1',
        'lime darken-1',
        'yellow darken-1',
        'amber darken-1',
        'orange darken-1',
        'deep-orange darken-1',
        'blue-grey darken-1',
        'grey darken-1',
      ],
      evento: {
        id: null,
        nome: '',
        dataInicio: null,
        dataFim: null,
        dataDiaSeguinte: null,
        idTipo: null,
        cor: 'red darken-1',
        indAtivo: true,
      },
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTipo: () => !!(this.evento.idTipo) || this.$t('message.campo_obrigatorio'),
        maxLength50: (v) => (v && v.length <= 50) || this.$t('message.limite_caracteres_excedido'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
        dataFimMaiorInicio: () => this.isDataFimMaiorInicio() || this.$t('message.data_inicial_maior_final'),
        dataInicioMenorQueDiaSeguinte: () => !this.isDataInicioMenorDiaSeguinte() || this.$t('message.data_inicial_menor_dia_seguinte'),
      },
    };
  },
  computed: {
    dateInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.evento.dataInicio);
      },
      set(newValue) {
        this.evento.dataInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dataInicio: {
      get() {
        return this.evento.dataInicio;
      },
      set(newValue) {
        this.evento.dataInicio = newValue;
      },
    },
    dateFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.evento.dataFim);
      },
      set(newValue) {
        this.evento.dataFim = parseDateYYYYMMDD(newValue);
      },
    },
    dataFim: {
      get() {
        return this.evento.dataFim;
      },
      set(newValue) {
        this.evento.dataFim = newValue;
      },
    },
    dataDiaSeguinte: {
      get() {
        return `${this.moment().add(1, 'd').format('YYYY-MM-DD')}`;
      },
      set(newValue) {
        this.evento.dataDiaSeguinte = formatDateDDMMYYYY(newValue);
      },
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      if (this.idEvento) {
        this.resources.atualizar({ id: this.idEvento }, this.evento).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      } else {
        this.resources.gravar(this.evento).then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    isDataFimMaiorInicio() {
      const dtInicio = this.moment(this.evento.dataInicio, 'YYYY-MM-DD');
      const dtFinal = this.moment(this.evento.dataFim, 'YYYY-MM-DD');
      return dtFinal.isAfter(dtInicio);
    },
    isDataInicioMenorDiaSeguinte() {
      const dtInicio = this.moment(this.evento.dataInicio, 'YYYY-MM-DD');
      const dtDiaSeguinte = this.moment().add(1, 'd').format('YYYY-MM-DD');
      return dtInicio.isBefore(dtDiaSeguinte);
    },
    buscarTipos() {
      this.resources.tipos().then((response) => {
        this.tipos = response.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    carregar() {
      const id = this.idEvento;
      this.resources.obter({ id }).then((response) => {
        this.evento = response.data;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    buscarEventos() {
      this.resources.listar().then((response) => {
        this.eventosListados = response.data.resposta;
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    exibeObservacoes(id) {
      const obj = this.tipos.find((el) => el.id === id);
      if (obj && obj.observacoes) {
        this.observacoes = JSON.parse(obj.observacoes.value);
      }
    },
  },
  mounted() {
    this.buscarTipos();
    if (this.idEvento) {
      this.carregar();
      this.buscarEventos();
    }
  },
};
</script>

<style>
  .CalendarioForm-data__text > label {
    animation: unset !important;
  }
</style>
