import getBasePath from '@/common/functions/api-resource';

const basePathCadastro = getBasePath('api');
export const basePath = getBasePath('job');

const importacaoActions = {
  listarAndamento: { method: 'GET', url: `${basePathCadastro}importacao/andamento` },
  listarErro: { method: 'GET', url: `${basePathCadastro}importacao/erro` },
  listarFinalizado: { method: 'GET', url: `${basePathCadastro}importacao/finalizado` },
  listarTipos: { method: 'GET', url: `${basePathCadastro}tipo-importacao` },
};

export default (resource) => resource(`${basePath}`, {}, importacaoActions);
