<template>
  <transition name="slide-fade"
    mode="out-in" v-if="canAccessPage">
    <router-view></router-view>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  name: 'Usuario',
  methods: {
    ...mapActions('metadados', [
      'setUsuarioMetadado',
    ]),
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast('Acesso negado');
    },
  },
  computed: {
    ...generateComputed('usr', [
      'canAccessPage',
    ]),
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
  mounted() {
    this.setUsuarioMetadado({
      resource: this.metadadoResource.listarUsuario,
    });
  },
};
</script>
