import { hideLoading } from '@/common/functions/loading';
import Home from '@/spa/Home';
import NotFound from '@/spa/NotFound';
import alertaManutencao from './administracao/alerta-manutencao';
import calendario from './administracao/calendario';
import estruturaUsuario from './administracao/estrutura-usuario';
import dashboardInicial from './dashboard-inicial';
import filial from './administracao/filial';
import divisao from './administracao/divisao';
import ssoGrupo from './administracao/sso-grupo';
import usuarioFuncionario from './administracao/usuario-funcionario';
import usuarioFornecedor from './administracao/usuario-fornecedor';
import unidadeMedida from './cadastro/unidade-medida';
import regiao from './administracao/regiao';
import workflow from './administracao/workflow';
import termoAceite from './administracao/termo-aceite';
import tipoUso from './tipo-uso';
import produto from './cadastro/produto';
import nivelFornecedor from './administracao/nivel-fornecedor';
import importacao from './importacoes';
import fornecedor from './cadastro/fornecedor';
import notas from './cadastro/notas';
import planejamentoAcao from './acao/planejamento-acao';
import apuracaoAcao from './acao/apuracao-acao';
import recebimento from './acao/recebimento';
import planejamentoContrato from './contrato/planejamento-contrato';
import apuracaoContrato from './contrato/apuracao-contrato';
import apuracaoContratoSamsung from './contrato/apuracao-contrato-samsung';
import apuracaoContratoEpoca from './contrato/apuracao-contrato-epoca';
import parametrizacaoAcao from './parametrizacao/parametrizacao-acao';
import notasDebitoContabilizacao from './notas-debito/notas-debito-contabilizacao';
import notasDebitoConsulta from './notas-debito/notas-debito-consulta';
import relatorios from './relatorios';
import camposCustomizados from './parametrizacao/campos-customizados';
import nivelProduto from './parametrizacao/nivel-produto';
import parametrizacaoContrato from './parametrizacao/parametrizacao-contrato';
import configuracaoSistema from './parametrizacao/configuracao-sistema';
import administracao from './administracao/administracao';

const configureGuards = (router) => {
  router.afterEach((route) => {
    if (route.params.menu) {
      hideLoading();
    }
  });
};

export const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home,
    redirect: '/dashboard-inicial',
    children: [
      dashboardInicial,
      alertaManutencao,
      importacao,
      calendario,
      divisao,
      estruturaUsuario,
      filial,
      regiao,
      ssoGrupo,
      tipoUso,
      termoAceite,
      unidadeMedida,
      usuarioFuncionario,
      usuarioFornecedor,
      workflow,
      produto,
      nivelFornecedor,
      fornecedor,
      notas,
      planejamentoAcao,
      apuracaoAcao,
      recebimento,
      planejamentoContrato,
      apuracaoContrato,
      apuracaoContratoSamsung,
      apuracaoContratoEpoca,
      notasDebitoContabilizacao,
      notasDebitoConsulta,
      relatorios,
      camposCustomizados,
      nivelProduto,
      parametrizacaoAcao,
      parametrizacaoContrato,
      configuracaoSistema,
      administracao,
    ],
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const VueRouterObject = {
  routes,
  saveScrollPosition: true,
};

export default (VueRouter) => {
  const vueRouter = new VueRouter(VueRouterObject);
  configureGuards(vueRouter);
  return vueRouter;
};
