import getBasePath from '@/common/functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'calendario');
const calendarioActions = {
  ...genericActions(basePath),
  tipos: { method: 'GET', url: `${basePath}/tipos` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  ativarInativar: { method: 'PUT', url: `${basePath}/ativacao/{id}/{status}` },
  cancelar: { method: 'PUT', url: `${basePath}/{id}/cancelar` },
  tiposAtivos: { method: 'GET', url: `${basePath}/ativos?tipo={tipo}` },
};

export default (resource) => resource(`${basePath}`, {}, calendarioActions);
