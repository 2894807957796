import getBasePath from '@/common/functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/contrato');

const workflowContratoActions = {
  aprovador: { method: 'GET', url: `${basePath}/{idContrato}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idContrato}/passos/aprovar` },
  associaUsuariosCondicionalAprovador: { method: 'POST', url: `${basePath}/passos/associar-usuarios-condicional` },
  buscaFlagSubstituicaoContrato: { method: 'GET', url: `${basePath}/{idPassoAtual}/buscaFlagSubstituicaoContrato` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idContrato}/passos/fornecedor` },
  buscaPassosGerente: { method: 'GET', url: `${basePath}/{idContrato}/passos/gerente` },
  buscarContratosRenovacao: { method: 'GET', url: `${basePath}/{idContrato}/contratos-renovacao` },
  buscarFluxoTrade: { method: 'GET', url: `${basePath}/fluxo-trade` },
  cancelar: { method: 'PUT', url: `${basePath}/{idContrato}/cancelar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idContrato}/passos/analise` },
  enviarAnaliseContratoPorInativacaoFornecedor: { method: 'PUT', url: `${basePath}/{idContrato}/passos/{observacao}/analise-contrato-por-inativacao-fornecedor` },
  existeFluxoCadastrado: { method: 'GET', url: `${basePath}/{idContrato}/fluxo-cadastrado` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idContrato}` },
  reprovar: { method: 'PUT', url: `${basePath}/{idContrato}/passos/reprovar` },
  salvarPrazoPagamento: { method: 'PUT', url: `${basePath}/fluxo/salvar-prazo-pagamento/{idContrato}` },
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idContrato}` },
  status: { method: 'GET', url: `${basePath}/{idContrato}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idContrato}/passos/status` },
  verificaSeUsuarioAprovadorContratoComplementoInformacoes: { method: 'GET', url: `${basePath}/fluxo/usuario-informa-complemento-informacoes` },
};
export default (resource) => resource(`${basePath}`, {}, workflowContratoActions);

export const pendenciasContratos = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-contratos', parametros).doGet();
