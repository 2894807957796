<template>
  <v-card style="margin-bottom:1em" class="RelatorioExportacoesProdutoFiltros">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.produto', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon v-show="mostrarFiltros"
          @click="fixaFiltros"
          :class="filtrosFixosClass"
          style="margin-right: 10px"
          >fa-thumbtack</v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          v-if="camposFormulario !== null"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-inativos="false"
          :ignora-obrigatorios="umFiltroObrigatorioSelecionado"
          :objeto="filtrosExportacaoProduto"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="aplicarFiltros"
          color="primary"
        >{{$t('label.exportar')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { skipLoading } from '@/common/functions/loading';
import { mapGetters } from 'vuex';
import { countRelatorioProduto } from '@/common/resources/magalu-relatorios'; // eslint-disable-line
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import RelatorioExportacaoProdutoFiltrosCampos from './RelatorioExportacaoProdutoFiltrosCampos';

export default {
  name: 'RelatorioExportacaoProduto',
  mixins: [
    RelatorioExportacaoProdutoFiltrosCampos,
  ],
  watch: {
    filtrosExportacaoProduto: {
      handler() {
        const idsCategoria = this.filtrosExportacaoProduto.categoria;
        const idsFamilia = this.filtrosExportacaoProduto.familia;
        if ((idsCategoria && idsCategoria.length > 0)
          || (idsFamilia && idsFamilia.length > 0)) {
          this.umFiltroObrigatorioSelecionado = true;
        } else {
          this.umFiltroObrigatorioSelecionado = false;
        }
        this.$refs.form.resetValidation();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      ordenacaoCampos: [
        'categoria',
        'familia',
      ],
      mostrarFiltros: false,
      filtrosFixados: false,
      filtrosExportacaoProduto: {},
      umFiltroObrigatorioSelecionado: false,
      limiteRegistrosExportacao: 50000,
      camposFormulario: null,
    };
  },
  methods: {
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtroValido(filtrosAplicados) {
      // valida se um dos dois filtros foi selecionado
      if ((filtrosAplicados.id_familia && filtrosAplicados.id_familia.length > 0)
        || (filtrosAplicados.id_categoria && filtrosAplicados.id_categoria.length > 0)) {
        return true;
      }
      return false;
    },
    resetaFiltros() {
      this.filtrosExportacaoProduto = {};
    },
    aplicarFiltros() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.filtroValido(this.$refs.container.getValoresCamposPadrao())) {
        this.$toast(this.$t('message.selecione_um_filtro_obrigatorio'));
        return;
      }
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
      };

      let quantidadeRegistros = 0;

      countRelatorioProduto(params, this.$resource)
        .then((response) => {
          quantidadeRegistros = response.data;
          if (quantidadeRegistros > this.limiteRegistrosExportacao) {
            this.$toast(this.$t('errors.registros.excede_limite_dados', { limite: this.limiteRegistrosExportacao }));
          } else if (quantidadeRegistros > 0) {
            let nomeRelatorio = '';
            if (this.isAmbienteKabum) {
              nomeRelatorio = 'produto_kabum';
            } else {
              nomeRelatorio = 'produto_magalu';
            }
            this.$emit('RelatorioExportacaoProduto__AplicaFiltros',
              params,
              nomeRelatorio,
              'api');
          } else {
            this.$toast(this.$t('errors.registros.nao_ha_dados'));
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'relatorio-produto';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'relatorio-produto';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosExportacaoProduto;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
  },
  mounted() {
    if (this.isAmbienteKabum) {
      this.camposFormulario = this.camposFormularioKabum;
    } else {
      this.camposFormulario = this.camposFormularioMagalu;
    }
  },
};
</script>
<style>
  .RelatorioExportacoesProdutoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .RelatorioExportacoesProdutoFiltros__Opcoes div.v-input__slot,
  .RelatorioExportacoesProdutoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .RelatorioExportacoesProdutoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .RelatorioExportacoesProdutoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
