<template lang="html">
  <div>
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card-title primary-title class="card-header">
        {{ $t('title.resumo') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.resumo')}`" />
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              id="config_acao_nome"
              v-model="parametrizacao.nome"
              :label="`${$tc('label.nome', 1)} * `"
              :rules="[rules.required]"
              maxlength="50"
              counter=50
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              id="config_acao_descricao"
              v-model="parametrizacao.descricao"
              :label="`${$tc('label.descricao', 1)} * `"
              :rules="[rules.required]"
              maxlength="200"
              counter=200
              required>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              id="config_acao_categoria"
              v-model="parametrizacao.categoriaAcao"
              :label="$tc('label.categoria_acao', 1)"
              maxlength="50"
              counter=50>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              id="config_acao_fluxo_trade"
              class="Form-text__invalid"
              name="forma_rateio"
              item-text="text"
              item-value="value"
              v-model="parametrizacao.fluxo"
              :items="fluxos"
              :rules="[rules.required]"
              :label="`${$tc('label.fluxo_trademarketing', 1)} *`"
              required>
            </v-select>
          </v-col>
          <v-col cols="12" sm="4" md="2">
            <v-switch
              color="primary"
              id="config_acao_ativar_inativar"
              :label="`${$t('label.ativo')}`"
              v-model="parametrizacao.indAtivo"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-switch
              color="primary"
              id="config_acao_exclusivo_contrato"
              :label="`${$t('label.habilitar_uso_exclusivo_contrato')}`"
              v-model="parametrizacao.indExclusivoContrato"/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-title primary-title class="card-header">
        {{ $t('title.upload_arquivo') }}
        <v-spacer />
        <tooltip-ajuda :mensagem="`${$t('message.ajuda.parametrizacao_acao.upload')}`" />
      </v-card-title>
      <v-container fluid>
          <v-col cols="12" sm="12" class="pb-0">
            <v-switch
              id="config_acao_upload_obrigatorio"
              :label="$tc('label.upload_arquivo_obrigatorio_aprovacao', 1)"
              @change="alteracaoUploadObrigatorio"
              v-model="parametrizacao.uploadObrigatorio"
            ></v-switch>
          </v-col>
        <v-subheader class="mt-0">{{ $tc('label.status_permitidos', 1) }}</v-subheader>
        <v-row class="mx-4">
          <v-col cols="2">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.emCadastro"
              :disabled="parametrizacao.uploadObrigatorio"
              class="mx-2"
              :label="$tc('status_entidade.em_cadastro')">
            </v-checkbox>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.aprovado"
              class="mx-2"
              :label="$tc('status_entidade.aprovado')">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="2">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.aguardandoAprovacao"
              class="mx-2"
              :label="$tc('status_entidade.aguardando_aprovacao')">
            </v-checkbox>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.reprovado"
              class="mx-2"
              :label="$tc('status_entidade.reprovado')">
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="2">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.emAnalise"
              class="mx-2"
              :label="$tc('status_entidade.em_analise')">
            </v-checkbox>
          </v-col>
          <v-col cols="10">
            <v-checkbox
              v-model="parametrizacao.statusPermitidosUploadPlanejamento.cancelado"
              class="mx-2"
              :label="$tc('status_entidade.cancelado')">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>

      <v-row justify="end" class="my-4">
        <v-btn id="config_acao_passo1_botao_cancelar" @click="cancelar" class="mr-3">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="config_acao_passo1_botao_proximo" color="primary" @click="proximoPasso" class="mr-3">{{ $t('label.proximo_passo') }}</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script type="text/javascript">
import Passo1 from '@/spa/parametrizacao/acao/Passo1';
import TooltipAjuda from '@/spa/parametrizacao/TooltipAjuda';

export default {
  name: 'ParametrizacaoAcaoPasso1',
  props: {
    id: Number,
    copy: String,
  },
  components: {
    TooltipAjuda,
  },
  watch: {
    id() {
      this.prepararPasso();
    },
  },
  data() {
    return {
      paramAcaoResources: this.$api.parametrizacaoAcao(this.$resource),

      passo: 1,
      headersTipoInvestimento: [
        { text: this.$tc('label.tipo_verba', 1), value: 'tipoVerba', width: '25%' },
        { text: this.$tc('label.tipo_investimento', 1), value: 'tipoInvestimento', width: '25%' },
        { text: this.$tc('label.habilita', 1), value: 'habilita', width: '20%' },
        { text: this.$tc('label.operacao', 1), value: 'operacao', width: '30%' },
      ],
      tipos: [],
      fluxos: [
        {
          text:
            `${this.$tc('label.planejamento', 1)} -> ${this.$tc('label.apuracao', 1)} -> ${this.$tc('label.liquidacao', 1)}`,
          value: 'PLANEJAMENTO,APURACAO,LIQUIDACAO',
        },
        {
          text:
            `${this.$tc('label.planejamento', 1)} -> ${this.$tc('label.liquidacao', 1)}`,
          value: 'PLANEJAMENTO,LIQUIDACAO',
        },
        {
          text:
            `${this.$tc('label.planejamento', 1)} -> ${this.$tc('label.apuracao', 1)} + ${this.$tc('label.liquidacao', 1)}`,
          value: 'PLANEJAMENTO,APURACAO_E_LIQUIDACAO',
        },
        {
          text: `${this.$tc('label.planejamento', 1)}`,
          value: 'PLANEJAMENTO',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      parametrizacao: new Passo1(),
      operacoes: [
        { text: this.$tc('label.debito', 1), value: 'DEBITO' },
        { text: this.$tc('label.credito', 1), value: 'CREDITO' },
      ],
    };
  },
  methods: {
    alteracaoUploadObrigatorio() {
      this.parametrizacao
        .statusPermitidosUploadPlanejamento
        .emCadastro = this.parametrizacao.uploadObrigatorio
        || this.parametrizacao.statusPermitidosUploadPlanejamento.emCadastro;
    },
    cancelar() {
      this.$emit('PARAMETRIZACAO_ACAO_CANCELAR');
    },
    limparCampos() {
      this.parametrizacao.descricao = '';
      this.parametrizacao.nome = '';
    },
    obter(id, copy) {
      this.paramAcaoResources.obterPasso1({ id }, { id }).then((response) => {
        this.parametrizacao = new Passo1(response.data);
        if (copy) {
          this.limparCampos();
        }
        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_ACAO_DEFINIR_PASSOS');
      }, (err) => {
        this.$error(this, err);
      });
    },
    prepararPasso() {
      if (this.id) {
        // Editar - Copiar
        this.obter(this.id, this.copy);
      } else {
        // Novo
        this.parametrizacao = new Passo1();
      }
    },
    proximoPasso() {
      if (this.$refs.form.validate()) {
        this.$emit('PARAMETRIZACAO_ACAO_ATUALIZAR_PASSO', this.passo, this.parametrizacao);
        this.$emit('PARAMETRIZACAO_ACAO_CONTINUAR', this.passo + 1, this.id);
      }
    },
  },
  mounted() {
    this.prepararPasso();
  },
};
</script>
<style lang="scss">
  .card-header {
    background-color: var(--v-primary-base);
    color: #ffffff;
  }
</style>
