import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('usuario', 'usuario');

const usuarioActions = {
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  listarTodos: { method: 'GET', url: `${basePath}?nome={nome}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  listarUsuariosFornecedores: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}{&idsGrupoFornecedor*}{&statusFluxo*}{&indUsuarioExternoMaster*}` },
  buscar: { method: 'GET', url: `${basePath}/{id}` },
  buscarNoOmni: { method: 'GET', url: `${basePath}/{tipo}/omni/{email}` },
  listarAtivosResumido: { method: 'GET', url: `${basePath}/resumido?nome={nome}&indAtivo=true{&ids*}` },
  listarSuperior: { method: 'GET', url: `${basePath}/superior` },
  informacao: { method: 'GET', url: `${basePath}/informacao` },
  listarConfiguracao: { method: 'GET', url: `${basePath}/configuracao` },
  buscaUltimaAlteracao: { method: 'GET', url: `${basePath}/ultima-alteracao/{idUsuario}` },
  dadosFluxosAprovacaoImpactados: { method: 'GET', url: `${basePath}/dados-fluxos-aprovacao-impactados/{idUsuario}` },
  buscaTermoAceiteUsuarioCadastro: { method: 'GET', url: `${basePath}/busca-termo-aceite-cadastro/{idUsuario}` },
  gravar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  atualizarConfiguracao: { method: 'PUT', url: `${basePath}/configuracao` },
  redefinirSenha: { method: 'PUT', url: `${basePath}/senha/redefinicao/usuario/{idUsuario}` },
  redefinirSenhaPadraoTradelinks: { method: 'PUT', url: `${basePath}/senha/redefinicao-senha-padrao/usuario/{idUsuario}` },
  aprovarUsuarioMaster: { method: 'PUT', url: `${basePath}/aprovar-usuario-master` },
  buscarFluxoAprovacao: { method: 'GET', url: `${basePath}/buscar-fluxo-aprovacao/{idUsuario}` },
  verificarRepresentacaoConjunto: { method: 'GET', url: `${basePath}/verificar-rep-conjunto/{idGrupoFornecedor}` },
  atualizarRepresentacaoConjunto: { method: 'PUT', url: `${basePath}/atualizar-rep-conjunto/{idGrupoFornecedor}/{indRepresentacaoConjunto}` },

};

export default (resource) => resource(`${basePath}`, {}, usuarioActions);
