<template lang="html">
  <v-card v-if="canAccessPage">
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.termo_aceite', 1) }}</h3>
    </v-card-title>
    <v-card-text>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="searchQuery"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text icon v-on="on"
                @click.native="requestExportacao">
                <v-icon>get_app</v-icon>
              </v-btn>
            </template>
            <span>{{$tc('label.exportar', 1)}}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="termos"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :items-per-page="5"
        class="elevation-1"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="grey--text text--darken-1">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="verDetalhes(item)" v-if="!canCRUD">
                <v-list-item-action>
                  <v-icon>equalizer</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">{{ $t('label.mais_detalhes') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="editar(item)" v-if="canCRUD">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">{{ $t('label.editar') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="ativarInativar(item)" v-if="canCRUD">
                <v-list-item-action>
                  <v-icon>block</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">{{ $t('label.ativar_inativa') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.grupos_fornecedor="{ item }">
          {{ item.gruposFornecedor }}
        </template>
        <template v-slot:item.objetivo="{ item }">
          {{ $t(`label.${item.objetivo.toLowerCase()}`) }}
        </template>
        <template v-slot:item.frequencia="{ item }">
          {{ $t(`label.${item.frequencia.toLowerCase()}`) }}
        </template>
        <template v-slot:item.indAtivo="{ item }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao') }}
        </template>
      </v-data-table>

      <v-btn class="v-btn__fab"
        v-if="canCRUD"
        color="primary"
        dark fixed fab bottom right
        @click="abrirForm">
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import exportacao from '@/common/resources/exportacoes';

export default {
  name: 'TermoAceiteLista',
  components: {

  },
  data() {
    return {
      termoAceiteResources: this.$api.termoAceite(this.$resource),

      pagination: {},
      totalPage: 0,
      searchQuery: '',
      headers: [
        { text: this.$tc('label.objetivo', 1), value: 'objetivo', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.frequencia', 1), value: 'frequencia', sortable: false },
        { text: this.$tc('label.ativo', 1), value: 'indAtivo', sortable: false },
      ],
      termos: [],
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    idUsuario() {
      return this.usuarioLogado.id;
    },
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el.startsWith('TERMO_ACEITE')).length;
    },
    canCRUD() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'TERMO_ACEITE_CRUD').length;
    },
  },
  watch: {
    searchQuery() {
      if (this.timeoutTemp) {
        window.clearTimeout(this.timeoutTemp);
      }
      this.timeoutTemp = window.setTimeout(() => {
        this.buscarTermos();
      }, 500);
    },
    pagination: {
      handler() {
        this.buscarTermos();
      },
      deep: true,
    },
  },
  methods: {
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    abrirForm() {
      this.$router.push({ name: 'novoTermoAceite' });
    },
    buscarTermos() {
      const params = {
        tamanhoPagina: this.pagination.itemsPerPage || 10,
        numeroPagina: this.pagination.page || 1,
        filter: this.searchQuery,
      };

      this.termoAceiteResources.listarFiltrado(params).then((response) => {
        this.termos = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    editar(item) {
      this.$router.push({ name: 'editarTermoAceite', params: { id: item.id } });
    },
    verDetalhes(item) {
      this.$router.push({ name: 'verTermoAceite', params: { id: item.id } });
    },
    ativarInativar(item) {
      const codigo = item.id;
      this.termoAceiteResources.ativacao({ codigo }, { codigo }).then(() => {
        item.indAtivo = !item.indAtivo;
        this.$toast(this.$t('message.atualizado_confira_tabela'));
      }, (err) => {
        this.$error(this, err);
      });
    },
    requestExportacao(cb) {
      this.exportar(cb, 'termo_aceite_usuario');
    },
    exportar(cb, nomeExportador, limit = 500, timeout = 120000) {
      const resource = exportacao(this.$http, timeout);
      let payload = null;
      if (this.idUsuario && nomeExportador === 'termo_aceite_usuario') {
        const parametrosConsulta = { idUsuario: this.idUsuario };
        if (this.searchQuery) {
          parametrosConsulta.filtro = this.searchQuery;
        }
        parametrosConsulta.indModoVarejo = true;
        payload = { parametrosConsulta, limit };
      } else {
        payload = { limit };
      }
      resource.download(nomeExportador, payload)
        .then(() => {
          this.$toast(this.$t('message.exportacao_download'));
        })
        .catch(() => {
          this.$toast('Erro ao exportar. Tente novamente.');
        });
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
  mounted() {
    this.headers.unshift(
      {
        text: this.$tc('label.acoes', 2), value: 'acoes', width: '2%', sortable: false,
      },
      { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false },
      { text: this.$tc('label.grupo_fornecedor', 1), value: 'grupos_fornecedor', sortable: false },
    );
  },
};
</script>
