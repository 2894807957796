import Fornecedor from '@/spa/fornecedor/FornecedorCadastro';

const FornecedorList = () => import('@/spa/fornecedor/FornecedorList');
const FornecedorForm = () => import('@/spa/fornecedor/FornecedorForm');

export default {
  path: 'fornecedor',
  component: Fornecedor,
  children: [
    {
      path: '',
      name: 'fornecedor',
      component: FornecedorList,
    },
    {
      path: 'novo',
      name: 'novoFornecedor',
      component: FornecedorForm,
      props: {
        default: false,
        somenteLeitura: false,
      },
    },
    {
      path: ':id',
      name: 'verFornecedor',
      component: FornecedorForm,
      props: {
        default: true,
        somenteLeitura: true,
      },
    },
    {
      path: ':id/editar',
      name: 'editarFornecedor',
      component: FornecedorForm,
      props: {
        default: true,
        somenteLeitura: false,
      },
    },
  ],
};
