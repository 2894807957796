import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'nivel-produto');

const nivelProdutoActions = {
  labels: { method: 'GET', url: `${basePath}/labels` },
  categorias: { method: 'GET', url: `${basePath}/categorias-recursivas` },
  orcamento: { method: 'GET', url: `${basePath}/orcamento` },
  acao: { method: 'GET', url: `${basePath}/acao` },
};

export default (resource) => resource(`${basePath}`, {}, nivelProdutoActions);
