import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'contrato');

const planejamentoContratoActions = {
  buscarContrato: { methods: 'GET', url: `${basePath}/carregamento/{idContrato}` },
  buscaUsuarioCancelamentoContrato: { methods: 'GET', url: `${basePath}/carregamento/usuario-cancelamento-contrato/{idContrato}` },
  vericaUnicoTipo: { methods: 'GET', url: `${basePath}/listagem/tipo_contrato/unico` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/listagem/acesso/{idContrato}` },
  buscarCategoriasContrato: { methods: 'GET', url: `${basePath}/listagem/categoria/{idContrato}` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoContratoActions);

export const buscarTiposContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'listagem/tipo_contrato', parametros).doGet();

export const buscarContratos = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/tipo_contrato/contratos', parametros)
  .doGet();

export const buscarTotalizadores = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/totalizadores', parametros)
  .doGet();

export const buscarContratoPorGrupoFornecedorData = (parametros, resource) => resourceBuilder
  .buildGET(resource, basePath, 'listagem/alterar_data', parametros)
  .doGet();

export const alterarPrazoVencimento = (parametros, resource, body) => resourceBuilder
  .buildPUT(resource, basePath, 'cadastro/alterar/prazo-vencimento', parametros)
  .doPut(parametros, body);
