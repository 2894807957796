import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'agendamento');

const agendamentoActions = {
  listar: { method: 'GET', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  reagendar: { method: 'PUT', url: `${basePath}/proxima-execucao` },
  listarLogs: { method: 'GET', url: `${basePath}/{id}/logs` },
};

export default (resource) => resource(`${basePath}`, {}, agendamentoActions);
