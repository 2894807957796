import getBasePath from '@/common/functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'estrutura-usuario');

const estruturaUsuarioActions = {
  ...genericActions(basePath),
  ativarInativarRegistros: { method: 'POST', url: `${basePath}/ativarInativarRegistros` },
  listarAutocomplete: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true&{&idsEstruturaUsuarioNotIn*}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  buscarEstruturasPorVisao: { method: 'GET', url: `${basePath}/visao` },
};

export default (resource) => resource(`${basePath}`, {}, estruturaUsuarioActions);
