import VueResource from 'vue-resource';
import money from 'v-money';
import VueTheMask from 'vue-the-mask';
import Toast from './toast';
import ErrorHandler from './error-handler';
import Moment from './moment';
import Lodash from './lodash';
import APIResources from '../common/resources';

export default (Vue, ...params) => {
  params.filter((el) => typeof el !== 'undefined')
    .map((le) => Vue.use(le));
  Vue.use(VueResource);
  Vue.use(Toast);
  Vue.use(Moment);
  Vue.use(ErrorHandler);
  Vue.use(Lodash);
  Vue.use(money, { precision: 2 });
  Vue.use(VueTheMask);
  Vue.use(APIResources);
};
