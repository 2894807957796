import getBasePath from '../functions/api-resource';

const basePath = getBasePath('usuario', 'sso/grupo');

const grupoActions = {
  listar: { method: 'GET', url: `${basePath}` },
  obter: { method: 'GET', url: `${basePath}/{id}` },
  excluir: { method: 'DELETE', url: `${basePath}/{id}` },
  salvar: { method: 'PUT', url: `${basePath}` },
  adicionar: { method: 'POST', url: `${basePath}` },
};

export default (resource) => resource(`${basePath}`, {}, grupoActions);
