import getBasePath from '../functions/api-resource';

const basePath = getBasePath('apuracao_acao', 'verba_variavel/listagem');

const apuracaoActions = {
  buscarApuracao: { methods: 'GET', url: `${basePath}/carregamento/{idApuracao}` },
  listarApuracoesPorCategoria: { methods: 'GET', url: `${basePath}/categoria?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}{&status*}{&idTipoAcao*}{&nomesTiposUso*}{&idGrupoFornecedor*}{&tiposCalculo*}{&meses*}{&indAcoesComTeto*}{&codigoCampanhas*}{&descricaoPasso*}` },
  buscarApuracoes: { methods: 'GET', url: `${basePath}/categoria/{idCategoria}/apuracao?tamanhoPagina={tamanhoPagina}&numeroPagina={numeroPagina}{&status*}{&idTipoAcao*}{&nomesTiposUso*}{&idGrupoFornecedor*}{&tiposCalculo*}{&meses*}{&indAcoesComTeto*}{&codigoCampanhas*}{&descricaoPasso*}` },
  verificarSeUsuarioPossuiAcesso: { methods: 'GET', url: `${basePath}/acesso/{idApuracao}` },
  buscarMetas: { method: 'GET', url: `${basePath}/metas/{idAcao}` },
  buscarFornecedoresNd: { method: 'GET', url: `${basePath}/fornecedores-nd/{idApuracao}` },
  buscarApuracaoPorAcao: { method: 'GET', url: `${basePath}/acao/{idAcao}/apuracao` },
  buscarFornecedoresNdFluxo: { method: 'GET', url: `${basePath}/fornecedores-nd/{idApuracao}/fluxo` },
  buscarProdutosApuracaoAcao: { method: 'GET', url: `${basePath}/produtos/{idAcao}/apuracao` },
  buscarDadosPopoverNd: { method: 'GET', url: `${basePath}/popover-nd/{idAcao}` },
  buscarDadosPopoverRecebimentoPendente: { method: 'GET', url: `${basePath}/popover-nd-recebimento-pendente/{idAcao}` },
  reprocessarApuracao: { method: 'PUT', url: `${basePath}/reprocessar-apuracao/{idApuracao}` },
  registrarApuracaoAcaoReprocessamento: { method: 'POST', url: `${basePath}/registrar-apuracao-acao-reprocessamento` },
  recalcularApuracao: { method: 'POST', url: `${basePath}/recalcular-apuracao/{idAcao}/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);
