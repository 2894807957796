import getBasePath from '@/common/functions/api-resource';
import resourceBuilder from '@/common/functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/redirecionamento-passo-apuracao');

const workflowAcaoActions = {
  buscarPassosFluxoApuracaoAcao: { method: 'GET', url: `${basePath}/fluxo/buscar` },
  verificarSeUsuarioAprovadorNDComplementoInformacoes: { method: 'GET', url: `${basePath}/fluxo/usuario-informa-data-contabilizacao` },
  salvarApuracaoAcaoDataContabilizacao: { method: 'PUT', url: `${basePath}/fluxo/salvar-apuracao-acao-data-contabilizacao/{idApuracao}` },
  salvarApuracaoAcaoTipoUso: { method: 'PUT', url: `${basePath}/fluxo/salvar-apuracao-acao-tipo-uso/{idApuracao}` },
};

export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const iniciaFluxo = (parametros, resource) => resourceBuilder.buildPOST(resource, basePath, 'inicia-fluxo', parametros).doPost(parametros);
