import Usuario from '@/spa/usuario/CadastroUsuario';

const UsuarioFornecedorForm = () => import('@/spa/usuario/UsuarioFornecedorForm');
const UsuarioFornecedorList = () => import('@/spa/usuario/UsuarioFornecedorList');

export default {
  path: 'usuario-fornecedor',
  component: Usuario,
  children: [
    {
      path: '',
      name: 'usuario-fornecedor',
      component: UsuarioFornecedorList,
    },
    {
      path: ':id/editar',
      name: 'editarUsuarioFornecedor',
      component: UsuarioFornecedorForm,
      props: {
        somenteLeitura: false,
      },
    },
    {
      path: 'novo',
      name: 'novoUsuarioFornecedor',
      component: UsuarioFornecedorForm,
    },
    {
      path: ':id',
      name: 'verUsuarioFornecedor',
      component: UsuarioFornecedorForm,
      props: {
        somenteLeitura: true,
      },
    },
  ],
};
