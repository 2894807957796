<template lang="html">
  <v-card v-if="canAccessPage">
    <v-card-title>
      <h3 class="headline mb-0">{{ $tc('label.alerta_manutencao', 1) }}</h3>
    </v-card-title>
    <v-card-text>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="searchQuery"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="alertasManutencao"
        :options.sync="pagination"
        :server-items-length="totalPage"
        :items-per-page="5"
        class="elevation-1"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item.acoes="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                class="grey--text text--darken-1">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="verDetalhes(item)" v-if="!canCRUD">
                <v-list-item-action>
                  <v-icon>equalizer</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">{{ $t('label.mais_detalhes') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="editar(item)" v-if="canCRUD">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title class="grey--text text--darken-1">{{ $t('label.editar') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.indAtivo="{ item }">
          {{ item.indAtivo ? $t('label.sim') : $t('label.nao') }}
        </template>
      </v-data-table>

      <v-btn class="v-btn__fab"
        v-if="canCRUD"
        color="primary"
        dark fixed fab bottom right
        @click="abrirForm">
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AlertaManutencaoLista',
  data() {
    return {
      alertaManutencaoResources: this.$api.alertaManutencao(this.$resource),

      pagination: {},
      totalPage: 0,
      searchQuery: '',
      headers: [
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', width: '2%', sortable: false,
        },
        { text: this.$tc('label.mensagem', 1), value: 'mensagem', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'dataInicio', sortable: false },
        { text: this.$tc('label.data_fim', 1), value: 'dataFim', sortable: false },
        { text: this.$tc('label.ativo', 1), value: 'indAtivo', sortable: false },
      ],
      alertasManutencao: [],
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ALERTA_MANUTENCAO').length;
    },
    canCRUD() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ALERTA_MANUTENCAO').length;
    },
  },
  watch: {
    searchQuery() {
      if (this.timeoutTemp) {
        window.clearTimeout(this.timeoutTemp);
      }
      this.timeoutTemp = window.setTimeout(() => {
        this.buscarAlertasManutencao();
      }, 500);
    },
    pagination: {
      handler() {
        this.buscarAlertasManutencao();
      },
      deep: true,
    },
  },
  methods: {
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    abrirForm() {
      this.$router.push({ name: 'novoAlertaManutencao' });
    },
    buscarAlertasManutencao() {
      const params = {
        tamanhoPagina: this.pagination.itemsPerPage || 10,
        numeroPagina: this.pagination.page || 1,
        filter: this.searchQuery,
      };

      this.alertaManutencaoResources.listarFiltrado(params).then((response) => {
        this.alertasManutencao = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
      }, (err) => {
        this.$error(this, err);
      });
    },
    editar(item) {
      this.$router.push({ name: 'editarAlertaManutencao', params: { id: item.id } });
    },
    verDetalhes(item) {
      this.$router.push({ name: 'verAlertaManutencao', params: { id: item.id } });
    },
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
  mounted() {
  },
};
</script>
