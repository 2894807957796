import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'tipo-uso');

const tipoUsoActions = {
  gravar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  buscar: { method: 'GET', url: `${basePath}/{codigo}` },
  selecao: { method: 'GET', url: `${basePath}/selecao` },
};

export default (resource) => resource(`${basePath}`, {}, tipoUsoActions);
