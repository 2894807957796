<template>
  <v-row class="my-3 mx-0">
    <v-col cols="12" class="pa-0" v-for="(grupo, i) in grupos" :key="i">
      <div v-if="gruposCampos[grupo].length > 1 || gruposForcados[grupo]" class="pa-3">
        <v-row justify="space-between" class="MetadadosContainerFiltroLayout__group__header" @click="exibirGrupo(grupo)">
          <div class="subheading MetadadosContainerFiltroLayout__group__header__text">
            {{ quantidadeItens[grupo] ? `${grupo} (${quantidadeItens[grupo]})` : grupo }}
          </div>
          <v-icon :class="{ 'MetadadosContainerFiltroLayout__icon-rotate': grupoShow === grupo, 'MetadadosContainerFiltroLayout__icon': true }">keyboard_arrow_down</v-icon>
        </v-row>
        <v-expand-transition>
          <metadados-container-layout
            style="overflow-x: auto"
            v-show="grupoShow === grupo"
            :metadados-entidade="metadadosEntidade"
            :ordenacao-campos="gruposCampos[grupo]"
            :campos-formulario="camposFormulario"
            :explode-hierarquia="explodeHierarquia"
            :formulario-filtros="formularioFiltros"
            :ignora-obrigatorios="ignoraObrigatorios"
            :ignora-desabilitados="ignoraDesabilitados"
            :layout-class="layoutClass"
            :input-layout="inputLayout"
            :filtro-rapido="true"
            :objeto="objeto"
            :grupo-ativo="grupoShow === grupo"
            @MetadadosContainerLayout_valorAlterado="() => alterarQuantidadeItens(grupo, i)"
            @MetadadosContainerLayout_GrupoAtivo="(ativo) => exibirGrupo(grupo, ativo)"
            ref="container">
          </metadados-container-layout>
        </v-expand-transition>
      </div>
      <metadados-container-layout v-else
        :metadados-entidade="metadadosEntidade"
        :ordenacao-campos="gruposCampos[grupo]"
        :campos-formulario="camposFormulario"
        :explode-hierarquia="explodeHierarquia"
        :formulario-filtros="formularioFiltros"
        :ignora-obrigatorios="ignoraObrigatorios"
        :ignora-desabilitados="ignoraDesabilitados"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :filtro-rapido="true"
        :objeto="objeto"
        :grupo-ativo="grupoShow === grupo"
        @MetadadosContainerLayout_GrupoAtivo="(ativo) => exibirGrupo(grupo, ativo)"
        ref="container">
      </metadados-container-layout>
    </v-col>
  </v-row>

</template>

<script>
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';

export default {
  name: 'MetadadosContainerFiltroLayout',
  components: {
    MetadadosContainerLayout,
  },
  props: {
    objeto: Object,
    metadadosEntidade: {
      type: Object,
      default: () => ({}),
    },
    inputLayout: {
      type: Object,
      default: () => ({
        xs12: true,
        sm12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
        md12: false,
      }),
    },
    layoutClass: {
      type: Object,
      default: null,
    },
    formularioFiltros: {
      type: Boolean,
      default: false,
    },
    ignoraObrigatorios: {
      type: Boolean,
      default: false,
    },
    ignoraDesabilitados: {
      type: Boolean,
      default: false,
    },
    explodeHierarquia: {
      type: Boolean,
      default: false,
    },
    camposFormulario: {
      type: Object,
      default: () => ({ padrao: [] }),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    novoCadastro: {
      type: Boolean,
      default: false,
    },
    grupoExpandido: {
      type: String,
      default: null,
    },
    ordenacaoCampos: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      gruposCampos: {},
      gruposForcados: {},
      grupoShow: null,
      quantidadeItens: {},
    };
  },
  computed: {
    grupos() {
      return Object.keys(this.gruposCampos);
    },
  },
  watch: {
    ordenacaoCampos(val) {
      this.preencherGrupos(val);
    },
    grupoExpandido(val) {
      this.grupoShow = val;
    },
  },
  methods: {
    preencherGrupos(val) {
      this.gruposCampos = {};
      this.gruposForcados = {};
      if (val) {
        val.forEach((campo) => {
          if (this.gruposCampos[campo.nomeGrupo]) {
            this.gruposCampos[campo.nomeGrupo].push(campo.campo);
          } else {
            this.gruposCampos[campo.nomeGrupo] = [campo.campo];
          }
          if (campo.forcarGrupo) {
            this.gruposForcados[campo.nomeGrupo] = true;
          }
        });
        this.preencherQuantidadeItens();
        setTimeout(() => this.$emit('MetadadosContainerFiltroLayout__gruposCarregados'), 5E1);
      }
    },
    refresh() {
      this.grupos.forEach((grupo, i) => {
        this.$refs.container[i].refresh();
        this.alterarQuantidadeItens(grupo, i);
      });
    },
    getValoresDependencias() {
      let parametros;
      this.grupos.forEach((grupo, i) => {
        parametros = {
          ...parametros,
          ...this.$refs.container[i].getValoresDependencias(),
          ...this.$refs.container[i].getValoresCamposPadrao(),
          ...this.$refs.container[i].getValoresCamposDinamicos(),
        };
      });
      return parametros;
    },
    preencherQuantidadeItens() {
      setTimeout(() => {
        this.grupos.forEach((grupo, i) => {
          this.alterarQuantidadeItens(grupo, i);
        });
      }, 1E2);
    },
    alterarQuantidadeItens(grupo, i) {
      setTimeout(() => {
        if (this.$refs.container[i]) {
          this.quantidadeItens[grupo] = this.$refs.container[i]
            .getQuantidadeFiltrosSelecionados();
          this.$forceUpdate();
        }
      }, 1E2);
    },
    exibirGrupo(grupo, ativo) {
      if (ativo) {
        this.grupoShow = grupo;
      } else if (this.grupoShow === grupo) {
        this.grupoShow = null;
      } else {
        this.grupoShow = grupo;
      }
    },
  },
  mounted() {
    this.grupoShow = this.grupoExpandido;
    this.preencherGrupos(this.ordenacaoCampos);
  },
};
</script>

<style>
  .MetadadosContainerFiltroLayout__group__header__text {
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 1rem;
  }
  .MetadadosContainerFiltroLayout__group__header {
    align-items: center;
    cursor: pointer;
  }
  .MetadadosContainerFiltroLayout__group__header:hover {
    background: rgba(0, 0, 0, .04);
  }
  .MetadadosContainerFiltroLayout__icon {
    transition: .3s cubic-bezier(.25,.8,.5,1);
  }
  .MetadadosContainerFiltroLayout__icon-rotate {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
  }

</style>
