<template lang="html">
  <div class="ma-3">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="agendamentos"
            disable-pagination
            hide-default-footer
            class="elevation-1"
            :no-data-text="$t('label.tabela_sem_conteudo')"
          >
            <template v-slot:item.ind_unica_execucao="{ item }">
              <span>
                {{ item.ind_unica_execucao ? $t('label.sim') : $t('label.nao') }}
              </span>
            </template>
            <template v-slot:item.ind_ativo="{ item }">
              <span>
                {{ item.ind_ativo ? $t('label.sim') : $t('label.nao') }}
              </span>
            </template>
            <template v-slot:item.horario_execucao="{ item }">
              <span v-if="item.ind_horario_fixo">
                {{ item.horario_execucao }}
              </span>
            </template>
            <template v-slot:item.opcoes="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="requestLogs(item)">
                    <v-icon>assignment</v-icon>
                  </v-btn>
                </template>
                <span>{{ $tc('label.log', 2) }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="podeEditar(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="edit(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="podeReagendar(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="reagendar(item)">
                    <v-icon>access_time</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.reagendar') }}</span>
              </v-tooltip>
              <v-tooltip bottom v-if="podeReagendar(item)">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="executarTarefa(item)">
                    <v-icon>play_circle_outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.executar') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogForm" persistent max-width="1200">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <h3 class="headline mb-0">{{ $t('label.agendamento_tarefa') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <span> {{ $t('message.edicao_agendamento') }}</span>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  class="Form-text__invalid"
                  id="nome"
                  name="nome"
                  v-model="agendamento.nome"
                  :label="`${$t('label.nome')} *`"
                  :counter="50"
                  maxlength="50"
                  :rules="[rules.required]"
                  :readonly="true">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select
                  class="Form-text__invalid"
                  id="periodo"
                  name="periodo"
                  :items="periodos"
                  :label="`${$tc('label.periodo', 1)} *`"
                  v-model="agendamento.periodo"
                  :rules="[rules.required]">
                </v-select>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-text-field
                  class="Form-text__invalid"
                  id="tempo"
                  name="tempo"
                  v-model="agendamento.coeficiente_tempo"
                  :label="`${$t('label.tempo')} *`"
                  type="number"
                  min="0"
                  :rules="[rules.required]"
                  >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="2" v-if="agendamento.ind_horario_fixo">
                <v-menu
                  ref="horarioFixo"
                  :close-on-content-click="false"
                  v-model="pickerHora"
                  :nudge-right="40"
                  transition="scale-transition"
                  lazy
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :id="inputId"
                      v-on="on"
                      v-model="agendamento.horario_execucao"
                      :label="`${$t('label.horario_fixo')} *`"
                      prepend-icon="access_time"
                      readonly
                      :rules="[rules.required]"
                      clearable>
                    </v-text-field>
                  </template>

                  <v-time-picker
                    v-if="pickerHora"
                    v-model="agendamento.horario_execucao"
                    format="24hr"
                    @input="horaAlterada()">
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3" md="2">
                <v-switch
                  :label="`${$t('label.ativo')} *`"
                  v-model="agendamento.ind_ativo"
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="fechar">{{ $t('label.cancelar') }}</v-btn>
            <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>
        <confirm
          ref="confirmDialog"
          :message="$t('message.deseja_salvar', {text: $t('label.agendamento_tarefa')})"
          :persistent="true"
          @agree="salvar">
        </confirm>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogReagendar" persistent max-width="800">
      <v-form ref="formReagendar" lazy-validation>
        <v-card>
          <v-card-title>
            <h3 class="headline mb-0">{{ $t('label.reagendar') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field v-mask="'##-##-#### ##:##'"
                  v-model="dataProximaExecucao"
                  :label="$t('label.data_proxima_execucao')"
                  hint='DD/MM/AAAA HH:mm'
                  :return-masked-value="true">
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="fechar">{{ $t('label.cancelar') }}</v-btn>
            <v-btn @click.native="abrirDialogSalvarReagendar" color="primary">{{ $t('label.salvar') }}</v-btn>
          </v-card-actions>
        </v-card>
        <confirm
          ref="confirmDialogReagendar"
          :message="$t('message.deseja_salvar', {text: $t('label.agendamento_tarefa')})"
          :persistent="true"
          @agree="salvarReagendar">
        </confirm>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogLog" persistent max-width="800">
      <v-card>
        <v-card-title>
          <h3 class="headline mb-0">{{ $tc('label.log', 2) }}</h3>
        </v-card-title>
        <v-card-text>
          <v-data-table
          :headers="headersLog"
          :items="logs"
          hide-default-footer
          class="elevation-1"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="fechar">{{ $t('label.fechar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm
      ref="confirmDialogExecutar"
      :message="$t('message.confirmacao_execucao_tarefa')"
      :persistent="true"
      @agree="salvarReagendar">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import Confirm from '@/shared-components/dialog/Confirm';
import { copyObject } from '@/common/functions/helpers';

export default {
  name: 'AgendamentoTarefa',
  components: {
    Confirm,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeEditarAgendamentoFixo() {
      return this.getAllRoles
        .filter((el) => el.indexOf('EDITAR_AGENDAMENTO_HR_FIXO') !== -1).length > 0;
    },
  },
  data() {
    return {
      agendamentoResources: this.$api.agendamento(this.$resource),
      agendamentos: [],
      agendamento: {},
      dataProximaExecucao: '',
      dialogForm: false,
      dialogReagendar: false,
      dialogLog: false,
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: false },
        { text: this.$tc('label.periodo', 1), value: 'periodo', sortable: false },
        { text: this.$tc('label.data_proxima_execucao', 1), value: 'dta_proxima_execucao', sortable: false },
        { text: this.$tc('label.tempo', 1), value: 'coeficiente_tempo', sortable: false },
        { text: this.$tc('label.unica_execucao', 1), value: 'ind_unica_execucao', sortable: false },
        { text: this.$tc('label.ativo', 1), value: 'ind_ativo', sortable: false },
        { text: this.$tc('label.horario_fixo', 1), value: 'horario_execucao', sortable: false },
        { text: this.$tc('label.opcao', 2), value: 'opcoes', sortable: false },
      ],
      headersLog: [
        { text: this.$tc('label.data', 1), value: 'dta_criacao' },
        { text: this.$tc('label.status', 1), value: 'ind_status' },
        { text: this.$tc('title.erro', 1), value: 'erro' },
      ],
      logs: [],
      periodos: ['MINUTO', 'HORAL', 'DIARIO'],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
      pickerHora: false,
    };
  },
  methods: {
    requestAgendamentos() {
      this.agendamentoResources.listar().then((response) => {
        this.agendamentos = response.data;
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    edit(item) {
      this.agendamento = copyObject(item);
      this.dialogForm = true;
    },
    reagendar(item) {
      this.agendamento = copyObject(item);
      this.dataProximaExecucao = this.moment(item.dta_proxima_execucao, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm');
      this.dialogReagendar = true;
    },
    executarTarefa(item) {
      this.agendamento = copyObject(item);
      this.dataProximaExecucao = this.moment().add(1, 'm').format('DD-MM-YYYY HH:mm');
      this.$refs.confirmDialogExecutar.open();
    },
    fechar() {
      this.dialogForm = false;
      this.dialogReagendar = false;
      this.dialogLog = false;
      this.requestAgendamentos();
    },
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    abrirDialogSalvarReagendar() {
      if (!this.$refs.formReagendar.validate()) return;
      this.$refs.confirmDialogReagendar.open();
    },
    salvar() {
      this.agendamentoResources.atualizar(this.agendamento).then(() => {
        this.$toast(this.$t('message.atualizado_confira_tabela'));
        this.requestAgendamentos();
        this.fechar();
      }, (err) => {
        this.$error(this, err);
      });
    },
    salvarReagendar() {
      this.agendamento.dta_proxima_execucao = this.dataProximaExecucao;
      this.agendamentoResources.reagendar(this.agendamento).then(() => {
        this.$toast(this.$t('message.atualizado_confira_tabela'));
        this.requestAgendamentos();
        this.fechar();
      }, (err) => {
        this.$error(this, err);
      });
    },
    requestLogs(item) {
      this.agendamentoResources.listarLogs({ id: item.id }).then((response) => {
        this.logs = response.data;
        this.dialogLog = true;
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    podeReagendar(item) {
      return item.ind_ativo && (!item.ind_horario_fixo || (item.ind_horario_fixo && this.podeEditarAgendamentoFixo));
    },
    podeEditar(item) {
      return !item.ind_horario_fixo || (item.ind_horario_fixo && this.podeEditarAgendamentoFixo);
    },
    horaAlterada() {
      this.pickerHora = false;
    },
  },
  mounted() {
    this.requestAgendamentos();
  },
};
</script>
