import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'notas/processamento');

const notasActions = {
  reprocessarInconsistencia: { method: 'GET', url: `${basePath}/reprocessar` },
  reprocessarGeral: { method: 'POST', url: `${basePath}/geral` },
};

export default (resource) => resource(`${basePath}`, {}, notasActions);
