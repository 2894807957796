<template lang="html">
  <div>
    <textarea :id="id" :name="id" rows="10" cols="30" style="height:440px" aria-label="editor">
        {{ texto }}
    </textarea>
  </div>
</template>

<script>
import JSZip from 'jszip';

export default {
  props: {
    id: { type: String, default: 'editor' },
    texto: { type: String, default: '' },
  },
  data() {
    return {
      editor: undefined,
    };
  },
  methods: {
    criar() {
      this.editor = $(`#${this.id}`).kendoEditor();
    },
    startKendoUI() {
      window.kendo.culture('pt-BR');
      this.criar();
    },
    getTexto() {
      return this.editor.data('kendoEditor').value();
    },
    setTexto(valor) {
      if (this.editor) {
        this.editor.data('kendoEditor').value(valor);
      }
    },
  },
  mounted() {
    window.JSZip = JSZip;
    if (window.kendo) {
      this.startKendoUI();
      return;
    }

    $.getScript('static/js/kendoui/kendoui.js', () => {
      this.startKendoUI();
    });
  },
};
</script>

<style src="../../assets/vendors/kendoui/kendo.common.min.css"></style>
<style src="../../assets/vendors/kendoui/kendo.default.min.css"></style>
<style src="../../assets/vendors/kendoui/kendo.default.mobile.min.css"></style>
