import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'configuracao-sistema');

const configSistema = {
  buscarConfiguracoes: { method: 'GET', url: `${basePath}` },
  buscarChave: { method: 'GET', url: `${basePath}/{chave}` },
  atualizarConfiguracao: { method: 'PUT', url: `${basePath}/atualizar/chave/{chave}/valor/{valor}` },
};

export default (resource) => resource(`${basePath}`, {}, configSistema);
