import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('apuracao_acao', 'edicao');

const apuracaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  cancelar: { method: 'PUT', url: `${basePath}/cancelar` },
  atualizarNdPrevia: { method: 'PUT', url: `${basePath}/atualizar-nd-previa` },
  atualizarFornecedorResumo: { method: 'PUT', url: `${basePath}/atualizar-fornecedor-resumo/{idAcao}/` },
  recalcularFornecedores: { method: 'POST', url: `${basePath}/recalcular-fornecedores/{idAcao}` },
  salvarIdAcaoFornecedor: { method: 'POST', url: `${basePath}/salvar-id-acao-fornecedor/{idAcao}/{idAcaoFornecedor}` },
  salvarMultiplasNds: { method: 'PUT', url: `${basePath}/{idNotaDebitoPrevia}/salvar-multiplas-nds` },
  gerarNumeroNdMultiplasNds: { method: 'PUT', url: `${basePath}/gerar-numero-nd-multiplas-nds` },
};

export default (resource) => resource(`${basePath}`, {}, apuracaoActions);

export const cancelarApuracao = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'cancelar-apuracao', parametros).doPut(parametros);
