<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="card-size">
      <v-card-title>
        <h2 style="font-size: 18px; width: 100%" class="mb-0">{{ divisao.ehEdicao() ? $t('title.editar_divisao') : $t('title.cadastrar_nova_divisao') }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="codDivisao"
              name="codDivisao"
              v-model="divisao.idExterno"
              required
              :label="`${$t('label.cod_divisao')} *`"
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="Form-text__invalid"
              id="nome"
              name="nome"
              v-model="divisao.nome"
              :label="`${$t('label.nome')} *`"
              :counter="50"
              maxlength="50"
              required
              :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="estruturaUsuarioDaDivisao"
              name="estruturaUsuarioDaDivisao"
              v-model="divisao.estruturaUsuario"
              :disabled="divisao.possuiDivisoesSuperiores()"
              :items="listaEstruturaUsuarios"
              :search-input.sync="buscaListaEstruturaUsuario"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomEstruturaUsuario"
              item-value="id"
              :rules="[rules.requiredSelect]"
              :label="`${$tc('label.estrutura_usuario', 1)} *`"
              clearable
              @input="onEstruturaUsuarioSelecionada"
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="divisoesSuperiores"
              name="divisoesSuperiores"
              v-model="divisao.divisoesSuperiores"
              :disabled="isDivisoesSuperioresDisabled()"
              :items="listaDivisoes"
              :search-input.sync="buscaListaDivisoesSuperiores"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nome"
              item-value="id"
              :label="`${$tc('label.divisao_superior', 1)}`"
              multiple
              return-object>
              <template
                v-slot:selection="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  class="chip--select-multi"
                  @click:close="removeChipSelecao(data.item)">
                  {{ data.item.nome }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <metadados-container-layout
          :metadados-entidade="metadadosDivisao"
          :objeto="divisao"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          v-if="metadadosDivisao"
          ref="container">
        </metadados-container-layout>

        <v-row>
          <v-col cols="6">
            <v-switch :label="$t('label.diretoria')" color="primary" v-model="divisao.desAtributos.diretoria" class="mt-3"></v-switch>
          </v-col>
          <v-col cols="6">
            <v-switch :label="$t('label.divisao_responsavel')" color="primary" v-model="divisao.desAtributos.divisaoResponsavel" class="mt-3"></v-switch>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancel">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '@/shared-components/dialog/Confirm';
import MetadadosContainerLayout from '@/shared-components/metadados/MetadadosContainerLayout';
import Divisao from './Divisao';

export default {
  name: 'DivisaoForm',
  data() {
    return {
      buscaListaEstruturaUsuario: null,
      buscaListaDivisoesSuperiores: null,
      divisao: new Divisao(),
      divisaoResources: this.$api.divisao(this.$resource),
      estruturaUsuarioResources: this.$api.estruturaUsuario(this.$resource),
      inputLayout: {
        xs12: true,
        sm12: true,
        md12: true,
        sm6: false,
        md3: false,
        md4: false,
        md6: false,
        md9: false,
      },
      layoutClass: { wrap: true },
      listaDivisoes: [],
      listaEstruturaUsuarios: [],
      vlrSelecaoEstrutura: {},
      dialog: {
        content: '',
      },
      rules: {
        required: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredSelect: (value) => (!!value && !!value.id) || this.$t('message.campo_obrigatorio'),
        requiredMultiple: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  watch: {
    buscaListaDivisoesSuperiores(val) {
      if (this.timeoutDivisao) {
        window.clearTimeout(this.timeoutDivisao);
      }
      this.timeoutDivisao = window.setTimeout(() => {
        if (val != null) this.buscarDivisoesPorEstruturaDeUsuario(val);
      }, 500);
    },
    buscaListaEstruturaUsuario(val) {
      if (this.divisao.estruturaUsuario && this.divisao.estruturaUsuario.nomEstruturaUsuario) {
        const selecao = this.divisao.estruturaUsuario.nomEstruturaUsuario;
        if (selecao === val) {
          return;
        }
      }

      if (this.timeoutEstrutura) {
        window.clearTimeout(this.timeoutEstrutura);
      }
      this.timeoutEstrutura = window.setTimeout(() => {
        if (val != null) this.buscarEstruturasDeUsuario(val);
      }, 500);
    },
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
  },
  computed: {
    ...mapGetters('metadados', [
      'getDivisaoMetadado',
    ]),
    metadadosDivisao() {
      if (this.getDivisaoMetadado && this.getDivisaoMetadado.mapaEntidades) {
        return this.getDivisaoMetadado;
      }
      return undefined;
    },
  },
  methods: {
    buscarDivisoesPorEstruturaDeUsuario(nome) {
      const filtro = {
        idEstruturaUsuario: this.divisao.estruturaUsuario.id,
        nome,
      };
      return this.divisaoResources.pesquisasrDivisoesSuperiores(filtro)
        .then((resultado) => {
          this.listaDivisoes = resultado.data.resposta;
          const selecao = this.divisao.divisoesSuperiores;
          this.listaDivisoes = [...selecao, ...this.listaDivisoes];
        });
    },
    buscarEstruturasDeUsuario(nomEstruturaUsuario) {
      const param = {
        autocomplete: nomEstruturaUsuario,
      };
      return this.estruturaUsuarioResources.selecao(param)
        .then((resultado) => {
          this.listaEstruturaUsuarios = resultado.data;
        });
    },
    cancel() {
      this.limparFormulario();
      this.$emit('FORMULARIO_CADASTRO_DIVISAO_CANCELAR');
    },
    editar(divisao) {
      this.divisao = new Divisao({ ...divisao });
      this.divisao.montarEstruturaDinamica(divisao, this.metadadosDivisao);
    },
    limparFormulario() {
      this.divisao = new Divisao();
      this.$refs.form.reset();
    },
    closeDialog(ref, cb) {
      this.$refs[ref].close();
      this.$refs[ref].active = false;
      if (cb) {
        cb();
      }
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.dialog.content = this.$t('message.salvar_nova_divisao');
      this.$refs.confirmDialog.open();
    },
    onEstruturaUsuarioSelecionada(estruturaUsuarioSelecionada) {
      if (!estruturaUsuarioSelecionada) {
        if (this.divisao && this.divisao.estruturaUsuario) {
          this.divisao.estruturaUsuario = null;
        }
        this.buscarEstruturasDeUsuario();
        return;
      }
      this.buscarDivisoesPorEstruturaDeUsuario();
    },
    isDivisoesSuperioresDisabled() {
      if (this.divisao && this.divisao.estruturaUsuario) {
        return !this.divisao.estruturaUsuario.id;
      }
      return true;
    },
    removeChipSelecao(item) {
      const selecao = this.divisao.divisoesSuperiores;
      const index = selecao.map((s) => s.id).indexOf(item.id);
      if (index >= 0) selecao.splice(index, 1);
    },
    save() {
      const objArmazenamento = { ...this.divisao };
      objArmazenamento.dependenciasMetadados = this.$refs.container.getValoresDependencias();

      let salvarOuAtualizar;
      if (this.divisao.id != null) {
        salvarOuAtualizar = this.divisaoResources.atualizarDivisao({ id: this.divisao.id },
          objArmazenamento);
      } else {
        salvarOuAtualizar = this.divisaoResources.gravar(objArmazenamento);
      }

      salvarOuAtualizar.then(() => this.limparFormulario())
        .then(() => this.$toast(this.$t('message.adicionado_confira_tabela')))
        .then(() => {
          this.$emit('FORMULARIO_CADASTRO_DIVISAO_SALVAR');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.buscarEstruturasDeUsuario();
  },
};
</script>
