<template>
  <v-dialog v-model="showModal" :max-width="'800px'">
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <div>
            <h3 class="headline mb-0">{{ textoAcao }}</h3>
          </div>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" v-if="isAprovacao && podeAlterarDataContabilizacao">
              <v-menu
                ref="data_contabilizacao"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    id="data_contabilizacao"
                    class="CalendarioForm-data__text"
                    readonly
                    clearable
                    :rules="[rules.required]"
                    v-model="dataContabilizacaoFormatada"
                    :label="`${$t('label.data_contabilizacao')} *`"
                    prepend-icon="event">
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  color="primary"
                  scrollable
                  @input="setaDataFormatada()"
                  v-model="dataContabilizacao">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-textarea
                id="Acaofluxo_justificativa"
                index="1"
                ref="textareaJustificativa"
                name="Acaofluxo_justificativa"
                v-model="justificativa"
                auto-grow
                autofocus
                rows="1"
                :rules="[required]"
                :disabled="bloquearCampos"
                :label="validarJustificativa ? `${$t('label.justificativa')} *` : $t('label.justificativa')"
                :counter="500"
                maxlength="500">
              </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-if="podeAlterarTipoDeUso"
                id="justificativa-tipo-uso"
                class="custom-autocomplete"
                v-model="tipoUso"
                :rules="[rules.required]"
                :items="listaTiposUso"
                :label="`${$tc('label.tipo_uso', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="nome"
                item-value="id"
                required
                :disabled="bloquearCampos"
                :clearable="true"
                @click:append="() => triggerSelecao('justificativa-tipo-uso')"
                @click.native="(i) => buscaAutocomplete(i, buscarTiposUso)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar" :disabled="bloquearCampos">{{ $t('label.cancelar') }}</v-btn>
          <v-btn
            color="primary darken-1"
            :disabled="bloquearCampos"
            @click.native="executarAcao"
          >{{ textoAcao }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ListFiles from '@/shared-components/files/ListFiles';
import { getMoney } from '@/common/functions/helpers';
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/common/functions/autocomplete-utils';
import { forceLoading, forceHideLoading } from '@/common/functions/loading';

export default {
  name: 'ApuracaoFluxo',
  props: {
    apuracao: {
      type: Object,
      default: null,
    },
    corIcone: {
      type: String,
      default: null,
    },
    icone: {
      type: String,
      default: '',
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    validarJustificativa: {
      type: Boolean,
      default: false,
    },
    textoAcao: {
      type: String,
      default: '',
    },
    idAcao: {
      type: Number,
      default: null,
    },
    idApuracao: {
      type: Number,
      default: null,
    },
    isAprovacao: {
      type: Boolean,
      default: false,
    },
    salvarJustificativa: Function,
  },
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      workflowApuracaoAcaoMagalu: this.$api.workflowApuracaoAcaoMagalu(this.$resource),

      fornecedoresNd: [],
      showModal: false,
      valid: false,
      bloquearCampos: false,
      justificativa: '',
      exibirPerguntaProximo: false,
      tipoUso: null,
      prazoPagamento: null,
      listaTiposUso: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      usuarioAprovadorNDComplementoInformacoes: false,
      dataContabilizacaoFormatada: null,
      dataContabilizacao: new Date().toJSON().substr(0, 10),

      listaDocumentosGerados: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: this.$tc('label.nome_arquivo', 1), value: 'nomeArquivo', width: '100%', align: 'center', sortable: false,
        },
      ],
    };
  },
  components: {
    ListFiles,
  },
  watch: {
    showModal(val) {
      if (val) {
        setTimeout(() => this.$refs.textareaJustificativa.focus(), 5E2);
      }
    },
    idApuracao() {
      if (this.idApuracao) {
        this.verificaSeUsuarioAprovadorNDComplementoInformacoes();
      }
    },
  },
  computed: {
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    podeAlterarTipoDeUso() {
      return this.usuarioAprovadorNDComplementoInformacoes && !this.validarJustificativa;
    },
    podeAlterarDataContabilizacao() {
      return this.usuarioAprovadorNDComplementoInformacoes;
    },
  },
  methods: {
    getMoney,
    buscaAutocomplete,
    triggerSelecao,
    open() {
      this.justificativa = '';
      this.showModal = true;
    },
    required(v) {
      return (!this.validarJustificativa || !!v) || this.$t('label.campo_obrigatorio');
    },
    cancelar() {
      this.fechar();
    },
    fechar() {
      this.showModal = false;
      this.justificativa = '';
      this.exibirPerguntaProximo = false;
      this.desbloquearCampos();
    },
    naoIrProximo() {
      this.$emit('ApuracaoFluxo_acaoExecutada', this.justificativa, false);
      this.fechar();
    },
    irProximo() {
      this.$emit('ApuracaoFluxo_acaoExecutada', this.justificativa, true);
      this.fechar();
    },
    acaoExecutada() {
      this.exibirPerguntaProximo = true;
    },
    desbloquearCampos() {
      this.bloquearCampos = false;
    },
    executarAcao() {
      if (!this.$refs.form.validate()) return;
      forceLoading();
      this.bloquearCampos = true;

      const promises = [];
      promises.push(this.salvarTipoUso());
      if (this.isAprovacao) {
        promises.push(this.salvarDataContabilizacao());
      }
      Promise.all(promises)
        .then(() => {
          this.salvarJustificativa(this.justificativa);
          forceHideLoading();
          if (this.perguntarProximo) {
            this.acaoExecutada();
          } else {
            this.naoIrProximo();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarTipoUsoDaAcao() {
      const { idAcao } = this;
      this.workflowAcaoMagaluResource
        .carregarTipoUso({ idAcao })
        .then((res) => {
          this.tipoUso = { id: res.data.id, nome: res.data.nome };
          const tipoUso = this.listaTiposUso.find((tipo) => tipo.nome === this.tipoUso.nome);
          if (tipoUso) {
            tipoUso.id = this.tipoUso.id;
            this.fornecedoresNd.forEach((fornecedor) => {
              fornecedor.idTipoUso = this.tipoUso.id;
            });
          }
        })
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => res)
        .catch(() => {
          this.$toast(this.$tc('errors.acao.erro_carregar_dados'));
        });
    },
    salvarTipoUso() {
      if (this.tipoUso) {
        const { idApuracao } = this;
        const { tipoUso } = this;
        const tiposUso = this.fornecedoresNd.map((nd) => ({
          idTipoUso: tipoUso.id,
          idNotaDebitoPrevia: nd.idNotaDebitoPrevia,
        }));
        this.workflowApuracaoAcaoMagalu.salvarApuracaoAcaoTipoUso({ idApuracao }, tiposUso)
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    verificaSeUsuarioAprovadorNDComplementoInformacoes() {
      const params = {
        idApuracao: this.idApuracao,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowApuracaoAcaoMagalu
        .verificarSeUsuarioAprovadorNDComplementoInformacoes(params)
        .then((res) => {
          this.usuarioAprovadorNDComplementoInformacoes = res.data;
          if (this.podeAlterarDataContabilizacao) {
            this.setaDataFormatada();
          }
          if (this.usuarioAprovadorNDComplementoInformacoes) {
            Promise.all([this.buscarTiposUso(), this.buscarFornecedoresNd()])
              .then((response) => {
                const [retorno] = response;
                this.listaTiposUso = retorno.data;
                this.carregarTipoUsoDaAcao();
              });
          } else {
            this.buscarFornecedoresNd();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    salvarDataContabilizacao() {
      if (this.podeAlterarDataContabilizacao && this.dataContabilizacao) {
        const { idApuracao } = this,
          { dataContabilizacao } = this;
        this.workflowApuracaoAcaoMagalu.salvarApuracaoAcaoDataContabilizacao({ idApuracao },
          { data_contabilizacao: dataContabilizacao })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    setaDataFormatada() {
      this.dataContabilizacaoFormatada = this.retornaDataFormatada(this.dataContabilizacao);
    },
    retornaDataFormatada(data) {
      if (!data) return null;
      return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    buscarFornecedoresNd() {
      return this.apuracaoListagemResource.buscarFornecedoresNdFluxo({ idApuracao: this.idApuracao })
        .then((res) => {
          this.fornecedoresNd = res.data;
        });
    },
  },
  mounted() {
    if (this.idApuracao) {
      this.verificaSeUsuarioAprovadorNDComplementoInformacoes();
    }
  },
};
</script>
