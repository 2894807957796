import getBasePath from '../functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'cadastro');

const planejamentoAcaoActions = {
  salvarAcao: { method: 'POST', url: `${basePath}` },
  atualizarAcao: { method: 'PUT', url: `${basePath}` },
  concluirAcao: { method: 'POST', url: `${basePath}/concluir_acao` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/executar_analise` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  salvarNovoBudgetId: { method: 'POST', url: `${basePath}/salvar-novo-budget-id/{codBudgetId}` },
  salvarAcaoVerbaVariavel: { method: 'POST', url: `${basePath}/acao-verba-variavel` },
  atualizarAcaoVerbaVariavel: { method: 'PUT', url: `${basePath}/acao-verba-variavel` },
  buscarTiposAcao: { method: 'GET', url: `${basePath}/campos/tipo_acao?tamanhoPagina={size}&numeroPagina={page}` },
  buscarDivisoes: { method: 'GET', url: `${basePath}/campos/divisao` },
  buscarDivisoesPorVisao: { method: 'GET', url: `${basePath}/campos/divisao/visao` },
  buscarUnidadesNegocioDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/unidade_negocio` },
  buscarExtensaoDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/extensao` },
  buscarUnidadesNegocioEntidadesControlador: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio` },
  buscarUnidadesNegocioPorVisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio/visao` },
  buscarPeriodosPlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/todos` },
  buscarPeriodosVigentePlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/vigente` },
  buscarGruposFornecedores: { method: 'GET', url: `${basePath}/campos/grupo_fornecedor` },
  buscarFornecedores: { method: 'GET', url: `${basePath}/campos/fornecedor` },
  buscarFornecedoresPagadores: { method: 'GET', url: `${basePath}/campos/fornecedor/pagador` },
  buscarStatus: { method: 'GET', url: `${basePath}/campos/buscarStatus` },
  buscarCategoria: { method: 'GET', url: `${basePath}/campos/categoria` },
  buscarFamilia: { method: 'GET', url: `${basePath}/campos/familia{?ids_categorias*}` },
  buscarFamiliaEpoca: { method: 'GET', url: `${basePath}/campos/familia-epoca{?ids_categorias*}` },
  buscarProdutoskit: { method: 'GET', url: `${basePath}/campos/produtosKit` },
  buscarContratos: { method: 'GET', url: `${basePath}/campos/contratos` },
  buscarBudgetIds: { method: 'GET', url: `${basePath}/campos/budget-id` },
  buscarValoresRateio: { method: 'GET', url: `${basePath}/campos/buscar-valores-rateio/{idAcao}` },
  buscarFornecedoresVinculoCampanha: { method: 'GET', url: `${basePath}/campos/fornecedores-vinculo-campanha` },
  buscaCampanhasAds: { method: 'GET', url: `${basePath}/campos/campanha` },
};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarProdutosLinhaProduto = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/produto/acao-verba-variavel', parametros).doGet();
