<template>
  <v-row justify="end">

    <v-tooltip left v-if="mostrarAcompanhamento">
      <template v-slot:activator="{ on }">
        <v-btn text icon
              v-on="on"
              @click.native="abrirAcompanhamento">
          <v-icon >assignment_turned_in</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.acompanhamento') }}</span>
    </v-tooltip>

    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn text icon
              v-on="on"
              @click.native="abrirModalGerarNd">
          <v-icon >paid</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.ver_nota_debito') }}</span>
    </v-tooltip>

    <popover-confirmacao
      v-if="podeSolicitarAprovacao"
      :message="$tc('message.deseja_solicitar_aprovacao_apuracao', 1)"
      @agree="solicitarAprovacao"
      icone="send"
      :textoAcao="$t('label.solicitar_aprovacao')">
    </popover-confirmacao>

    <apuracao-fluxo
      ref="acaoAprovar"
      icone="thumb_up"
      :id-acao="idAcao"
      :texto-acao="$t('label.aprovar')"
      :status="status"
      :perguntar-proximo="perguntarProximo"
      :id-apuracao="getIdApuracao()"
      v-if="podeAprovar"
      :is-aprovacao="true"
      :is-modalidade-valor-fixo="isModalidadeValorFixo"
      @AcaoFluxo_executarAcao="aprovar">
    </apuracao-fluxo>

    <apuracao-fluxo
      ref="acaoEmAnalise"
      icone="announcement"
      :texto-acao="$t('label.solicitar_analise')"
      :status="status"
      :perguntar-proximo="perguntarProximo"
      :id-acao="idAcao"
      :id-apuracao="getIdApuracao()"
      :validar-justificativa="true"
      v-if="podeAprovar"
      @AcaoFluxo_executarAcao="enviarAnalise">
    </apuracao-fluxo>

    <apuracao-fluxo
      ref="acaoReprovar"
      icone="thumb_down"
      :texto-acao="$t('label.reprovar')"
      :status="status"
      :id-acao="idAcao"
      :perguntar-proximo="perguntarProximo"
      :id-apuracao="getIdApuracao()"
      :validar-justificativa="true"
      v-if="podeAprovar"
      @AcaoFluxo_executarAcao="reprovar">
    </apuracao-fluxo>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'APURACAO'"/>

    <modal-redirecionamento
      ref="modalRedirecionamento"
      @INICIAR_FLUXO="iniciarFluxo">
    </modal-redirecionamento>

    <apuracao-acao-lista-modal-gerar-nd
      ref="modalGerarNd"
      v-if="acaoModal"
      v-model="acaoModal"
      :abrir-modal="abrirModalND"
      @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND">
    </apuracao-acao-lista-modal-gerar-nd>

  </v-row>
</template>

<script>
import { generateComputed } from '@/common/functions/roles-computed-generator';
import { iniciaFluxo } from '@/common/resources/workflow-apuracao-acao-redirecionamento';
import Confirm from '@/shared-components/dialog/Confirm';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import PopoverConfirmacao from '@/shared-components/PopoverConfirmacao';
import ModalRedirecionamento from '@/shared-components/workflow/ModalRedirecionamento';
import ApuracaoFluxo from '@/shared-components/ApuracaoFluxo';
import ApuracaoAcaoListaModalGerarNd from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNd';

export default {
  name: 'ApuracaoAcaoFluxo',
  props: {
    idAcao: Number,
    idApuracao: Number,
    status: String,
    vlrConfApuracao: Number,
    acao: Object,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    exibirSolicitarAprovacao: {
      type: Boolean,
      default: true,
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    salvarAntesSolicitarAprovacao: {
      type: Boolean,
      default: false,
    },
    isModalidadeValorFixo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ApuracaoFluxo,
    PopoverConfirmacao,
    AcaoFluxo,
    Confirm,
    Acompanhamento,
    ModalRedirecionamento,
    ApuracaoAcaoListaModalGerarNd,
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      workflowApuracaoAcaoMagaluResource: this.$api.workflowApuracaoAcaoMagalu(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      solicitanteFluxo: false,
      aprovadorFluxo: false,
      statusPassos: {},
      abrirModalND: false,
      acaoModal: null,
    };
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    podeSolicitarAprovacao() {
      return this.canEdit && this.getVlrConfApuracao()
          && (this.getStatusApuracao() === 'AGUARDANDO_APURACAO' || this.getStatusApuracao() === 'EM_ANALISE')
          && this.solicitanteFluxo && this.exibirSolicitarAprovacao;
    },
    podeAprovar() {
      return this.getStatusApuracao() === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && this.getStatusApuracao() !== 'AGUARDANDO_APURACAO'
          && this.getStatusApuracao() !== 'APURACAO_PREVIA' && this.getStatusApuracao() !== 'CANCELADO';
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.getIdApuracao() && this.getStatusApuracao()) {
        this.verificarUsuarioSolicitante(this.getIdApuracao(), this.getStatusApuracao());
        this.verificarUsuarioAprovador(this.getIdApuracao(), this.getStatusApuracao());
      }
    },
    verificarUsuarioSolicitante(idApuracao, status) {
      if (status === 'AGUARDANDO_APURACAO' || status === 'EM_ANALISE') {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    solicitarAprovacao() {
      if (!this.salvarAntesSolicitarAprovacao) {
        this.abreModalRedirecionamento(this.idApuracao);
        return;
      }
      const apu = {};
      apu.valorRealizado = this.getValorRealizado();
      apu.vlrConfApuracao = this.getVlrConfApuracao();
      apu.id = this.getIdApuracao();
      this.apuracaoAcaoResource.atualizar(apu)
        .then(() => {
          this.abreModalRedirecionamento(apu.id);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abreModalRedirecionamento(idApuracao = null) {
      this.workflowApuracaoAcaoMagaluResource.buscarPassosFluxoApuracaoAcao()
        .then((res) => {
          this.fluxo = res.data;
          this.$refs.modalRedirecionamento.fluxo = this.fluxo;
          this.$refs.modalRedirecionamento.idApuracao = idApuracao;
          this.$refs.modalRedirecionamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    iniciarFluxo(dados = null) {
      const params = {
        idApuracao: dados.idApuracao,
      };
      const { fluxo } = dados;

      if (fluxo != null) {
        const idsPassosDesativados = [];
        fluxo.passosFluxo.forEach((passo) => {
          if (!passo.indAtivo) {
            idsPassosDesativados.push(passo.id);
          }
        });

        if (idsPassosDesativados.length === fluxo.passosFluxo.length) {
          this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
          return;
        }

        params.idsPassosDesativados = idsPassosDesativados;
      }

      iniciaFluxo(params, this.$resource)
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.$refs.modalRedirecionamento.fechar();
        })
        .catch((err) => {
          this.$error(this, err);
          this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        });
    },
    verificarUsuarioAprovador(idApuracao, status) {
      if (status === 'AGUARDANDO_APROVACAO') {
        this.workflowApuracaoAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
            this.$forceUpdate();
          });
      }
    },
    reprovar(justificativa, irProximo) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.reprovar({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa, irProximo) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.aprovarPasso({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.verificarUsuarioAprovador(this.getIdApuracao(), this.getStatusApuracao());
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa, irProximo) {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource.enviarAnalise({ idApuracao },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.apuracao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    irProximaPagina(irProximo) {
      if (irProximo) {
        this.$emit('ApuracaoAcaoFluxo_irProximo');
      }
    },
    abrirAcompanhamento() {
      const idApuracao = this.getIdApuracao();
      this.workflowApuracaoAcaoResource
        .existeFluxoCadastrado({ idApuracao })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.apuracao_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idApuracao);
        });
    },
    exibeModalAcompanhamento(idApuracao) {
      this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
        .then((res) => {
          if (res.data.passos
              && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;

          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    getIdApuracao() {
      return this.idApuracao ? this.idApuracao : this.acao.idApuracao;
    },
    getIdAcao() {
      return this.idAcao ? this.idAcao : this.acao.idAcao;
    },
    getStatusApuracao() {
      return this.status ? this.status : this.acao.statusApuracao;
    },
    getVlrConfApuracao() {
      if (this.vlrConfApuracao) {
        return this.vlrConfApuracao;
      }
      if (this.acao && this.acao.vlrConfApuracao) {
        return this.acao.vlrConfApuracao;
      }
      return 0;
    },
    getValorRealizado() {
      return this.acao.valorRealizado ? this.acao.valorRealizado : 0.0;
    },
    abrirModalGerarNd() {
      this.acaoModal = this.acao;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
  },
};
</script>
