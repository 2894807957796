import moment from 'moment';

moment.updateLocale('pt', {
  months: [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
    'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ],
});

export const formatDateDDMMYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const formatDateDDMMYYYYBarras = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const formatDateMMYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('MM-YYYY');
};

export const formatDateMonthYYYY = (date) => {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('MMMM/YYYY');
};

export const parseDateYYYYMMDD = (date) => {
  if (!date || date.length !== 10) return null;
  const retorno = moment(date, 'DD-MM-YYYY');
  if (retorno.isValid()) return retorno.format('YYYY-MM-DD');
  return null;
};

export const parseDateYYYYMMDDBarras = (date) => {
  if (!date || date.length !== 10) return null;
  const retorno = moment(date, 'DD/MM/YYYY');
  if (retorno.isValid()) return retorno.format('YYYY-MM-DD');
  return null;
};

export const formatlastDayOfTheMonth = (date, format = 'DD-MM-YYYY') => {
  if (date === null || date === undefined) {
    return null;
  }
  return moment(date, 'MM-YYYY').endOf('month').format(format);
};

export const formatFirstDayOfTheMonth = (date, format = 'DD-MM-YYYY') => {
  if (date === null || date === undefined) {
    return null;
  }
  return moment(date, 'MM-YYYY').startOf('month').format(format);
};

export const validarIntervalosIguais = (dtaInicio, dtaFim, dtaInicio2, dtaFim2) => {
  const momentInicio = moment(dtaInicio);
  const momentFim = moment(dtaFim);
  const momentInicio2 = moment(dtaInicio2);
  const momentFim2 = moment(dtaFim2);
  return (momentInicio.isSame(momentInicio2)
      && momentFim.isSame(momentFim2));
};
