import { render, staticRenderFns } from "./CalendarioCard.vue?vue&type=template&id=8a6d08fc"
import script from "./CalendarioCard.vue?vue&type=script&lang=js"
export * from "./CalendarioCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports