import getBasePath from '@/common/functions/api-resource';
import genericActions from './generic-resource';

const basePath = getBasePath('api', 'divisao');

const divisaoActions = {
  ...genericActions(basePath),
  autocomplete: { method: 'GET', url: `${basePath}/autocomplete?{&id*}` },
  arvore: { method: 'GET', url: `${basePath}/arvore?filtro={filtro}&{&idsFilhosNotIn*}` },
  atualizarDivisao: { method: 'PUT', url: `${basePath}/{id}` },
  hierarquia: { method: 'GET', url: `${basePath}/hierarquia` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&{&camposDinamicos*}` },
  pesquisasrDivisoesSuperiores: { method: 'GET', url: `${basePath}/pai/hierarquia/{idEstruturaUsuario}` },
  listarFiltrado: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}&filtro={filter}` },
  buscarDivisoesPorVisao: { method: 'GET', url: `${basePath}/visao` },
};

export default (resource) => resource(`${basePath}`, {}, divisaoActions);
