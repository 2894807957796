<template>
  <v-card style="margin-bottom:1em" class="RelatorioNotasNaoProcessadas">
    <v-form ref="form" autocomplete="off">
      <v-card-title>
        <div class="title">{{ $tc('title.nds_retidas_camada_integracao', 2) }}</div>
        <v-spacer></v-spacer>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <v-row>
          <v-col cols="12">
            <v-btn @click="filtrar" color="primary" class="ml-3">
              {{$t('label.pesquisar')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="RelatorioNotasNaoProcessadasTabela"
              :headers="headers"
              :items="notasNaoProcessadasPaginada"
              :no-data-text="$t('label.tabela_sem_conteudo')"
              hide-default-footer
              disable-pagination
              :items-per-page="-1">
              <template v-slot:item.procDate="{ item }">
                <div style="white-space: nowrap">
                  {{ item.procDate != null ? new Date(item.procDate).toLocaleDateString() : null }}
                </div>
              </template>
              <template v-slot:item.accountingDate="{ item }">
                <div style="white-space: nowrap">
                  {{ item.accountingDate != null ? new Date(item.accountingDate).toLocaleDateString() : null }}
                </div>
              </template>
              <template v-slot:item.emissionDate="{ item }">
                <div style="white-space: nowrap">
                  {{ item.emissionDate != null ? new Date(item.emissionDate).toLocaleDateString() : null }}
                </div>
              </template>
              <template v-slot:item.dueDate="{ item }">
                <div style="white-space: nowrap">
                  {{ item.dueDate != null ? new Date(item.dueDate).toLocaleDateString() : null }}
                </div>
              </template>
               <template v-slot:item.valueDebitNote="{ item }">
                <div style="white-space: nowrap">
                  {{ getMoney(item.valueDebitNote) }}
                </div>
              </template>
            </v-data-table>
            <v-pagination
              v-if="pagination.totalPage >= pagination.rowsPerPage"
              v-model="pagination.page"
              :length="pages"
              :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
              class="py-2"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>
<script>
import { copyObject, getMoney } from '@/common/functions/helpers';

export default {
  name: 'RelatorioNotasNaoProcessadas',
  components: {
  },
  data() {
    return {
      magaluRelatoriosResource: this.$api.magaluRelatorios(this.$resource),

      notasNaoProcessadas: [],
      pagination: {
        page: 1,
        totalPage: 0,
        rowsPerPage: 10,
      },

      lastPagination: {},

      mostrarFiltros: false,
      filtrosFixados: false,

      headers: [
        {
          text: this.$tc('label.numero_nd', 1), width: '4%', value: 'numberTitle', sortable: 'false',
        },
        {
          text: this.$tc('label.status', 1), width: '4%', value: 'status', sortable: 'false',
        },
        {
          text: 'procId', width: '4%', value: 'procId', sortable: 'false',
        },
        {
          text: this.$tc('label.data_processamento', 1), width: '5%', value: 'procDate', sortable: 'false',
        },
        {
          text: this.$tc('label.cod_fornecedor', 1), width: '5%', value: 'supplierId', sortable: 'false',
        },
        {
          text: this.$tc('label.valor', 1), width: '5%', value: 'valueDebitNote', sortable: 'false',
        },
        {
          text: this.$tc('label.data_contabilizacao', 1), width: '5%', value: 'accountingDate', sortable: 'false',
        },
        {
          text: this.$tc('label.data_emissao', 1), width: '5%', value: 'emissionDate', sortable: 'false',
        },
        {
          text: this.$tc('label.data_vencimento', 1), width: '5%', value: 'dueDate', sortable: 'false',
        },
        {
          text: 'tpCliCrc', width: '4%', value: 'tpCliCrc', sortable: 'false',
        },
        {
          text: 'codOrigCrc', width: '4%', value: 'codOrigCrc', sortable: 'false',
        },
        {
          text: 'codEveCrc', width: '4%', value: 'codEveCrc', sortable: 'false',
        },
        {
          text: this.$tc('label.cod_filial', 1), width: '4%', value: 'branchId', sortable: 'false',
        },
        {
          text: 'directorate', width: '4%', value: 'directorate', sortable: 'false',
        },
        {
          text: this.$tc('label.cod_linha', 1), width: '4%', value: 'idLine', sortable: 'false',
        },
        {
          text: this.$tc('label.cod_familia', 1), width: '4%', value: 'idFamily', sortable: 'false',
        },
        {
          text: this.$tc('label.tipo_uso', 1), width: '5%', value: 'typeOfUse', sortable: 'false',
        },
        {
          text: this.$tc('title.tipo_pagamento', 1), width: '5%', value: 'payment', sortable: 'false',
        },
        {
          text: this.$tc('label.prazo_vencimento_dias', 1), width: '5%', value: 'dueInDays', sortable: 'false',
        },
        {
          text: this.$tc('label.observacao', 1), width: '20%', value: 'referenceAdditionalDebitNote', sortable: 'false',
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.lastPagination.page) {
          this.lastPagination = this.pagination;
          return;
        }
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.filtrar();
      },
      deep: true,
    },
  },
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    pages() {
      return Math.ceil(this.pagination.totalPage / this.pagination.rowsPerPage);
    },
    notasNaoProcessadasPaginada() {
      const listaPaginada = copyObject(this.notasNaoProcessadas);
      const startFrom = (this.pagination.page * this.pagination.rowsPerPage) - this.pagination.rowsPerPage;
      return listaPaginada.splice(startFrom, this.pagination.rowsPerPage);
    },
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    filtrar() {
      this.magaluRelatoriosResource.buscarNotasIntegradasNaoProcessadas()
        .then((res) => {
          if (res.data && res.data.records.length) {
            const [resposta] = res.data.records;

            resposta.forEach((r) => {
              if (!!r.lineFam && r.lineFam.length === 9) {
                r.idLine = r.lineFam.substring(0, 4) !== '0000' ? r.lineFam.substring(0, 4) : null;
                r.idFamily = r.lineFam.substring(4, 9) !== '00000' ? r.lineFam.substring(4, 9) : null;
              }
            });

            this.pagination.totalPage = resposta.length;
            this.notasNaoProcessadas = resposta;
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
  },
};
</script>
<style>
.RelatorioNotasNaoProcessadas table.v-table tbody td:first-child,.RelatorioNotasNaoProcessadas  table.v-table tbody td:not(:first-child),
  .RelatorioNotasNaoProcessadas table.v-table tbody th:first-child, .RelatorioNotasNaoProcessadas table.v-table tbody th:not(:first-child),
  .RelatorioNotasNaoProcessadas table.v-table thead td:first-child, .RelatorioNotasNaoProcessadas table.v-table thead td:not(:first-child),
  .RelatorioNotasNaoProcessadas table.v-table thead th:first-child, .RelatorioNotasNaoProcessadas table.v-table thead th:not(:first-child) {
  padding: 0 11px;
}
.RelatorioNotasNaoProcessadas table {
  max-width: 250%;
  width: max-content !important;
}
.RelatorioNotasNaoProcessadasTabela i {
  display: none !important;
}
.RelatorioNotasNaoProcessadasTabela th {
  cursor: default !important;
}
</style>
