<template>
  <v-container fluid>
    <v-row justify="end">

      <v-tooltip left v-if="mostrarAcompanhamento">
        <template v-slot:activator="{ on }">
          <v-btn text icon
                  v-on="on"
                  @click.native="abrirAcompanhamento">
            <v-icon >assignment_turned_in</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('label.acompanhamento') }}</span>
      </v-tooltip>

      <acao-fluxo
        ref="acaoAprovar"
        icone="thumb_up"
        :texto-acao="$t('label.aprovar')"
        :status="status"
        :is-contrato="true"
        :is-aprovacao="true"
        :id-contrato="idContrato"
        :is-carta-parceria="isCartaParceria"
        :data-inicio-contrato="dataInicioContrato"
        :data-fim-contrato="dataFimContrato"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :validar-justificativa="true"
        :is-contrato="true"
        :id-contrato="idContrato"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
      </acao-fluxo>

      <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :validar-justificativa="true"
        :is-contrato="true"
        :id-contrato="idContrato"
        v-show="isOpcaoReprovacaoHabilitada"
        v-if="podeAprovar"
        @AcaoFluxo_executarAcao="reprovar">
      </acao-fluxo>

    </v-row>
    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
      :entidade="'CONTRATO'"
    />
  </v-container>
</template>

<script>
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import Confirm from '@/shared-components/dialog/Confirm';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';

export default {
  name: 'PlanejamentoContratoFluxo',
  props: {
    idContrato: Number,
    status: String,
    isCartaParceria: {
      type: Boolean,
      default: false,
    },
    dataInicioContrato: String,
    dataFimContrato: String,
  },
  components: {
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      solicitanteFluxo: false,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      justificativa: '',
      statusPassos: {},
      isOpcaoReprovacaoHabilitada: false,
    };
  },
  computed: {
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    podeAprovar() {
      return this.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    mostrarAcompanhamento() {
      return this.acaoPossuiFluxo && this.status !== 'CANCELADO';
    },
  },
  methods: {
    carregaInformacoesFluxo() {
      if (this.idContrato && this.status) {
        this.verificarUsuarioAprovador(this.idContrato, this.status);
      }
    },
    verificaSeOpcaoReprovacaoContratoHabilitada() {
      this.planejamentoContratoResource.verificaSeOpcaoReprovacaoContratoHabilitada()
        .then((res) => {
          this.isOpcaoReprovacaoHabilitada = res.data;
        });
    },
    verificarUsuarioAprovador(idContrato, status) {
      if (status === 'AGUARDANDO_APROVACAO') {
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    reprovar(justificativa) {
      const { idContrato } = this;
      this.workflowContratoResource.reprovar({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.contrato_reprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa) {
      const { idContrato } = this;
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.contrato_aprovado'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa) {
      const { idContrato } = this;
      this.workflowContratoResource.enviarAnalise({ idContrato },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar');
          this.$toast(this.$t('message.contrato_enviado_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirAcompanhamento() {
      const { idContrato } = this;
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
  mounted() {
    this.carregaInformacoesFluxo();
    this.verificaSeOpcaoReprovacaoContratoHabilitada();
  },
};
</script>
