<template>
  <v-card>
    <v-date-picker
      locale="pt-br"
      v-model="date"
      width="500"
      landscape
      :events="ehEvento"
      :event-color="corDoEvento"
      @update:picker-date="alterarMes"
      @input="limparSelecao">
    </v-date-picker>

    <v-list v-if="listaEventos && !!listaEventos.length" two-line style="max-height: 400px; overflow-y: scroll;">
      <v-list-item
        v-for="(item, index) in listaEventos"
        :key="index">
        <v-list-item-avatar>
          <v-avatar :size="22" :color="item.cor"></v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ item.descricao }} - {{ item.nome }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatarData(item) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { skipLoading } from '@/common/functions/loading';

export default {
  name: 'CalendarioCard',
  data() {
    return {
      resources: this.$api.calendario(this.$resource),
      date: new Date().toJSON().substr(0, 10),
      dataAnterior: null,
      mesAnoPesquisa: null,
      datas: {},
      eventos: [],
    };
  },
  computed: {
    listaEventos() {
      if (this.date) {
        return this.datas[this.date] || [];
      }
      return this.eventos;
    },
  },
  watch: {
    date() {
      this.dataAnterior = this.date;
    },
  },
  methods: {
    carregarMes() {
      skipLoading();
      const params = {
        indAtivo: true,
        indCancelado: false,
        size: 20000,
        page: 0,
        dataInicio: this.getDataInicio(),
        dataFim: this.getDataFim(),
        asc: true,
        colunaOrdenacao: 'data_inicio',
      };

      this.resources.listar(params).then((response) => {
        this.converterDatas(response.data.resposta);
      }, (err) => {
        this.$error(this, err);
      });
    },
    formatarData(item) {
      const dataInicio = this.converterDataToMoment(item.dataInicio);
      const dataFim = this.converterDataToMoment(item.dataFim);
      if (dataFim.isSame(dataInicio)) {
        return dataInicio.format('DD/MM/YYYY');
      }
      return `${dataInicio.format('DD/MM/YYYY')} a ${dataFim.format('DD/MM/YYYY')}`;
    },
    converterDataToMoment(data) {
      const dataM = this.moment(data, 'DD/MM/YYYY HH:mm:ss');
      if (dataM.isValid()) {
        return dataM;
      }
      return this.moment(data, 'YYYY-MM-DD');
    },
    converterDatas(eventos) {
      this.eventos = eventos;
      const datas = {};
      eventos.forEach((e) => {
        const dataInicio = this.converterDataToMoment(e.dataInicio);
        const dataFim = this.converterDataToMoment(e.dataFim);
        while (dataInicio.isSameOrBefore(dataFim)) {
          const formatada = dataInicio.format('YYYY-MM-DD');
          if (datas[formatada]) datas[formatada].push(e);
          else datas[formatada] = [e];
          dataInicio.add(1, 'days');
        }
      });
      this.datas = datas;
    },
    getDataInicio() {
      if (!this.mesAnoPesquisa || !this.mesAnoPesquisa.length) return null;
      return this.moment(this.mesAnoPesquisa, 'YYYY-MM').startOf(1).format('YYYY-MM-DD');
    },
    getDataFim() {
      if (!this.mesAnoPesquisa || !this.mesAnoPesquisa.length) return null;
      return this.moment(this.mesAnoPesquisa, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    },
    ehEvento(date) {
      return !!this.datas[date];
    },
    corDoEvento(date) {
      return this.datas[date][0].cor;
    },
    alterarMes(mes) {
      this.mesAnoPesquisa = mes;
      this.carregarMes();
    },
    limparSelecao(data) {
      if (data === this.dataAnterior) {
        this.date = null;
      }
    },
  },
  mounted() {
    this.mesAnoPesquisa = this.moment().format('YYYY-MM');
    this.carregarMes();
  },
};
</script>
