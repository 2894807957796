<template>
  <v-dialog v-model="dialog"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition">
    <v-card class="ApuracaoAcaoListaModalGerarNd--background-color">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="confirmacaoFechamentoModal">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $tc('label.nota_debito', 1)}}</v-toolbar-title>
      </v-toolbar>
      <v-card-title v-if="!ocultaResumo">
        <span >{{ $tc('label.resumo_campanha', 1)}}</span>
      </v-card-title>
      <v-card-text class="justify" v-if="!ocultaResumo">
        <apuracao-acao-lista-modal-gerar-nd-detalhes
            v-model="acao">
        </apuracao-acao-lista-modal-gerar-nd-detalhes>
      </v-card-text>
      <v-card-title>
        <span v-if="temApuracao">{{ $tc('label.nota_debito', 1)}}</span>
        <span v-if="!temApuracao">{{ $tc('label.apuracao_nao_gerada', 1)}}</span>
      </v-card-title>
      <v-card-text class="justify">
        <v-expansion-panels
          v-if="temApuracao"
          v-model="panelExpandidos"
          multiple>
          <apuracao-acao-lista-modal-gerar-nd-apuracao
            :ref="`apuracaoAcaoListaModalGerarNdApuracao-${apuracao.id}`"
            v-for="(apuracao, index) in apuracoes"
            :key="index"
            :apuracao="apuracao"
            :indece-apuracao="index"
            :unica-apuracao="unicaApuracao"
            :buscar-apuracao="buscarApuracao"
            :data-maxima-calc-retroativo="dataMaximaCalcRetroativo"
            @ApuracaoAcaoListaModalGerarNdApuracao__abrirAcompanhamento="abrirAcompanhamento"
            @ApuracaoAcaoListaModalGerarNdApuracao__abrirCancelar="abrirModalCancelamento"
            @ApuracaoAcaoListaModalGerarNdApuracao_ApuracaoEditada="apuracaoEditada"
            @ApuracaoAcaoListaModalGerarNdApuracao__recarregarApuracoes="settarAtualizarApuracoes"
            @ApuracaoAcaoListaModalGerarNdApuracao__atualizarResumoAcao="atualizarResumoAcao">
          </apuracao-acao-lista-modal-gerar-nd-apuracao>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
    <acompanhamento
        ref="modalAcompanhamento"
        :dados-modal-acompanhamento="dadosModalAcompanhamento"
        :fluxo="statusPassos"
        :entidade="'APURACAO'"/>
    <apuracao-cancelar
        v-if="apuracaoCancelar"
        :abrir-modal="abrirModalCancelar"
        :apuracao="apuracaoCancelar"
        :titulo="tituloCancelamento"
        :mensagem-sucesso="mensagemSucesso"
        @ApuracaoCancelar__fechar="fecharModalCancelamento"
        @ApuracaoAcaoCancelar__recarregar="atualizarStatusApuracao">
    </apuracao-cancelar>
    <confirm
      ref="confirmacaoFechamentoModal"
      :message="$t('message.fechar_sem_salvar')"
      :persistent="true"
      maxWidth="510"
      @agree="close">
    </confirm>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import ApuracaoAcaoListaModalGerarNdDetalhes from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNdDetalhes';
import ApuracaoAcaoListaModalGerarNdApuracao from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNdApuracao';
import Confirm from '@/shared-components/dialog/Confirm';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import ApuracaoCancelar from '@/spa/apuracao-acao/ApuracaoCancelar';
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoAcaoListaModalGerarNd',
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      dialog: false,
      acao: null,
      apuracoes: [],
      statusPassos: {},
      apuracaoCancelar: null,
      abrirModalCancelar: false,
      atualizaApuracoes: false,
      apuracoesEditadasNaoSalvas: [],
      panelExpandidos: [],
      tituloCancelamento: null,
      mensagemSucesso: null,
      dadosModalAcompanhamento: {},
      dataMaximaCalcRetroativo: '',
    };
  },
  props: {
    value: Object,
    abrirModal: false,
    ocultaResumo: false,
    executarAnaliseSelecionada: false,
    idApuracaoSelecionada: Number,
  },
  components: {
    ApuracaoCancelar,
    Acompanhamento,
    ApuracaoAcaoListaModalGerarNdApuracao,
    ApuracaoAcaoListaModalGerarNdDetalhes,
    Confirm,
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    unicaApuracao() {
      return this.apuracoes && this.apuracoes.length === 1;
    },
    temApuracao() {
      return this.apuracoes && this.apuracoes.length > 0;
    },
  },
  watch: {
    value() {
      this.preencherAcao();
    },
    abrirModal() {
      this.dialog = this.abrirModal;
    },
    dialog(novoValor) {
      if (!novoValor) {
        this.close();
      }
    },
  },
  methods: {
    abrirModalCancelamento(apuracao) {
      const isAguardandoAprovacao = apuracao.status === 'AGUARDANDO_APROVACAO';
      this.tituloCancelamento = isAguardandoAprovacao ? this.$t('title.cancelar_fluxo_apuracao') : this.$t('title.cancelar_apuracao');
      this.mensagemSucesso = isAguardandoAprovacao ? this.$t('message.fluxo_apuracao_cancelado') : this.$t('message.apuracao_cancelada_sucesso');
      this.apuracaoCancelar = apuracao;
      setTimeout(() => { this.abrirModalCancelar = true; }, 2E2);
    },
    fecharModalCancelamento() {
      this.abrirModalCancelar = false;
      setTimeout(() => { this.apuracaoCancelar = null; }, 2E2);
    },
    preencherAcao() {
      this.acao = { ...this.value };
      this.buscarApuracao();
    },
    atualizarStatusApuracao(status, idApuracao, justificativa) {
      this.diminuirValor();
      const apuracaoCancelada = this.apuracoes.find((apuracao) => apuracao.id === idApuracao);
      apuracaoCancelada.status = status;
      apuracaoCancelada.justificativa = justificativa;
      this.atualizarFornecedoresND(idApuracao);
      this.settarAtualizarApuracoes();
    },
    diminuirValor() {
      if (this.apuracaoCancelar.status === 'AGUARDANDO_APURACAO'
          || this.apuracaoCancelar.status === 'EM_ANALISE'
          || this.apuracaoCancelar.status === 'AGUARDANDO_APROVACAO') {
        const valor = this.acao.valor_recebimento_pendente - this.apuracaoCancelar.vlrConfApuracao;
        this.acao = { ...this.value, valor_recebimento_pendente: valor };
      }
    },
    settarAtualizarApuracoes() {
      this.atualizaApuracoes = true;
    },
    recarregarApuracao() {
      if (this.atualizaApuracoes) {
        this.$emit('ApuracaoAcaoListaModalGerarNd__recarregarApuracao');
        this.atualizaApuracoes = false;
      }
    },
    buscarApuracao() {
      const param = { idAcao: this.acao.cod_acao };
      this.apuracaoListagemResource.buscarApuracaoPorAcao(param)
        .then((res) => {
          if (this.executarAnaliseSelecionada) {
            this.apuracoes = res.data.filter((apuracao) => apuracao.id === this.idApuracaoSelecionada && apuracao.status === 'EM_ANALISE');
          } else {
            this.apuracoes = res.data;
            this.panelExpandidos = [];
            this.panelExpandidos.push(this.apuracoes.findIndex((apu) => apu.status === 'AGUARDANDO_APURACAO'));
          }
        })
        .catch((err) => this.$error(this, err));
    },
    buscarChaveQtdeMesesCalculoRetroativo() {
      this.configSistemaResource.buscarChave({ chave: 'QTDE_MESES_CALCULO_RETROATIVO' })
        .then((resp) => {
          const qtdeMesesCalculoRetroativo = resp.body;
          this.dataMaximaCalcRetroativo = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'DD-MM-YYYY')
            .subtract(qtdeMesesCalculoRetroativo, 'months')
            .format('YYYY-MM-DD');
        });
    },
    confirmacaoFechamentoModal() {
      if (this.apuracoesEditadasNaoSalvas.length) {
        this.$refs.confirmacaoFechamentoModal.open();
      } else {
        this.close();
      }
    },
    close() {
      this.recarregarApuracao();
      this.$emit('ApuracaoAcaoListaModalGerarNd__fechar');
    },
    abrirAcompanhamento(item) {
      const idApuracao = item.id;
      this.dadosModalAcompanhamento = {
        idAcao: item.idAcao,
        modalidade: item.modalidade,
        numNotaDebito: item.numNotaDebito,
        countNotasDebito: item.countNotasDebito,
        idApuracao,
      };
      if (!item.temFluxo) {
        this.$toast(this.$t('message.acao_aprovada_automatica'));
        return;
      }
      this.workflowApuracaoAcaoResource.statusPasso({ idApuracao }, { idApuracao })
        .then((res) => {
          if (res.data.passos
                && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    apuracaoEditada(idApuracao, isSalvo) {
      if (!isSalvo && !this.apuracoesEditadasNaoSalvas.includes(idApuracao)) {
        this.apuracoesEditadasNaoSalvas.push(idApuracao);
      } else if (isSalvo && this.apuracoesEditadasNaoSalvas.includes(idApuracao)) {
        this.apuracoesEditadasNaoSalvas = this.apuracoesEditadasNaoSalvas.filter((id) => id !== idApuracao);
      }
    },
    atualizarFornecedoresND(idApuracao) {
      const nomeComponente = 'apuracaoAcaoListaModalGerarNdApuracao-'.concat(idApuracao);
      const componente = this.$refs[nomeComponente];
      componente[0].atualizarFornecedoresND();
    },
    atualizarResumoAcao() {
      this.$emit('ApuracaoAcaoListaModalGerarNd__atualizarResumoAcao');
    },
  },
  mounted() {
    this.buscarChaveQtdeMesesCalculoRetroativo();
    this.dialog = this.abrirModal;
    this.preencherAcao();
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}

.ApuracaoAcaoListaModalGerarNd--background-color {
  background-color: #EEEEEE !important;
}

</style>
