<template>
  <v-dialog v-model="dialog" :persistent="true" max-width="40%">
    <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
      <v-card-title>
        <div class="card-title">
          {{ $tc('title.rateio_entre_naturezas_operacao', 1) }}
        </div>
      </v-card-title>
      <v-card-text class="pb-0 pr-0">
        <v-container fluid grid-list-md class="pb-0">
          <v-row dense>
            <v-col cols="4">
              <span>{{ $tc('label.valor_total') }}: {{ getMoney(valorTotalAcao) }}</span>
            </v-col>
            <v-col cols="4">
              <span>{{ $tc('label.valor_pendente') }}: {{ getMoney(valorPendente) }}</span>
            </v-col>
            <v-col cols="4">
              <span>{{ $tc('label.valor_alocado') }}: {{ getMoney(valorAlocado) }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="p-0"  >
        <v-data-table
          id="tabela_linha_rateio"
          :headers="headersLinhaRateio"
          :items="listaRateio"
          :no-data-text="$t('label.tabela_sem_conteudo')"
          :footer-props="{ itemsPerPageOptions: [5, 10] }"
          :items-per-page="5"
        >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.nome_tipo_uso }}</td>
              <td>{{ item.cod_budget_id }}</td>
              <td>{{ getMoney(item.valor) }}</td>
            </tr>
          </tbody>
        </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text
               @click="close()">{{$t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </template>

<script>
import { getMoney } from '@/common/functions/helpers';

export default {
  name: 'ModalRateioContabil',
  props: {
    notaDebito: {
      type: Object,
      default: () => ({}),
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      dialog: false,
      valor: '',
      listaRateio: [],
      valorTotalAcao: null,
      valorPendente: null,
      valorAlocado: null,
      headersLinhaRateio: [
        {
          text: this.$tc('label.tipo_uso', 1), value: 'nome_tipo_uso', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.budget_id', 1), value: 'cod_budget_id', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '10%',
        },
      ],
    };
  },
  methods: {
    getMoney,
    open(item) {
      this.carregaListaRateioContabil(item.idAcao);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.limpaModal();
    },
    carregaListaRateioContabil(idAcao) {
      this.planejamentoAcaoResource.buscarValoresRateio({ idAcao })
        .then((res) => {
          this.listaRateio = res.body.listalinhasrateio;
          this.valorTotalAcao = res.body.valorTotal;
          this.valorPendente = res.body.valorPendente;
          this.valorAlocado = res.body.valorAlocado;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    limpaModal() {
      this.listaRateio = [];
      this.valorTotalAcao = null;
      this.valorPendente = null;
      this.valorAlocado = null;
    },
  },
};
</script>
<style lang="scss">
.justify {
  text-align: justify;
}
</style>
