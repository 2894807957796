import getBasePath from './api-resource';

const basePath = getBasePath('api', 'dicionario');

export default {
  carregar() {
    return new Promise((resolve, reject) => {
      const xhttp = new XMLHttpRequest();
      xhttp.open('GET', `${basePath}`, true);
      xhttp.setRequestHeader('Authorization', `Bearer ${sessionStorage.token}`);
      xhttp.setRequestHeader('x-tenant-id', window.localStorage.getItem('xTenantId'));
      xhttp.send();
      xhttp.onreadystatechange = function () { // eslint-disable-line
        if (this.readyState === 4) {
          if (this.status === 200) {
            const response = JSON.parse(xhttp.response);
            resolve(response);
          }
        } else if (this.status && this.status === 403) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ status: this.status, message: 'Usuário sem permissão para acessar o sistema!' });
        }
      };
    });
  },
};
