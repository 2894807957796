<template lang="html">
  <v-row class="administracao__tabs">
    <v-col cols="12">
      <v-tabs
        centered
        v-model="tabSelecionada"
        background-color="rgba(0,0,0,0)"
        color="accent">
        <v-tab href="#tab-views">
          {{ $t('label.views') }}
        </v-tab>
        <v-tab href="#tab-agendamento">
          {{ $t('label.agendamento_tarefa') }}
        </v-tab>
        <v-tab href="#tab-cache-manager">
          Cache Manager
        </v-tab>
        <v-tabs-items v-model="tabSelecionada" style="background-color: rgba(0,0,0,0)">
          <v-tab-item id="tab-views">
            <views></views>
          </v-tab-item>
          <v-tab-item id="tab-agendamento">
            <agendamento-tarefa></agendamento-tarefa>
          </v-tab-item>
          <v-tab-item id="tab-cache-manager">
            <cache-manager></cache-manager>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import Views from '@/spa/administracao/Views';
import AgendamentoTarefa from '@/spa/administracao/AgendamentoTarefa';
import CacheManager from '@/spa/administracao/CacheManager';

export default {
  name: 'Administracao',
  data() {
    return {
      tabSelecionada: 'tab-views',
      dados: null,
      resourceMetadado: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    mapaCamposDinamicos() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaCamposDinamicos) {
        return this.getProdutoMetadado.mapaCamposDinamicos;
      }
      return [];
    },
    metadadosProduto() {
      if (this.getProdutoMetadado && this.getProdutoMetadado.mapaEntidades) {
        return this.getProdutoMetadado;
      }
      return undefined;
    },
    canAccessPage() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ADMINISTRACAO_PAGINA').length;
    },
  },
  components: {
    AgendamentoTarefa,
    Views,
    CacheManager,
  },
  methods: {
    ...mapActions('metadados', [
      'setProdutoMetadado',
    ]),

    requestPopularHeader() {
      if (this.getProdutoMetadado) {
        return;
      }
      this.setProdutoMetadado({
        resource: this.resourceMetadado.listarProduto,
      });
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast('Acesso negado');
      }
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
    this.requestPopularHeader();
  },
};
</script>

<style lang="css">
.administracao__tabs .v-tabs-items {
  background: none;
}
</style>
