<template>
      <v-dialog v-model="exibeModal"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition">
        <v-card class="DashboardModalPendenciasContratos--background-color">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="confirmacaoFechamentoModal">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title><b>{{ $tc('title.aprovacao_massiva_contratos', 1).toUpperCase() }}</b></v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row class="start pb-12 pt-8">
              <v-col cols="5" sm="5" class="mx-3">
                <v-text-field
                  v-model="searchQuery"
                  append-icon="search"
                  :label="$t('label.pesquisa_modal_aprovacao_massiva_contrato')"
                  single-line
                  clearable
                  hide-details>
                </v-text-field>
              </v-col>
              <v-row  align="center" class="pl-5">
                <v-col cols="auto">
                  <v-btn density="compact" @click="aprovar">{{ $tc('label.aprovar', 1) }}</v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn density="default" @click="solicitarAnalise">{{ $tc('label.solicitar_analise', 1) }}</v-btn>
                </v-col>
              </v-row>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="listaContratos"
              v-model="selected"
              :loading="loading"
              :show-select="!somenteLeitura"
              @toggle-select-all="realizarSelecaoTotal"
              :options.sync="pagination"
              hide-default-footer
              :no-data-text="$t('label.tabela_sem_conteudo')">
              <template v-slot:[`item.detalhar`]="{ item }">
                <v-btn
                  elevation="2"
                  color="primary"
                  @click="detalharContrato(item)"
                  class="text-capitalize white--text"
                  small
                >
                  {{ $tc('label.ver_detalhes') }}
                </v-btn>
              </template>
              <template v-slot:[`item.acao`]="{ item }">
                <v-tooltip left v-if="mostrarGerarCartaParceria(item)">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon
                      v-on="on"
                      @click.native="realizarDownloadCartaParceria(item)">
                      <v-icon>receipt</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="!isTenantEpoca">{{ $tc('label.gerar_carta_parceria', 1) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.nomeTipoUso`]="{ item }">
                <div v-if="item.nomeTipoUso">
                <span v-if="!item.nomeTipoUso.includes('/')">{{ item.nomeTipoUso }}</span>
                <v-btn icon v-else class="mx-0" @click.native="visualizarTipoDeUso(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
                </div>
              </template>
              <template v-slot:[`item.contratosVigentes`]="{ item }">
                <div>
                <v-btn icon class="mx-0" @click.native="visualizarContratosVigentes(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
                </div>
              </template>
              <template v-slot:[`item.extensoes`]="{ item }">
                <div v-if="item.extensoes">
                  <span v-if="!item.extensoes.includes('/')">{{ item.extensoes }}</span>
                  <v-btn icon v-else class="mx-0" @click.native="visualizarCategorias(item)">
                    <v-icon>visibility</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
            <div class="text-xs-center">
              <v-pagination
                v-model="pagination.page"
                v-if="totalPage > 1"
                :length="totalPage"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
        <modal-lista-valores
        ref="modalListaValores">
        </modal-lista-valores>
        <modal-contrato-substituicao
          ref="modalContratoSubstituicao">
        </modal-contrato-substituicao>
        <planejamento-fluxo-modal-pendencias
          ref="planejamentoFluxoModalPendencias"
          :message="$t('message.fechar_sem_salvar')"
          :titulo-modal="tituloModal"
          :is-contrato="isContrato"
          :persistent="true"
          maxWidth="600"
          @aprovar="executarAprovadas"
          @executar_analise="executarParaAnalise"
          @agree="close">
        </planejamento-fluxo-modal-pendencias>
      </v-dialog>
  </template>
<script>
import { mapGetters } from 'vuex';
import ApuracaoAcaoListaModalGerarNd from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNd';
import ModalListaValores from '@/spa/apuracao-acao-lista/ModalListaValores';
import ModalContratoSubstituicao from '@/spa/apuracao-contrato/modals/ModalContratoSubstituicao';
import { pendenciasAcaoApuracao } from '@/common/resources/workflow-acao';
import { pendenciasContratos } from '@/common/resources/workflow-contrato';
import { getMoney } from '@/common/functions/helpers';
import { skipLoading } from '@/common/functions/loading';
import exportacaoCartaParceria from '@/common/functions/downloads';
import basePath from '@/common/functions/api-resource';
import PlanejamentoFluxoModalPendencias from '@/shared-components/PlanejamentoFluxoModalPendencias';

export default {
  name: 'DashboardModalPendenciasContratos',
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),
      exibeModal: false,
      buscaInicial: false,
      dialog: false,
      prazoPagamento: null,
      selected: [],
      listaContratos: [],
      listaContratosSelecionados: [],
      searchQuery: null,
      searchQueryArray: [],
      somenteLeitura: false,
      pendenciaExecutada: false,
      isContrato: true,
      pagination: {
        itemsPerPage: 20,
        page: 1,
      },
      totalPage: 0,
      timeout: 0,
      esperar: false,
      modalTipoUso: false,
      tamanhoTotalProdutos: 0,
      rules: {
        required: (value) => (!!value || value === 0) || this.$t('message.campo_obrigatorio'),
      },
      headers: [
        {
          text: '', value: 'acao', sortable: false, width: '3%',
        },
        { text: this.$tc('label.id', 1), value: 'id', sortable: true },
        { text: this.$tc('label.categoria', 1), value: 'extensoes', sortable: true },
        { text: this.$tc('label.fornecedor', 1), value: 'fornecedor', sortable: true },
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: true },
        {
          text: this.$tc('label.tipo_uso', 1), value: 'nomeTipoUso', sortable: true, width: '8%',
        },
        {
          text: this.$tc('label.contratos_vigentes', 1), value: 'contratosVigentes', sortable: true, width: '10%',
        },
        { text: this.$tc('label.prazo_validade', 1), value: 'prazoPagContrato', sortable: true },
        {
          text: '', value: 'detalhar', sortable: false, width: '10%',
        },
      ],
      tituloModal: '',
      justificativaTipoUso: '',
      acaoApuracaoSelecionada: {},
      loading: false,
      workspace: {},
    };
  },
  props: {
    value: Object,
    abrirModalPendenciaContrato: false,
  },
  components: {
    pendenciasAcaoApuracao,
    PlanejamentoFluxoModalPendencias,
    ApuracaoAcaoListaModalGerarNd,
    ModalListaValores,
    ModalContratoSubstituicao,
  },
  computed: {
    ...mapGetters('roles', [
      'getTenantId',
    ]),
    isTenantEpoca() {
      return this.getTenantId === 'epoca';
    },
  },
  watch: {
    abrirModalPendenciaContrato() {
      this.dialog = this.abrirModalPendenciaContrato;
    },
    searchQuery() {
      if (this.exibeModal) {
        this.pagination.page = 1;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.filtrar();
        }, 500);
      }
    },
  },
  methods: {
    getMoney,
    open() {
      this.buscaInicial = true;
      this.carregarWorkspaceFiltros();
      this.exibeModal = true;
    },
    close() {
      this.exibeModal = false;
      this.resetaModal();
      if (this.pendenciaExecutada) {
        this.$emit('DashboardModalPendenciasContrato__fechar', true);
      } else {
        this.$emit('DashboardModalPendenciasContrato__fechar', false);
      }
    },
    resetaModal() {
      this.selected = [];
      this.listaContratos = [];
      this.listaContratosSelecionados = [];
      this.iniciarTodosSelecionados = false;
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 20;
    },
    filtrar() {
      if (!this.buscaInicial) {
        setTimeout(() => {
          this.buscaPendencias();
        }, 1000);
      }
    },
    buscaPendencias(zeraPaginacao = false) {
      if (zeraPaginacao) {
        this.pagination.page = 1;
      }
      const params = {
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'idAcao',
      };
      const filtro = {};
      if (this.searchQuery && this.searchQuery.length) {
        if (this.searchQuery.includes(',')) {
          const searchQuery = this.searchQuery.split(',');
          searchQuery.forEach((valor) => {
            this.searchQueryArray.push(`${valor}%`);
          });
          params.filtroArray = this.searchQueryArray;
        } else {
          params.filtro = this.searchQuery;
        }
        filtro.searchQuery = this.searchQuery;
      }
      this.salvarFiltrosWorkspace(filtro);
      params.numeroPagina = 1;
      params.tamanhoPagina = 999999;
      this.loading = true;
      pendenciasContratos(params, this.$resource)
        .then((response) => {
          this.loading = false;
          this.buscaInicial = false;
          this.listaContratos = response.body.resposta;
          this.searchQueryArray = [];
          this.totalPage = Math.ceil(response.body.resposta.length / 20);
          this.escondePaginacao = (this.totalPage / this.pagination.itemsPerPage) <= 1;
        })
        .catch((err) => {
          if (err.data) {
            this.$toast(err.data.error);
          }
        });
    },
    carregarWorkspaceFiltros() {
      const entidade = 'aprovacao_massiva_contrato';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          if (Object.keys(this.workspace).length) {
            this.searchQuery = this.workspace.filtros.searchQuery;
          }
          this.buscaPendencias(false);
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    salvarFiltrosWorkspace(filtro) {
      this.workspace.filtros = filtro;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'aprovacao_massiva_contrato';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'aprovacao_massiva_contrato';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    confirmacaoFechamentoModal() {
      this.close();
    },
    aprovar() {
      if (this.selected.length > 0) {
        this.tituloModal = this.$tc('label.aprovar', 1);
        this.$refs.planejamentoFluxoModalPendencias.open(this.selected);
      } else {
        this.$toast(this.$tc('message.nao_existem_selecao_de_contratos_para_aprovar'));
      }
    },
    solicitarAnalise() {
      if (this.selected.length > 0) {
        this.tituloModal = this.$tc('label.solicitar_analise', 1);
        this.$refs.planejamentoFluxoModalPendencias.open();
      } else {
        this.$toast(this.$tc('message.nao_existem_selecao_de_contratos_para_solicitar_analise'));
      }
    },
    executarAprovadas(param) {
      if (this.selected.length) {
        this.aprovarContratos(param).then(() => {
          this.resetaModal();
          this.filtrar();
          this.$toast(this.$t('message.contratos_aprovados'));
        });
      }
      this.pendenciaExecutada = true;
    },
    executarParaAnalise(param) {
      if (this.selected.length) {
        this.enviarContratosParaAnalise(param);
      }
      this.pendenciaExecutada = true;
    },
    async aprovarContratos(param) {
      this.prazoPagamento = param.prazoPagamento;
      const promises = [];
      const promisesSalvar = [];
      await Promise.all(this.selected.map(async (contrato) => {
        const { idContrato } = contrato;
        const contratosSubstituicao = await this.workflowContratoResource.buscarContratosRenovacao({ idContrato });
        if (contratosSubstituicao.body && contratosSubstituicao.body.length) {
          promises.push(this.alterarContratoSubstituicaoAguardandoEncerramento(contratosSubstituicao.body, idContrato));
        }
        promises.push(this.salvarPrazoPagamento(idContrato));
        promisesSalvar.push(this.workflowContratoResource.aprovarPasso({ idContrato }, { observacao: param.justificativa }));
      }));
      await Promise.all(promises);
      await Promise.all(promisesSalvar)
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarContratosParaAnalise(param) {
      this.selected.forEach((contrato) => {
        const { idContrato } = contrato;
        this.workflowContratoResource.enviarAnalise({ idContrato },
          { observacao: param.justificativa })
          .then(() => {
            this.resetaModal();
            this.filtrar();
            this.$toast(this.$t('message.contratos_enviados_para_analise'));
          })
          .catch((err) => {
            this.$error(this, err);
          });
      });
    },
    enviarAnalise(param) {
      this.selected.forEach((contrato) => {
        const { idContrato } = contrato;
        this.workflowContratoResource.enviarAnalise({ idContrato },
          { observacao: param.justificativa })
          .then(() => {})
          .catch((err) => {
            this.$error(this, err);
          });
      });
    },
    aprovarContrato(param, idContrato) {
      this.workflowContratoResource.aprovarPasso({ idContrato },
        { observacao: param.justificativa })
        .then(() => {})
        .catch((err) => {
          this.$error(this, err);
        });
    },
    alterarContratoSubstituicaoAguardandoEncerramento(contratosSubstituicao, idContrato) {
      const promises = [];
      contratosSubstituicao.forEach((contrato) => {
        const param = {
          idContratoAtual: idContrato,
          idContratoEncerramento: contrato.id_contrato,
        };
        const promise = this.planejamentoContratoResource.alterarContratoSubstituicaoAguardandoEncerramento(param, param)
          .then(() => {})
          .catch((err) => {
            this.$toast(err.data.error);
          });
        promises.push(promise);
      });
      Promise.all(promises);
    },
    salvarPrazoPagamento(idContrato) {
      if (!this.prazoPagamento) {
        this.prazoPagamento = 30;
      }
      this.workflowContratoResource.salvarPrazoPagamento({ idContrato },
        { prazo_pag_contrato: this.prazoPagamento })
        .then(() => {})
        .catch((err) => {
          this.$error(this, err);
        });
    },
    detalharContrato(contrato) {
      const route = this.$router.resolve({ name: 'detalharContrato', params: { idContrato: contrato.idContrato, from: 'dashboard-inicial', modalDashboard: 'PENDENCIAS_CONTRATO' } });
      this.abreNovaAbaPlanejamento(contrato.idContrato, route);
    },
    abreNovaAbaPlanejamento(idContrato, route) {
      const newTab = window.open(route.href, '_blank');
      if (newTab) {
        localStorage.setItem('idContrato', idContrato);
      }
    },
    isTemplateVerbaVariavel(acao) {
      const { template } = acao;
      return template === 'VERBA_VARIAVEL';
    },
    visualizarNotasDebito(item) {
      setTimeout(() => this.$refs.modalNotaDebito.open(item), 2E2);
    },
    valorTipoUsoAlterado(item) {
      this.openModalJustificativa(item);
    },
    openModalJustificativa(item) {
      item.justificativaTipoUso = this.justificativaTipoUso;
      this.acaoApuracaoSelecionada = item;
      this.dialogJustificativaTipoUso = true;
    },
    closeModalJustificativa() {
      this.dialogJustificativaTipoUso = false;
    },
    modalJustificativaConfirmar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = this.justificativaTipoUso;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    modalJustificativaCancelar() {
      this.acaoApuracaoSelecionada.justificativaTipoUso = '';
      this.acaoApuracaoSelecionada.idTipoUso = this.acaoApuracaoSelecionada.idTipoUsoAcaoTipo;
      this.acaoApuracaoSelecionada = {};
      this.justificativaTipoUso = '';
      this.closeModalJustificativa();
    },
    mostrarGerarCartaParceria(item) {
      return ['EM_CADASTRO', 'AGUARDANDO_APROVACAO', 'APROVADO', 'EM_ANALISE'].includes(item.status)
        && item.isCartaParceria;
    },
    realizarDownloadCartaParceria(item) {
      const getBasePath = basePath('job', 'contrato/carta-parceria');
      const servico = 'gerar-carta-parceria';
      const resource = exportacaoCartaParceria(this.$http);
      const idContrato = item.id;
      resource.download(getBasePath, idContrato, servico)
        .then(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_sucesso'));
        })
        .catch(() => {
          this.$toast(this.$t('message.carta_parceria_gerada_erro'));
        });
    },
    visualizarTipoDeUso(item) {
      const listaValor = {
        titulo: this.$t('title.tipo_de_uso_modal_contrato'),
        valor: item.nomeTipoUso,
      };
      setTimeout(() => this.$refs.modalListaValores.open(listaValor), 2E2);
    },
    visualizarContratosVigentes(item) {
      const dados = {
        idContrato: item.idContrato,
      };
      setTimeout(() => this.$refs.modalContratoSubstituicao.open(dados), 2E2);
    },
    visualizarCategorias(item) {
      const listaValor = {
        titulo: this.$tc('title.categorias', 2),
        valor: item.extensoes,
      };
      setTimeout(() => this.$refs.modalListaValores.open(listaValor), 2E2);
    },
    realizarSelecaoTotal(toggleSelectAll) {
      if (toggleSelectAll.value) {
        this.selected = [];
        this.selected = [...this.listaContratos];
      } else {
        this.selected = [];
      }
    },
  },
  mounted() {
    this.dialog = this.abrirModalPendenciaContrato;
    this.open();
  },
};
</script>
<style lang="scss">

.DashboardModalPendenciasContratos--background-color {
  background-color: #EEEEEE !important;
}

</style>
