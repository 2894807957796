import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'parametrizacao-acao');

const parametrizacaoAcaoActions = {
  atualizar: { method: 'PUT', url: `${basePath}` },
  gravar: { method: 'POST', url: `${basePath}` },
  buscar: { method: 'GET', url: `${basePath}` },
  obterPasso1: { method: 'GET', url: `${basePath}/{id}/passo1` },
  obterPasso2: { method: 'GET', url: `${basePath}/{id}/passo2` },
  obterPasso3: { method: 'GET', url: `${basePath}/{id}/passo3` },
  buscarVinculosCarteira: { method: 'GET', url: `${basePath}/passo3/carteira` },
  obterPasso4: { method: 'GET', url: `${basePath}/{id}/passo4` },
  obterPasso5: { method: 'GET', url: `${basePath}/{id}/passo5` },
  obterPasso6: { method: 'GET', url: `${basePath}/{id}/passo6` },
  obterPasso7: { method: 'GET', url: `${basePath}/{id}/passo7` },
  obterPasso8: { method: 'GET', url: `${basePath}/{id}/passo8` },
  ativarInativar: { method: 'PUT', url: `${basePath}/ativar-inativar/{id}` },
  obterCamposPreenchimentoExterno: { method: 'GET', url: `${basePath}/obter-campos-preenchimento-externo` },
  verificarSeConfiguracaoHerdada: { method: 'GET', url: `${basePath}/verificar-se-configuracao-herdada/{id}` },
  verificarSeTipoAcaoAgrupadoraEmContrato: { method: 'GET', url: `${basePath}/verificar-se-tipo-acao-agrupadora-em-contrato/{id}` },
};

export default (resource) => resource(`${basePath}`, {}, parametrizacaoAcaoActions);
