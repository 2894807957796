import getBasePath from '@/common/functions/api-resource';
import resourceBuilder from '../functions/metadados-resource-builder';

const basePath = getBasePath('workflow', 'execucao/acao');

const workflowAcaoActions = {
  aprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/aprovacao-massiva{?dataContabilizacao*}` },
  aprovador: { method: 'GET', url: `${basePath}/{idAcao}/passos/aprovador` },
  aprovarPasso: { method: 'PUT', url: `${basePath}/{idAcao}/passos/aprovar` },
  associaUsuarioFornecedorAprovador: { method: 'POST', url: `${basePath}/{idAcao}/passos/{idPasso}/fornecedor/usuario/{idUsuario}` },
  associaUsuariosCondicionalAprovador: { method: 'POST', url: `${basePath}/passos/associar-usuarios-condicional` },
  buscaPassosDiretor: { method: 'GET', url: `${basePath}/{idAcao}/passos/diretor` },
  buscaPassosFornecedor: { method: 'GET', url: `${basePath}/{idAcao}/passos/fornecedor` },
  buscarJustificativa: { method: 'GET', url: `${basePath}/justificativa/{idAcao}` },
  buscarQuantidadePendenciasTotalizadores: { method: 'GET', url: `${basePath}/buscar-quantidade-pendencias-totalizadores` },
  cancelar: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  enviarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/passos/analise` },
  enviarAnaliseAcaoPorInativacaoFornecedor: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/analise-acao-por-inativacao-fornecedor` },
  enviarAnaliseDadosMassivos: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/analise-dados-massivos` },
  fluxosUsuarioSolicitante: { method: 'GET', url: `${basePath}/fluxos-usuario-solicitante` },
  iniciaFluxo: { method: 'POST', url: `${basePath}/{idAcao}` },
  reprovacaoMassiva: { method: 'PUT', url: `${basePath}/{idAcao}/passos/{observacao}/{idTipoUso}/{justificativaTipoUso}/reprovacao-massiva` },
  reprovar: { method: 'PUT', url: `${basePath}/{idAcao}/passos/reprovar` },
  solicitante: { method: 'GET', url: `${basePath}/solicitante/{idAcao}` },
  status: { method: 'GET', url: `${basePath}/{idAcao}/status` },
  statusPasso: { method: 'GET', url: `${basePath}/{idAcao}/passos/status` },
  validarAcaoAgrupadoraEApuracoes: { method: 'GET', url: `${basePath}/buscar-acao-agrupadora-e-apuracoes` },
};
export default (resource) => resource(`${basePath}`, {}, workflowAcaoActions);

export const pendenciasFluxoAcao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias', parametros).doGet();

export const pendenciasAcaoApuracao = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-acao-apuracao', parametros).doGet();

export const pendenciasAcaoApuracaoPaginada = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'buscar-pendencias-acao-apuracao-paginada', parametros).doGet();

export const pendenciasFluxoAcaoEmAnalise = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'pendencias-analise', parametros).doGet();

export const buscarPerfilPassoAprovador = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'perfil_aprovador', parametros).doGet();

export const enviarEmailAvisoInativacaoFornecedor = (parametros, resource) => resourceBuilder.buildPUT(resource, basePath, 'enviar-email-aviso-inativacao-fornecedor', parametros).doPut(parametros);
