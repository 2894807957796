<template lang="html">
  <div class="ma-3" >
    <v-container fluid grid-list-md>
      <v-row>
        <v-col cols="12" v-for="(view, index) in views" :key="index"
          sm="6" md="4" lg="3">
          <v-hover>
            <v-card
              class="mx-auto"
              max-width="400"
              slot-scope="{ hover }"
              :color="view.cor"
              dark
              :class="`elevation-${hover ? 12 : 2}`">
              <v-card-title>
                <span class="font-weight-light title">{{ $t('label.view')}}</span>
              </v-card-title>
              <v-card-text class="headline font-weight-bold">
                {{ view.nome }}
              </v-card-text>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-row align="center" justify="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mr-1" text dark icon @click="criaView(view)" v-on="on">
                          <v-icon>create</v-icon>
                        </v-btn>
                      </template>
                      <span>Cria a view se não existir</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mr-1" text dark icon @click="refreshView(view)" v-on="on">
                          <v-icon>autorenew</v-icon>
                        </v-btn>
                      </template>
                      <span>Atualiza os dados da view</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mr-1" text dark icon @click="forceView(view)" v-on="on">
                          <v-icon>healing</v-icon>
                        </v-btn>
                      </template>
                      <span>Força recriação da view</span>
                    </v-tooltip>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import basePath from '@/common/functions/api-resource';

export default {
  name: 'Views',
  data() {
    return {
      agendamentoResources: this.$api.agendamento(this.$resource),
      views: [
        {
          nome: this.$tc('label.acao', 1),
          url: 'compilador/views/acao',
          base: 'planejamento_acao',
          cor: 'cyan',
        },
        {
          nome: this.$tc('label.contrato', 1),
          url: 'compilador/views/contrato',
          base: 'planejamento_acao',
          cor: 'pink',
        },
        {
          nome: this.$tc('label.produto', 1),
          url: 'compilador/views/produto',
          base: 'api',
          cor: 'orange',
        },
        {
          nome: this.$tc('label.fornecedor', 1),
          url: 'compilador/views/fornecedor',
          base: 'api',
          cor: 'deep-purple',
        },
      ],
    };
  },
  methods: {
    criaView(item) {
      const path = `${basePath(item.base, item.url)}/create`;
      return this.executar(path);
    },
    refreshView(item) {
      const path = `${basePath(item.base, item.url)}/refresh`;
      return this.executar(path);
    },
    forceView(item) {
      const path = `${basePath(item.base, item.url)}/force`;
      return this.executar(path);
    },
    executar(path) {
      return this.$http.post(path)
        .then(() => {
          this.$toast(this.$t('message.view_reprocessada'));
        });
    },
  },
};
</script>
