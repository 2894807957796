<template lang="html">
  <v-row>
    <v-col cols="12" sm="6" md="6">
      <importacao-sucesso-tabela style="height:400px; max-height:400px"
        :registros="tableRowsFinalizado"
        :headers="tableHeaderFinalizado"
        v-if="tableRowsFinalizado"/>
    </v-col>

    <v-col cols="12" sm="6" md="6">
      <importacao-erro-tabela style="height:400px; max-height:400px"
        :registros="tableRowsErros"
        :headers="tableHeaderErros"/>
    </v-col>
  </v-row>
</template>

<script>
import ImportacaoSucessoTabela from '@/spa/importacao/ImportacaoSucessoTabela';
import ImportacaoErroTabela from '@/spa/importacao/ImportacaoErroTabela';

export default {
  name: 'ImportacaoFinalizadaDialog',
  props: {
    tableRowsFinalizado: {
      type: Array,
      default: [],
    },
    tableHeaderFinalizado: {
      type: Array,
      default: [],
    },
    tableRowsErros: {
      type: Array,
      default: [],
    },
    tableHeaderErros: {
      type: Array,
      default: [],
    },
  },
  components: {
    ImportacaoSucessoTabela,
    ImportacaoErroTabela,
  },
  methods: {
    closeDialog() {
      this.$emit('IMPORTACAO_FINALIZADA_DIALOG_CLOSE');
    },
  },
};
</script>
