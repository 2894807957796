import vanillaMasker from 'vanilla-masker';

export const accessObjectViaString = (object, access) => {
  if (!access || access.length < 1) {
    return object;
  }
  let attribute = object;
  access.split('.').forEach((el) => {
    const value = attribute[el];
    attribute = value || el;
  });
  if ((attribute && !Number.isNaN(attribute))
    || (attribute && attribute.length)
    || attribute instanceof Function) {
    return attribute;
  }
  return access;
};

export const capitalize = (str) => (str ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : '');

export const copyObject = (object) => JSON.parse(JSON.stringify(object));

export const getClosestVueParent = (parent, cssClass) => {
  if (!parent || !parent.$el) {
    return false;
  }

  if (parent._uid === 0) { // eslint-disable-line no-underscore-dangle
    return false;
  }

  if (parent.$el.classList && parent.$el.classList.contains(cssClass)) {
    return parent;
  }

  return getClosestVueParent(parent.$parent, cssClass);
};

export const getClosestThemedParent = ($parent) => {
  if (!$parent || !$parent.$el || $parent._uid === 0) { // eslint-disable-line
    return false;
  }

  if ($parent.mdTheme || $parent.mdName) {
    return $parent;
  }

  return getClosestThemedParent($parent.$parent);
};

export const removeItem = (array, item) => {
  if (array && item && array.hasOwnProperty('length') && array.length) {
    const index = (Number.isNaN(Number(item)))
      ? array.indexOf(item)
      : item;
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
  return array;
};

export const convertToCpf = (cpf) => cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

export const convertFloatToMoney = (str) => {
  const t1 = String(str).split('.');
  let t2 = '';
  if (t1[1]) {
    t2 = t1[1].length === 1 ? `${t1[1]}0` : t1[1];
  } else {
    t2 = '00';
  }
  return `${t1[0]}.${t2}`;
};

function getValueFixed(vlrModelo, vlrDefault, decimalPlaces, symbol, onRight) {
  if (vlrModelo === null || vlrModelo === undefined) {
    if (vlrDefault === undefined) return '-';
    vlrModelo = vlrDefault;
  }
  const vlrArredondado = vlrModelo.toFixed ? vlrModelo.toFixed(decimalPlaces) : vlrModelo;
  let vlrFormatado = convertFloatToMoney(vlrArredondado);
  vlrFormatado = vlrFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const money = vlrFormatado.split('.');
  const ready = onRight ? `${money[0].replace(/,/g, '.')},${money[1]}${symbol}`
    : `${symbol} ${money[0].replace(/,/g, '.')},${money[1]}`;
  return ready;
}

export const getMoney = (vlrModelo, vlrDefault, decimalPlaces = 2, currency = 'R$') => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, currency);

export const getPercent = (vlrModelo, vlrDefault, decimalPlaces = 2) => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, '%', true);

export const getNumber = (vlrModelo, vlrDefault, decimalPlaces = 2) => getValueFixed(vlrModelo, vlrDefault, decimalPlaces, '');

export const convertToMoney = (value) => {
  if (!value) {
    return 'R$ 0,00';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `R$ -${vanillaMasker.toMoney(valor)}`;
  }
  return `R$ ${vanillaMasker.toMoney(valor)}`;
};

export const convertToPercent = (value) => {
  if (!value) {
    return '0,00 %';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)} %`;
  }
  return `${vanillaMasker.toMoney(valor)} %`;
};

export const convertToNumberFormat = (value) => {
  if (!value) {
    return '0';
  }
  const valor = convertFloatToMoney(value);
  if (value < 0) {
    return `-${vanillaMasker.toMoney(valor)}`;
  }
  return `${vanillaMasker.toMoney(valor)}`;
};

export const convertToIntegerFormat = (value) => {
  if (!value) {
    return '0';
  }
  return Number(value).toLocaleString();
};

function isEmpty(obj) {
  if (Array.isArray(obj) && !obj.length) {
    return true;
  }
  if (obj instanceof Object && !Object.keys(obj).length) {
    return true;
  }
  if (obj === null || obj === undefined) {
    return true;
  }
  return false;
}

function replacer(key, value) {
  if (isEmpty(value)) {
    return undefined;
  }
  return value;
}

export const removeEmptyProperties = (obj) => {
  if (isEmpty(obj)) {
    return obj;
  }
  const objParseado = JSON.parse(JSON.stringify(obj, replacer));
  const jsonString = Object.assign(objParseado, objParseado.extensoes);
  delete jsonString.extensoes;
  return jsonString;
};

export const removerItensDuplicadosPorId = (listaDuplicados) => {
  const idsUnicos = new Set();
  return listaDuplicados.filter((item) => {
    const isDuplicado = idsUnicos.has(item.id);
    idsUnicos.add(item.id);

    if (!isDuplicado) {
      return true;
    }
    return false;
  });
};
