<template lang="html">
  <div class="FilialTabela">
    <v-card class="card-size">
      <v-card-title>
        <h3 class="headline mb-0" v-text="title"></h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="searchQuery"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
        <div class="mt-1 ml-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon
                id="tipo-investimento-botao-ativar-inativar"
                v-show="canEdit && selected.length > 0"
                class="mx-0"
                v-on="on"
                @click="dialogConfirmarInativacao">
                <v-icon>block</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.ativar_inativa') }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-data-table
        v-model="selected"
        :headers="headersDinamico"
        :items="filiais"
        show-select
        :options.sync="pagination"
        :server-items-length="totalPage"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50],
        }">
        <template v-slot:item="{ item, isSelected, select }">
          <tr :key="item.name" :class="{ 'TableRow--disabled': !item.ativo }">
            <td>
              <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </td>
            <td class="justify-center px-0" v-if="!somenteLeitura">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="onEditarFilial(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.editar') }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.nome }}</td>
            <td>{{ item.idExterno }}</td>
            <td>{{ item.regiao }}</td>
            <td v-for="(entidade, index) in Object.keys(metadadosFilial.mapaEntidades)"
              :key="index">
              {{ item.getNomesSeparadosPorVirgula(entidade, 'nomExtensao') }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <confirm
      ref="dialogConfirmaInativacao"
      :message="$t('message.deseja_ativar_inativar_registros', {quantidade: selected.length})"
      :persistent="true"
      @agree="ativarInativar">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import Confirm from '@/shared-components/dialog/Confirm';
import Filial from './Filial';

export default {
  data() {
    return {
      filialResources: this.$api.filial(this.$resource),
      filiais: [],
      esperar: false,
      headers: [
        { text: this.$tc('label.nome', 1), value: 'nome', sortable: true },
        { text: this.$tc('label.codigo', 1), value: 'id_externo', sortable: true },
        { text: this.$tc('label.regiao', 1), value: 'regiao', sortable: true },
      ],
      pagination: {},
      totalPage: 0,
      searchQuery: '',
      registros: [],
      selected: [],
    };
  },
  components: {
    Confirm,
  },
  props: {
    title: String,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
    searchQuery() {
      this.pagination.page = 1;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        this.filtrar();
      }, 500);
    },
  },
  computed: {
    filteredList() {
      return [];
    },
    ...generateComputed('Filial', [
      'canEdit',
      'canGet',
      'canAccessPage',
    ]),
    ...mapGetters('metadados', [
      'getFilialMetadado',
    ]),
    metadadosFilial() {
      if (this.getFilialMetadado && this.getFilialMetadado.mapaEntidades) {
        return this.getFilialMetadado;
      }
      return undefined;
    },
    headersDinamico() {
      const headers = Object.assign([], this.headers);

      if (!this.metadadosFilial) return headers;

      Object.keys(this.metadadosFilial.mapaEntidades).forEach((entidade) => {
        const mapeamentoEntidade = this.metadadosFilial.mapaEntidades[entidade];
        headers.push({
          text: mapeamentoEntidade.entidadeEstrangeira,
          value: mapeamentoEntidade.label,
          sortable: false,
        });
      });

      if (!this.somenteLeitura) {
        headers.unshift({
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        });
      }

      return headers;
    },
  },
  methods: {
    buscar() {
      const params = {
        filtro: this.searchQuery,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      this.filialResources.listar(params).then((response) => {
        this.registros = response.data.resposta;
        this.montarObjetoFiliais();
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    montarColunasDinamicas() {
      const objeto = [];
      Object.keys(this.metadadosFilial.mapaEntidades).forEach((entidade) => {
        objeto.push(this.metadadosFilial.mapaEntidades[entidade].label);
      });
      return objeto;
    },
    montarObjetoFiliais() {
      this.filiais = this.registros.map((data) => {
        const filial = new Filial();
        filial.construirAPartirDaConsultaCompleta(data);
        filial.montarEstruturaDinamica(data, this.metadadosFilial);
        return filial;
      });
    },
    onEditarFilial(filial) {
      if (!filial.desAtributos) {
        filial.desAtributos = { regional: false };
      }
      this.$emit('PESQUISA_FILIAL_EDITAR', filial);
    },
    dialogConfirmarInativacao() {
      this.$refs.dialogConfirmaInativacao.open();
    },
    ativarInativar() {
      this.filialResources.ativarMassa(this.selected)
        .then(() => {
          if (this.selected.length > 1) {
            this.$toast(this.$t('message.alteracao_massa_sucesso'));
          } else {
            this.$toast(this.$t('message.atualizado_com_sucesso'));
          }
          this.selected = [];
          this.filtrar();
        })
        .catch((err) => this.$error(this, err));
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
  },
  mounted() {
    this.filtrar();
  },
};
</script>
<style scoped>

</style>
