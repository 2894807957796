import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'regiao');

const regiaoActions = {
  gravar: { method: 'POST', url: `${basePath}` },
  atualizar: { method: 'PUT', url: `${basePath}` },
  listar: { method: 'GET', url: `${basePath}?tamanhoPagina={size}&numeroPagina={page}` },
  listarAtivos: { method: 'GET', url: `${basePath}?nome={nome}&indAtivo=true` },
  ativar: { method: 'PUT', url: `${basePath}/ativacao/{id}/true` },
  inativar: { method: 'PUT', url: `${basePath}/ativacao/{id}/false` },
  selecao: { method: 'GET', url: `${basePath}/selecao` },
  ativarInativarRegistros: { method: 'POST', url: `${basePath}/ativarInativarRegistros` },
};

export default (resource) => resource(`${basePath}`, {}, regiaoActions);
