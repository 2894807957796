<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row no-gutters>
        <v-col cols="1" lg="2" xl="1" xxl="1">
          <v-menu
              bottom
              origin="center center"
              transition="scale-transition"
              v-if="mostrarAcompanhamento || mostrarCancelamento">
            <template v-slot:activator="{ on }">
              <v-btn
                  class="my-n3"
                  v-on="on"
                  :id="'more_vert_' + apuracao.id"
                  icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-if="mostrarAcompanhamento"
                  @click="abrirAcompanhamento">
                <v-list-item-action>
                  <v-icon>assignment_turned_in</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.acompanhamento', 1) }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="mostrarCancelamento"
                  @click="abrirCancelar">
                <v-list-item-action>
                  <v-icon>cancel</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ $tc('label.cancelar', 1) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="unicaApuracao" class="font-weight-bold">{{ $tc('label.nota_debito', 1) }}</span>
          <span v-else :class="(mostrarAcompanhamento || mostrarCancelamento) ? 'font-weight-bold': 'ml-9 font-weight-bold'">{{ `${$tc('label.parcial', 1)} #${indeceApuracao + 1}` }}</span>
        </v-col>
        <v-col cols="2" lg="1" xl="2" xxl="2" v-if="exibeDataEmissao" >
          <span>{{ `${$tc('label.data_emissao', 1)}: ${(apuracao.emissao.data_emissao)}` }}</span>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon
               class="mb-1"
                v-on="on"
                small
                v-bind:style="{color: getCollorIcon(apuracao)}">lens
              </v-icon>
            </template>
            {{apuracao.emissao.regra_data_emissao}}
          </v-tooltip>
        </v-col>
        <v-col cols="2">
          <span>{{ `${$tc('label.periodo', 1)}: ${formatDateDDMMYYYYBarras(apuracao.dtInicioVigencia)} ${$tc('label.a', 2)} ${formatDateDDMMYYYYBarras(apuracao.dtFimVigencia)}` }}</span>
        </v-col>
        <v-col align="center" cols="3">
          <span v-if="isStatusApuracaoPrevia || isStatusCancelado">{{
            `${$tc('label.recebimento_calculado', 1)}: ${exibirTetoAcaoPrevia ? getMoney(apuracao.tetoAcao) : getMoney(apuracao.vlrPrevia)}` }}
          </span>
          <span class="text-center " v-else>{{ `${$tc('label.recebimento_calculado', 1)}: ${getMoney(apuracao.vlrConfApuracaoOriginal)}` }}</span>
        </v-col>
        <v-col align="center" cols="2">
          <span>{{ `${$tc('label.total_de_nd', 1)}: ${exibirTetoAcaoPrevia ?  getMoney(apuracao.tetoAcao) : getMoney(apuracao.totalNd)}` }}</span>
        </v-col>
        <v-col cols="2">
          <status-descricao :status="apuracao.status" :larguraAutomatica="false"></status-descricao>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters v-if="exibeBotaoReprocessarApuracao">
        <v-col align="center" cols="12">
          <v-btn
            @click.native="abrirModalJustificativaReprocessarApuracao()"
            elevation="1"
            dark
            height="30"
            class="mx-1"
            color="orange darken-4">
            {{ $t('label.reprocessar_apuracao') }}
          </v-btn>
          <v-btn
            @click.native="recalcularApuracao(apuracao)"
            elevation="1"
            dark
            height="30"
            class="mx-1"
            color="orange darken-2">
            {{ $t('label.recalcular_apuracao') }}
          </v-btn>
        </v-col>
      </v-row>
      <apuracao-acao-lista-modal-gerar-nd-apuracao-fornecedores
        ref="apuracaoAcaoListaModalGerarNdApuracaoFornecedores"
        :apuracao="apuracao"
        :ind-excede-retroatividade="indExcedeRetroatividade"
        :buscar-apuracao="buscarApuracao"
        @ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__apuracaoEditada="apuracaoEditada"
        @ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__recarregarApuracoes="recarregarApuracoes"
        @ApuracaoAcaoListaModalGerarNdApuracaoFornecedores__emitirValorAlterado="alterarValorTotal">
      </apuracao-acao-lista-modal-gerar-nd-apuracao-fornecedores>
      <modal-justificativa
        ref="modalJustificativaReprocessamento"
        :title="$t('title.apuracao_acao_justificativa_reprocessamento')"
        :nota-debito="apuracao"
        :somente-leitura="false"
        :texto-botao-afirmativo="$t('label.salvar')"
        @ModalJustificativa__afirmativo="reprocessarApuracao()">
      </modal-justificativa>
      <confirm
        ref="confirmacaoRecalcularApuracao"
        :message="$t('message.apuracao_recalculada_com_sucesso')"
        :persistent="true"
        maxWidth="510"
        @agree="atualizarInformacoesTela()"
        hide-disagree>
      </confirm>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { getMoney } from '@/common/functions/helpers';
import { formatDateDDMMYYYYBarras } from '@/common/functions/date-utils';
import Confirm from '@/shared-components/dialog/Confirm';
import StatusDescricao from '@/shared-components/StatusDescricao';
import ApuracaoAcaoListaModalGerarNdApuracaoFornecedores from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNdApuracaoFornecedores';
import { forceLoading, forceHideLoading } from '@/common/functions/loading';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import ModalJustificativa from './ModalJustificativa';

export default {
  name: 'ApuracaoAcaoListaModalGerarNdApuracao',
  data() {
    return {
      apuracaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
    };
  },
  components: {
    ApuracaoAcaoListaModalGerarNdApuracaoFornecedores,
    StatusDescricao,
    ModalJustificativa,
    Confirm,
  },
  props: {
    indeceApuracao: Number,
    unicaApuracao: Boolean,
    apuracao: Object,
    buscarApuracao: Function,
    dataMaximaCalcRetroativo: String,
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    canCancelarFluxo() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_FLUXO_APROVACAO').length;
    },
    isStatusApuracaoPrevia() {
      return this.apuracao.status === 'APURACAO_PREVIA';
    },
    exibirTetoAcaoPrevia() {
      return this.apuracao.tetoAcao > 0 && (this.apuracao.tetoAcao < this.apuracao.vlrPrevia);
    },
    isStatusCancelado() {
      return this.apuracao.status === 'CANCELADO';
    },
    isStatusAguardandoApuracao() {
      return this.apuracao.status === 'AGUARDANDO_APURACAO';
    },
    isStatusEmAnalise() {
      return this.apuracao.status === 'EM_ANALISE';
    },
    isModalidadeSellout() {
      return this.apuracao.modalidade === 'SELLOUT';
    },
    mostrarAcompanhamento() {
      return !this.isStatusAguardandoApuracao
          && !this.isStatusApuracaoPrevia
          && !this.isStatusCancelado;
    },
    mostrarCancelamento() {
      return this.canEdit
        && (['AGUARDANDO_APURACAO', 'APURACAO_PREVIA', 'EM_ANALISE'].includes(this.apuracao.status)
        || (this.canCancelarFluxo && ['AGUARDANDO_APROVACAO'].includes(this.apuracao.status)));
    },
    valorCalculadoApuracao() {
      return this.apuracao.vlrConfApuracaoOriginal > 0 ? this.apuracao.vlrConfApuracaoOriginal : 0;
    },
    podeReprocessarApuracao() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REPROCESSAR_APURACAO_SELLOUT') !== -1).length > 0;
    },
    exibeBotaoReprocessarApuracao() {
      return this.isModalidadeSellout && this.podeReprocessarApuracao
        && (this.isStatusAguardandoApuracao || this.isStatusEmAnalise);
    },
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    exibeDataEmissao() {
      return this.isAmbienteMagalu && this.apuracao.emissao && this.apuracao.emissao.data_emissao;
    },
    indExcedeRetroatividade() {
      return this.verificaSeDataExcedeRetroatividade(this.apuracao.dtInicioVigencia);
    },
  },
  methods: {
    formatDateDDMMYYYYBarras,
    getMoney,
    abrirAcompanhamento() {
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracao__abrirAcompanhamento', this.apuracao);
    },
    abrirCancelar() {
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracao__abrirCancelar', this.apuracao);
    },
    apuracaoEditada(idApuracao, isSalvo) {
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracao_ApuracaoEditada', idApuracao, isSalvo);
    },
    atualizarFornecedoresND() {
      this.$refs.apuracaoAcaoListaModalGerarNdApuracaoFornecedores.buscarFornecedoresNd();
    },
    alterarValorTotal(valorTotal) {
      this.apuracao.totalNd = valorTotal;
    },
    recarregarApuracoes() {
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracao__recarregarApuracoes');
      this.$emit('ApuracaoAcaoListaModalGerarNdApuracao__atualizarResumoAcao');
    },
    reprocessarApuracao() {
      const idApuracao = this.apuracao.id;
      const parametrosReprocessamento = {
        idApuracao,
        justificativa: this.apuracao.justificativa,
        vlrConfApuracao: this.apuracao.totalNd,
      };

      this.apuracaoListagemResource.registrarApuracaoAcaoReprocessamento(parametrosReprocessamento)
        .then(() => {
          this.apuracaoListagemResource.reprocessarApuracao({ idApuracao }, { idApuracao })
            .then(() => {
              this.apuracao.status = 'APURACAO_PREVIA';
              this.atualizarFornecedoresND();
            })
            .catch((err) => this.$error(this, err));
        })
        .catch((err) => this.$error(this, err));
      this.apuracao.justificativa = null;
    },
    abrirModalJustificativaReprocessarApuracao() {
      this.$refs.modalJustificativaReprocessamento.open();
    },
    recalcularApuracao() {
      const params = {
        idApuracao: apuracao.id,
        idAcao: apuracao.idAcao,
      };

      this.apuracaoListagemResource.recalcularApuracao(params, params)
        .then(() => {
          this.$refs.confirmacaoRecalcularApuracao.open();
        })
        .catch((err) => this.$error(this, err));
    },
    verificaSeDataExcedeRetroatividade(data) {
      return moment(data).isBefore(this.dataMaximaCalcRetroativo);
    },
    atualizarInformacoesTela() {
      forceLoading();
      this.recarregarApuracoes();
      this.atualizarFornecedoresND();
      forceHideLoading();
    },
    getCollorIcon(apuracao) {
      if (apuracao && apuracao.status) {
        return apuracao.status === 'APROVADO' ? '#1abc9c' : '#95a5a6';
      }
      return '#95a5a6';
    },
  },
};
</script>
