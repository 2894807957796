import getBasePath from '@/common/functions/api-resource';

const basePath = getBasePath('api', 'tipo-contrato');

const tipoContratoActions = {
  buscarAtivos: { method: 'GET', url: `${basePath}/ativos` },
  buscarTipoContratoComConfiguracao: { method: 'GET', url: `${basePath}/{idTipoContrato}/configuracao` },
  buscarTipoContrato: { method: 'GET', url: `${basePath}/{id}` },
  buscarTipoContratoSamsung: { method: 'GET', url: `${basePath}/samsung` },
  buscarTipoContratoEpoca: { method: 'GET', url: `${basePath}/epoca` },
};

export default (resource) => resource(`${basePath}`, {}, tipoContratoActions);
