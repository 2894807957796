<template>
 <div class="RelatorioExportacoes">
    <v-spacer></v-spacer>
   <relatorio-exportacao-produto
     v-if="(isAmbienteKabum && acessoRelatoriosKabum && acessoRelatorioProduto) || (!isAmbienteKabum && acessoRelatorioProduto)"
     @RelatorioExportacaoProduto__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-produto-reorg
     v-if="acessoRelatorioProdutoReorg"
     @RelatorioExportacaoProdutoReorg__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-comparativo-notas
     v-if="acessoRelatorioComparativoNotas"
     @RelatorioComparativoNotas__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-log-acoes
     v-if="(isAmbienteKabum && acessoRelatoriosKabum && acessoRelatorioLogAcoes) || (!isAmbienteKabum && acessoRelatorioLogAcoes)"
     @RelatorioExportacaoLogAcoes__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-log-fornecedor
     v-if="acessoRelatorioLogFornecedor"
     @RelatorioExportacaoLogFornecedor__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-log-produto
     v-if="acessoRelatorioLogProduto"
     @RelatorioExportacaoLogProduto__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-log-usuario
     v-if="acessoRelatorioLogUsuario"
     @RelatorioExportacaoLogUsuario__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-acompanhamento-campanha-ads
     v-if="isAmbienteMagalu && acessoRelatorioAcompanhamentoAdsFiltrosCampos"
     @RelatorioExportacaoDetalhamentoNd__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-detalhamento-nd
     v-if="(isAmbienteKabum && acessoRelatoriosKabum && acessoRelatorioDetalhamentoNd) || (!isAmbienteKabum &&  acessoRelatorioDetalhamentoNd)"
     @RelatorioExportacaoDetalhamentoNd__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-usuario
     v-if="acessoRelatorioUsuario"
     @RelatorioExportacaoUsuario__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-base-contratos
     v-if="acessoRelatorioBaseContratos"
     @RelatorioExportacaoBaseContratos__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-notas-nao-processadas
     v-if="acessoRelatorioNotasNaoProcessadas"
   />
   <relatorio-dados-campanhas
     v-if="(isAmbienteKabum && acessoRelatoriosKabum && acessoRelatorioDadosCampanhas) || (!isAmbienteKabum &&  acessoRelatorioDadosCampanhas)"
     @RelatorioExportacaoDadosCampanhas__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-nd-massiva-epoca
     v-if="(isAmbienteEpoca && acessoRelatorioNdMassivaEpoca)"
     @RelatorioExportacaoNdMassivaEpoca__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-historico-jobs
     v-if="acessoRelatorioHistoricoJobs"
     @RelatorioHistoricoJobs__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-detalhamento-retroativo
     v-if="acessoRelatorioDetalhamentoRetroativo"
     @RelatorioDetalhamentoRetroativo__AplicaFiltros="aplicaFiltros"
   />
   <relatorio-exportacao-notas-sellin
     v-if="acessoRelatorioNotasSellin"
     @RelatorioExportacaoNotasSellin__AplicaFiltros="aplicaFiltros"
   />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { removeEmptyProperties } from '@/common/functions/helpers';
import exportacao from '@/common/functions/exportacao';
import RelatorioExportacaoProduto from './exportacoes/produto/RelatorioExportacaoProduto';
import RelatorioExportacaoProdutoReorg from './exportacoes/produto-reorg/RelatorioExportacaoProdutoReorg';
import RelatorioComparativoNotas from './exportacoes/comparativo-notas/RelatorioComparativoNotas';
import RelatorioExportacaoLogAcoes from './exportacoes/log-acoes/RelatorioExportacaoLogAcoes';
import RelatorioExportacaoLogFornecedor from './exportacoes/log-fornecedor/RelatorioExportacaoLogFornecedor';
import RelatorioExportacaoLogProduto from './exportacoes/log-produto/RelatorioExportacaoLogProduto';
import RelatorioExportacaoLogUsuario from './exportacoes/log-usuario/RelatorioExportacaoLogUsuario';
import RelatorioExportacaoDetalhamentoNd from './exportacoes/detalhamento-nd/RelatorioExportacaoDetalhamentoNd';
import RelatorioExportacaoUsuario from './exportacoes/usuario/RelatorioExportacaoUsuario';
import RelatorioBaseContratos from './exportacoes/relatorio-base-contratos/RelatorioBaseContratos';
import RelatorioDadosCampanhas from './exportacoes/relatorio-dados-campanhas/RelatorioDadosCampanhas';
import RelatorioNdMassivaEpoca from './exportacoes/relatorio-nd-massiva-epoca/RelatorioNdMassivaEpoca';
import RelatorioNotasNaoProcessadas from './exportacoes/notas-nao-processadas/RelatorioNotasNaoProcessadas';
import RelatorioHistoricoJobs from './exportacoes/relatorio-historico-jobs/RelatorioHistoricoJobs';
import RelatorioDetalhamentoRetroativo from './exportacoes/relatorio-detalhamento-retroativo/RelatorioDetalhamentoRetroativo';
import RelatorioExportacaoNotasSellin from './exportacoes/notas-sellin/RelatorioExportacaoNotasSellin';
import RelatorioAcompanhamentoCampanhaAds from './exportacoes/acompanhamento-campanha-ads/RelatorioAcompanhamentoCampanhaAds';

export default {
  data() {
    return {
      filtros: null,
      bloquearExportacao: false,
    };
  },
  components: {
    RelatorioAcompanhamentoCampanhaAds,
    RelatorioExportacaoProduto,
    RelatorioExportacaoProdutoReorg,
    RelatorioComparativoNotas,
    RelatorioExportacaoLogAcoes,
    RelatorioExportacaoLogFornecedor,
    RelatorioExportacaoLogProduto,
    RelatorioExportacaoLogUsuario,
    RelatorioExportacaoDetalhamentoNd,
    RelatorioExportacaoUsuario,
    RelatorioBaseContratos,
    RelatorioNotasNaoProcessadas,
    RelatorioDadosCampanhas,
    RelatorioNdMassivaEpoca,
    RelatorioHistoricoJobs,
    RelatorioDetalhamentoRetroativo,
    RelatorioExportacaoNotasSellin,
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    acessoRelatorioProduto() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_PRODUTO_EXPORTACAO') !== -1).length > 0;
    },
    acessoRelatorioProdutoReorg() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_PRODUTO_REORG') !== -1).length > 0;
    },
    acessoRelatorioComparativoNotas() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_COMPARATIVO_NOTAS') !== -1).length > 0;
    },
    acessoRelatorioLogAcoes() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_LOG_ACOES') !== -1).length > 0;
    },
    acessoRelatorioLogFornecedor() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_LOG_FORNECEDOR') !== -1).length > 0;
    },
    acessoRelatorioLogProduto() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_LOG_PRODUTO') !== -1).length > 0;
    },
    acessoRelatorioLogUsuario() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_LOG_USUARIO') !== -1).length > 0;
    },
    acessoRelatorioNotasSellin() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_NOTAS_SELLIN') !== -1).length > 0;
    },
    acessoRelatorioDetalhamentoNd() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_DETALHAMENTO_ND') !== -1).length > 0;
    },
    acessoRelatorioUsuario() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_USUARIO') !== -1).length > 0;
    },
    acessoRelatorioBaseContratos() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_DETALHAMENTO_CONTRATO') !== -1).length > 0;
    },
    acessoRelatorioDadosCampanhas() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_DADOS_CAMPANHA') !== -1).length > 0;
    },
    acessoRelatorioNdMassivaEpoca() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_ND_MASSIVA_EPOCA') !== -1).length > 0;
    },
    acessoRelatorioNotasNaoProcessadas() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_NDS_NAO_PROCESSADAS') !== -1).length > 0;
    },
    acessoRelatorioDetalhamentoRetroativo() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_DETALHAMENTO_RETROATIVO') !== -1).length > 0;
    },
    acessoRelatorioHistoricoJobs() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_HISTORICO_JOBS') !== -1).length > 0;
    },
    acessoRelatorioAcompanhamentoAdsFiltrosCampos() {
      return this.getAllRoles
        .filter((el) => el.indexOf('REL_ACOMPANHAMENTO_CAMPANHAS_ADS') !== -1).length > 0;
    },
    acessoRelatoriosKabum() {
      return this.isUsuarioFuncionario;
    },
  },
  methods: {
    aplicaFiltros(filtrosAplicados, tipoRelatorio, modulo) {
      if (!filtrosAplicados) {
        return;
      }
      this.filtros = {
        ...filtrosAplicados,
      };
      this.requestExportacao(this.filtros, tipoRelatorio, modulo);
    },
    requestExportacao(filtros, tipoRelatorio, modulo = 'api') {
      const filtroTratado = removeEmptyProperties(filtros);
      this.bloquearExportacao = true;

      exportacao.exportar(() => {
        this.bloquearExportacao = false;
      }, tipoRelatorio, this, filtroTratado, 5000, 0, modulo);
    },
  },
};
</script>
<style>
.RelatorioExportacoes {
  padding-bottom: 20px;
}
.RelatorioExportacoes__Modal .headline {
  width: 100%;
  text-align: center;
}
.RelatorioExportacoes__Lista {
  margin-top: 20px;
}
</style>
