<template lang="html">
  <div class="CRUDGenerico__Wrapper" v-if="canAccessPage">
    <v-card style="min-height: 86vh;">
      <v-card-title>
        <h3 class="headline mb-0">{{ $tc('title.parametrizacao_contrato', 1) }}</h3>
      </v-card-title>
      <v-row justify="start">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="search"
            @input="filtrar"
            append-icon="search"
            :label="$t('label.pesquisar')"
            single-line
            clearable
            hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="lista"
        hide-default-footer
        disable-pagination
        :items-per-page="-1"
        :no-data-text="$t('label.tabela_sem_conteudo')">
        <template v-slot:item.acoes="{ item }">
          <v-menu bottom
            id="more_vert"
            origin="center center"
            transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon text v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click.stop="editItem(item)">
                <v-list-item-action>
                  <v-icon>edit</v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('label.editar') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="copyItem(item)">
                <v-list-item-action>
                  <v-icon>library_add</v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  {{ $t('label.copiar') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <v-card-actions>
      <v-row>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn fab
              bottom right fixed
              class="v-btn__fab"
              color="primary"
              v-on="on"
              @click.native="openNewForm">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>{{$t('label.novo_tipo')}}</span>
        </v-tooltip>
      </v-row>
    </v-card-actions>
  </div>
</template>

<script type="text/javascript">
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  name: 'ParametrizacaoContrato',
  data() {
    return {
      resources: this.$api.parametrizacaoContrato(this.$resource),
      esperar: false,
      search: '',
      headers: [
        {
          text: '', width: '2%', value: 'acoes', sortable: false,
        },
        { text: this.$tc('label.nome', 1), value: 'nome' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.fluxo_trademarketing', 1), value: 'fluxos' },
      ],
      lista: [],
    };
  },
  components: {

  },
  computed: {
    ...generateComputed('PARAM_CONTRATO', [
      'canAccessPage',
    ]),
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 1000);
    },
    editItem(item) {
      this.$router.push({ name: 'editarParametrizacaoContrato', params: { id: item.id } });
    },
    copyItem(item) {
      this.$router.push({ name: 'copiarParametrizacaoContrato', params: { id: item.id, copy: 'COPY' } });
    },
    buscar() {
      const params = {
        filtro: this.search,
      };

      this.resources.buscar(params).then((response) => {
        this.lista = response.data;
        this.pararEsperar();
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    openNewForm() {
      this.$router.push({ name: 'novoParametrizacaoContrato' });
    },
  },
  mounted() {
    this.filtrar();
  },
  beforeMount() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.accessForbidden();
      }, 1E3);
    }
  },
};
</script>
